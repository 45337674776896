import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Image, TouchableOpacity, Linking } from 'react-native';
import { Provider, Text } from 'react-native-paper';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import ConstManager from './managers/ConstManager';
import myCss from './css/myCss'; //
import HomeScreen from './screens/HomeScreen';
import AdminControlPanelView from './screens/admin/AdminControlPanelView';
import ManageErgazomenousAnaMeraScreen from './screens/admin/ManageErgazomenousAnaMeraScreen';
import ManageErgazomenousScreen from './screens/admin/ManageErgazomenousScreen';
import ManageErgaScreen from './screens/admin/ManageErgaScreen';
import ManageUsersScreen from './screens/admin/menu/ManageUsersScreen';
import ManageKathgoriesErgazomenwnScreen from './screens/admin/menu/ManageKathgoriesErgazomenwnScreen';
import ManageArgiesScreen from './screens/admin/menu/ManageArgiesScreen';
import ManageTypousErgwnScreen from './screens/admin/menu/ManageTypousErgwnScreen';
import ManageKentraKostousScreen from './screens/admin/menu/ManageKentraKostousScreen';
import React, { useState } from 'react';
import i18n from './utils/i18n';

const Stack = createStackNavigator();

export default function App() {
  // Set the locale once at the beginning of your app.
  i18n.locale = 'gr';

  const linking = {
    prefixes: ['logistirio://'],
    config: {
      screens: {
        Home: 'home',
        News: 'news'
      },
    },
  };

  const [curPage, setCurPage] = useState(ConstManager.getInstance().HOME);

  const navigationOptions: NativeStackNavigationOptions = ({ navigation }) => ({
    headerStyle: {
      backgroundColor: 'lightgray',
    },
    headerTitle: () => (
      <Text style={{fontSize: 20, fontWeight: 'bold'}}>Εφαρμογή Λογιστηρίου - ΑΡΚΕΤΙΠΟ</Text>
    ),
    headerTitleAlign: 'center',
  });
  
  return (
    <Provider>
      <NavigationContainer>
        <Stack.Navigator 
            initialRouteName="Home" 
            linking={linking}        
            screenOptions={navigationOptions}
        >
          <Stack.Screen name="Home" component={HomeScreen} options={({ route }) => ({ title: 'Λογιστήριο' })} />
          
          <Stack.Screen name="AdminControlPanel" component={AdminControlPanelView} options={{ title: 'Λογιστήριο - Admin Control Panel' }} />
          
          <Stack.Screen name="ManageErgazomenousAnaMeraScreen" component={ManageErgazomenousAnaMeraScreen} options={({ route }) => ({ title: i18n.t('ergazomenoiAnaMera') })} />
          <Stack.Screen name="ManageErgazomenousScreen" component={ManageErgazomenousScreen} options={({ route }) => ({ title: i18n.t('ergazomenoi') })} />
          <Stack.Screen name="ManageErgaScreen" component={ManageErgaScreen} options={({ route }) => ({ title: i18n.t('erga') })} />
          <Stack.Screen name="ManageUsers" component={ManageUsersScreen} options={({ route }) => ({ title: i18n.t('users') })} />
          <Stack.Screen name="ManageKathgoriesErgazomenwnScreen" component={ManageKathgoriesErgazomenwnScreen} options={({ route }) => ({ title: i18n.t('kathgoriesErgazomenwn') })} />
          <Stack.Screen name="ManageArgiesScreen" component={ManageArgiesScreen} options={({ route }) => ({ title: i18n.t('argies') })} />
          <Stack.Screen name="ManageTypousErgwnScreen" component={ManageTypousErgwnScreen} options={({ route }) => ({ title: i18n.t('typoiErgwn') })} />
          <Stack.Screen name="ManageKentraKostousScreen" component={ManageKentraKostousScreen} options={({ route }) => ({ title: i18n.t('kentraKostous') })} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
}