class ConstManager {
    public readonly INTRUDER_MSG: string = "You do not have permissions for this!";    
    public readonly THERE_IS_ANOTHER_USER_WITH_THIS_EMAIL: string = "There is another user with this email";
    public readonly USER_ADDED_SUCCESSFULLY: string = "User added successfully!";
    public readonly USER_EDITED_SUCCESSFULLY: string = "User edited successfully!";
    public readonly USER_DELETED_SUCCESSFULLY: string = "User deleted successfully!";

    public readonly ERGO_ADDED_SUCCESSFULLY: string = "Ergo added successfully!";
    public readonly ERGO_EDITED_SUCCESSFULLY: string = "Ergo edited successfully!";
    public readonly ERGO_DELETED_SUCCESSFULLY: string = "Ergo deleted successfully!";

    public readonly ERGAZOMENOS_ADDED_SUCCESSFULLY: string = "Ergazomenos added successfully!";
    public readonly ERGAZOMENOS_EDITED_SUCCESSFULLY: string = "Ergazomenos edited successfully!";
    public readonly ERGAZOMENOS_DELETED_SUCCESSFULLY: string = "Ergazomenos deleted successfully!";

    public readonly ERGAZOMENOSANAMERA_ADDED_SUCCESSFULLY: string = "Ergazomenos ana mera added successfully!";
    public readonly ERGAZOMENOSANAMERA_EDITED_SUCCESSFULLY: string = "Ergazomenos ana mera edited successfully!";
    public readonly ERGAZOMENOSANAMERA_DELETED_SUCCESSFULLY: string = "Ergazomenos ana mera deleted successfully!";
    public readonly ERGAZOMENOSANAMERA_NOT_ADDED_DUE_TO_INVALID_VALUES: string = "Ergazomenos ana mera not added due to invalid values!";
    public readonly NEOS_ERGAZOMENOSANAMERA_ARXIKOPOIHTHIKE_EPEIDH_EXEI_ADEIA: string = "Neos ergazomenos ana mera arxikopoihthike giati exei adeia!";
    public readonly YPARXWN_ERGAZOMENOSANAMERA_ARXIKOPOIHTHIKE_EPEIDH_EXEI_ADEIA: string = "Yparxwn ergazomenos ana mera arxikopoihthike giati exei adeia!";
    public readonly DHMIOYRGHTHIKAN_KAI_ARXIKOPOIHTHIKAN_ENTRIES_GIA_OLA_TA_ERGA_EPEIDH_O_ERGAZOMENOS_EXEI_ADEIA: string = "Dhmioyrghthikan kai arxikopoihthikan entries gia ola ta erga giati exei adeia!";

    public readonly TYPOSERGOU_ADDED_SUCCESSFULLY: string = "Typos ergou added successfully!";
    public readonly TYPOSERGOU_EDITED_SUCCESSFULLY: string = "Typos ergou edited successfully!";
    public readonly TYPOSERGOU_DELETED_SUCCESSFULLY: string = "Typos ergou deleted successfully!";

    public readonly KATHGORIAERGAZOMENWN_ADDED_SUCCESSFULLY: string = "Kathgoria ergazomenwn added successfully!";
    public readonly KATHGORIAERGAZOMENWN_EDITED_SUCCESSFULLY: string = "Kathgoria ergazomenwn edited successfully!";
    public readonly KATHGORIAERGAZOMENWN_DELETED_SUCCESSFULLY: string = "Kathgoria ergazomenwn deleted successfully!";

    public readonly KENTROKOSTOUS_ADDED_SUCCESSFULLY: string = "Kentro kostous added successfully!";
    public readonly KENTROKOSTOUS_EDITED_SUCCESSFULLY: string = "Kentro kostous edited successfully!";
    public readonly KENTROKOSTOUS_DELETED_SUCCESSFULLY: string = "Kentro kostous deleted successfully!";

    public readonly ARGIA_ADDED_SUCCESSFULLY = "Argia added successfully!";
    public readonly ARGIA_EDITED_SUCCESSFULLY = "Argia edited successfully!";
    public readonly ARGIA_DELETED_SUCCESSFULLY = "Argia deleted successfully!";
    
    public readonly WEB_MAX_WIDTH = 1330;
    public readonly WEB_MAX_HEIGHT = 700;

    public readonly WIDTH_NEEDED_FOR_MENU = 0;

    public readonly YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA: number = 0;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_KATHIMERINES: number = 1;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SAVVATA: number = 2;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_KYRIAKES: number = 3;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_YPERWRIES: number = 4;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_DIANYKTEREYSEIS: number = 5;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_ODOIPORIKA: number = 6;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_KATHIMERINES: number = 7;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SAVVATA: number = 8;
    public readonly YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SYNOLO: number = 9;

    public readonly YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_KATHIMERINES_SE_MERES: number = 10;
    public readonly YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_SAVVATA_SE_MERES: number = 11;
    public readonly YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_KYRIAKES_SE_MERES: number = 12;
    public readonly YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_YPERWRIES_SE_WRES: number = 13;
    public readonly YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_DIANYKTEREYSEIS_SE_MERES: number = 14;
    public readonly YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_ODOIPORIKA_SE_WRES: number = 15;

    public readonly YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY: number = 16;
    public readonly YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_KENTRO_KOSTOYS: number = 17;
    public readonly YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_ERGO: number = 18;


    public readonly OLOI_OI_ERGAZOMENOI = -1000;
    public readonly OLA_TA_ERGA = -2000;
    public readonly OLOI_OI_TYPOI_ERGOY = -3000;

    public readonly OXI_ADEIA = -1;
    public readonly KANONIKH_ADEIA = 0;
    public readonly ANARRWTIKH_ADEIA = 1;

    public readonly HMEROMHNIA_CELL_WIDTH: number = 28;
    public readonly ADEIA_CELL_WIDTH: number = 15;
    public readonly KENTRO_KOSTOYS_CELL_WIDTH: number = 50;
    public readonly DEYTERA_PARASKEYH_CELL_WIDTH: number = 34;
    public readonly SAVVATO_CELL_WIDTH: number = 34;
    public readonly KYRIAKH_CELL_WIDTH: number = 34;
    public readonly YPERWRIES_CELL_WIDTH: number = 31;
    public readonly DIANYKTEREYSH_CELL_WIDTH: number = 22;
    public readonly ODOIPORIKA_CELL_WIDTH: number = 31;

    public readonly USE_ERGAZOMENOI_ANA_ERGO: boolean = false;

    //Synolo pdf width: 280

    private static instance: ConstManager;

    private constructor() { 
    }

    public static getInstance(): ConstManager {
        if (!ConstManager.instance) {
            ConstManager.instance = new ConstManager();
        }

        return ConstManager.instance;
    }
}

export default ConstManager;