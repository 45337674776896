import { Keyboard, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, View, Text } from 'react-native';
import styles from '../../css/myCss';
import LoginManager from '../../managers/LoginManager';
import AdminControlPanelFooter from './AdminControlPanelFooter';
import React, { memo, useEffect, useRef, useState } from "react";


const AdminControlPanelView = () => {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={styles.container}>
            
              <AdminControlPanelFooter/>

            </View>

        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
      );
};

export default AdminControlPanelView;