import { View, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';
import myCss from '../../../css/myCss';
import Footer from '../../Footer';
import React, { useEffect, useRef, useState } from 'react';
import RestManager from '../../../managers/RestManager';
import i18n from '../../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataTable, IconButton, Colors, Title, Text, Button, Portal, Modal, TextInput, Dialog, Paragraph } from 'react-native-paper';
import LoginManager from '../../../managers/LoginManager';
import DropDown from 'react-native-paper-dropdown';
import ConstManager from '../../../managers/ConstManager';
import AwesomeAlert from 'react-native-awesome-alerts';
import UtilsManager from '../../../managers/UtilsManager';


const ManageUsersScreen = () => {
  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ManageUsersScreen: I'm ready!");
    setIsLoading(true);
    (async () => {
      const tmpAllUsers: Array<User> = await RestManager.getInstance().getAllUsers();
      setUsers(tmpAllUsers);
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<Array<User>>([]);

  const [newUserModalVisible, setNewUserModalVisible] = useState(false);
  const hideNewUserModal = () => { 
    setNewUserModalVisible(false);
  }

  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const hideEditUserModal = () => { 
    setEditUserModalVisible(false);
  }

  const [newUser, setNewUser] = useState<User>({
    id: -1,
    onoma: "",
    epwnymo: "",
    thlefwno: "",
    email: "",
    password: "",
    role: -1,
    last_login: ""
  });

  const [editedUser, setEditedUser] = useState<User>({
    id: -1,
    onoma: "",
    epwnymo: "",
    thlefwno: "",
    email: "",
    password: "",
    role: -1,
    last_login: ""
  });

  // Create a ref for the TextInput components
  const onomaNewInputRef = useRef(null);
  const epwnymoNewInputRef = useRef(null);
  const thlefwnoNewInputRef = useRef(null);
  const emailNewInputRef = useRef(null);
  const passwordNewInputRef = useRef(null);

  const onomaEditInputRef = useRef(null);
  const epwnymoEditInputRef = useRef(null);
  const thlefwnoEditInputRef = useRef(null);
  const emailEditInputRef = useRef(null);
  const passwordEditInputRef = useRef(null);

  const [alertForNewWebModalVisible, setAlertForNewWebModalVisible] = useState(false);
  const [alertForNewWebModalText, setAlertForNewWebModalText] = useState('');

  const [alertForEditWebModalVisible, setAlertForEditWebModalVisible] = useState(false);
  const [alertForEditWebModalText, setAlertForEditWebModalText] = useState('');

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [dropdownListItems, setDropdownListItems] = useState([
            { label: "Διαχειριστής", value: LoginManager.getInstance().ADMIN_ROLE }, 
            { label: "Μηχανικός", value: LoginManager.getInstance().MHXANIKOS_ROLE },
            { label: "Εργοδηγός", value: LoginManager.getInstance().ERGODHGOS_ROLE }
        ]);
  const [showRoleDropdownList, setShowRoleDropdownList] = useState(false);
  
  const [selectedRoleAddUser, setSelectedRoleAddUser] = useState(-1);
  useEffect(() => {
    setNewUser({...newUser, role: selectedRoleAddUser });
  }, [selectedRoleAddUser]);
  
  const [selectedRoleEditUser, setSelectedRoleEditUser] = useState(-1);
  useEffect(() => {
    setEditedUser({...editedUser, role: selectedRoleEditUser });
  }, [selectedRoleEditUser]);

  async function encryptPasswordAsync(tmpUnencryptedPassword: string): Promise<string> {
    const digest = await UtilsManager.getInstance().generateMD5Hash_forWeb(newUser.password);
    console.log('Digest (web): ', digest);
    //setNewUser({...newUser, password: digest }); //ek perissou
    //setEditedUser({...editedUser, password: digest }); //ek perissou
    return digest;
  }
  
  async function addNewUserAsync() 
  {
    if(newUser.role == -1) {
      setAlertForNewWebModalText("Παρακαλώ επιλέξτε ρόλο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newUser.onoma == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε όνομα!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newUser.epwnymo == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε επώνυμο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newUser.thlefwno == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε τηλέφωνο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newUser.email == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε email!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newUser.password == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε password!");
      setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      //encrypt password
      const tmpEncryptedPassword = await encryptPasswordAsync(newUser.password); //to kanw gia thema xronismou - den prolavainei na enhmerwthei o newUser prin kalesw thn addUser

      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().addUser(newUser, tmpEncryptedPassword);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().THERE_IS_ANOTHER_USER_WITH_THIS_EMAIL)
          msgToShow = "Υπάρχει ήδη κάποιος χρήστης με αυτό το email!";
        else if(webServiceResponseStr == ConstManager.getInstance().USER_ADDED_SUCCESSFULLY) {
          msgToShow = "Ο χρήστης προστέθηκε επιτυχώς!";
          //update users array
          const tmpAllUsers: Array<User> = await RestManager.getInstance().getAllUsers();
          setUsers(tmpAllUsers);
          //init new user obj
          setNewUser({
            id: -1,
            onoma: "",
            epwnymo: "",
            thlefwno: "",
            email: "",
            password: "",
            role: -1,
            last_login: ""
          });
        }
        setNewUserModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  async function editUserAsync() 
  {
    if(editedUser.role == -1) {
      setAlertForEditWebModalText("Παρακαλώ επιλέξτε ρόλο!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedUser.onoma == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε όνομα!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedUser.epwnymo == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε επώνυμο!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedUser.thlefwno == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε τηλέφωνο!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedUser.email == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε email!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedUser.password == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε password!");
      setAlertForEditWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      //encrypt password
      const tmpEncryptedPassword = await encryptPasswordAsync(editedUser.password); //to kanw gia thema xronismou - den prolavainei na enhmerwthei o editedUser prin kalesw thn editUser

      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().editUser(editedUser, tmpEncryptedPassword);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().THERE_IS_ANOTHER_USER_WITH_THIS_EMAIL)
          msgToShow = "Υπάρχει ήδη κάποιος χρήστης με αυτό το email!";
        else if(webServiceResponseStr == ConstManager.getInstance().USER_EDITED_SUCCESSFULLY) {
          msgToShow = "Τα στοιχεία του χρήστη ενημερώθηκαν επιτυχώς!";
          //update users array
          const tmpAllUsers: Array<User> = await RestManager.getInstance().getAllUsers();
          setUsers(tmpAllUsers);
          //init new user obj
          setEditedUser({
            id: -1,
            onoma: "",
            epwnymo: "",
            thlefwno: "",
            email: "",
            password: "",
            role: -1,
            last_login: ""
          });
        }
        setEditUserModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const hideDeleteDialog = () => setDeleteDialogVisible(false);

  const [userIDToBeDeleted, setUserIDToBeDeleted] = useState(-1);

  async function deleteUserAsync() {
    hideDeleteDialog();
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    webServiceResponseStr = await RestManager.getInstance().deleteUser(userIDToBeDeleted);
    if(typeof webServiceResponseStr === 'string') {
      var msgToShow: string = "";
      if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
        msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
      else if(webServiceResponseStr == ConstManager.getInstance().USER_DELETED_SUCCESSFULLY) {
        msgToShow = "Ο χρήστης διεγράφη επιτυχώς!";
        //update users array
        const tmpAllUsers: Array<User> = await RestManager.getInstance().getAllUsers();
        setUsers(tmpAllUsers);
        //init new user obj
        setNewUser({
          id: -1,
          onoma: "",
          epwnymo: "",
          thlefwno: "",
          email: "",
          password: "",
          role: -1,
          last_login: ""
        });
      }
      setAlertForWebText(msgToShow);
      setAlertForWebVisible(true);
    }
    setIsLoading(false);
  }
  
  return (
    <SafeAreaView>
      <ScrollView style={{/*height: 4000*/}}>

        <Portal>
          <Modal visible={newUserModalVisible} onDismiss={hideNewUserModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Νέος χρήστης</Title>
              <View style={myCss.domainDropdownModal}>
                <DropDown
                    label="Ρόλος"
                    mode={'outlined'}
                    visible={showRoleDropdownList}
                    showDropDown={() => setShowRoleDropdownList(true)}
                    onDismiss={() => setShowRoleDropdownList(false)}
                    value={selectedRoleAddUser}
                    setValue={setSelectedRoleAddUser}
                    list={dropdownListItems}
                    inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showRoleDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
                />
              </View>
              <TouchableOpacity onPress={() => onomaNewInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={onomaNewInputRef}
                  label="Όνομα"
                  value={newUser.onoma}
                  onChangeText={tmpOnoma => setNewUser({...newUser, onoma: tmpOnoma })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => epwnymoNewInputRef.current.focus()}>
                <TextInput
                  ref={epwnymoNewInputRef}
                  label="Επώνυμο"
                  value={newUser.epwnymo}
                  onChangeText={tmpEpwnymo => setNewUser({...newUser, epwnymo: tmpEpwnymo })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => thlefwnoNewInputRef.current.focus()}>
                <TextInput
                  ref={thlefwnoNewInputRef}
                  label="Τηλέφωνο"
                  value={newUser.thlefwno}
                  onChangeText={tmpThlefwno => setNewUser({...newUser, thlefwno: tmpThlefwno })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => emailNewInputRef.current.focus()}>
                <TextInput
                  ref={emailNewInputRef}
                  label="Email"
                  value={newUser.email}
                  onChangeText={tmpEmail => setNewUser({...newUser, email: tmpEmail })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => passwordNewInputRef.current.focus()}>
                <TextInput
                  ref={passwordNewInputRef}
                  label="Password"
                  value={newUser.password}
                  onChangeText={tmpPassword => setNewUser({...newUser, password: tmpPassword })}
                  secureTextEntry
                />
              </TouchableOpacity>
              <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={addNewUserAsync}>{i18n.t('save')}</Button>
              <AwesomeAlert
                show={alertForNewWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForNewWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Modal visible={editUserModalVisible} onDismiss={hideEditUserModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Υπάρχων χρήστης</Title>
              <View style={myCss.domainDropdownModal}>
                <DropDown
                    label="Ρόλος"
                    mode={'outlined'}
                    visible={showRoleDropdownList}
                    showDropDown={() => setShowRoleDropdownList(true)}
                    onDismiss={() => setShowRoleDropdownList(false)}
                    value={editedUser.role}
                    setValue={setSelectedRoleEditUser}
                    list={dropdownListItems}
                    inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showRoleDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
                />
              </View>
              <TouchableOpacity onPress={() => onomaEditInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={onomaEditInputRef}
                  label="Όνομα"
                  value={editedUser.onoma}
                  onChangeText={tmpOnoma => setEditedUser({...editedUser, onoma: tmpOnoma })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => epwnymoEditInputRef.current.focus()}>
                <TextInput
                  ref={epwnymoEditInputRef}
                  label="Επώνυμο"
                  value={editedUser.epwnymo}
                  onChangeText={tmpEpwnymo => setEditedUser({...editedUser, epwnymo: tmpEpwnymo })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => thlefwnoEditInputRef.current.focus()}>
                <TextInput
                  ref={thlefwnoEditInputRef}
                  label="Τηλέφωνο"
                  value={editedUser.thlefwno}
                  onChangeText={tmpThlefwno => setEditedUser({...editedUser, thlefwno: tmpThlefwno })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => emailEditInputRef.current.focus()}>
                <TextInput
                  ref={emailEditInputRef}
                  label="Email"
                  value={editedUser.email}
                  onChangeText={tmpEmail => setEditedUser({...editedUser, email: tmpEmail })}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => passwordEditInputRef.current.focus()}>
                <TextInput
                  ref={passwordEditInputRef}
                  label="Password"
                  value={editedUser.password}
                  onChangeText={tmpPassword => setEditedUser({...editedUser, password: tmpPassword })}
                  secureTextEntry
                />
              </TouchableOpacity>
              <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={editUserAsync}>{i18n.t('save')}</Button>
              <AwesomeAlert
                show={alertForEditWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForEditWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Dialog visible={deleteDialogVisible} onDismiss={hideDeleteDialog} style={myCss.dialog}>
            <Dialog.Title>Διαγραφή χρήστη!</Dialog.Title>
            <Dialog.Content>
              <Paragraph>{i18n.t('areYouSure')}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setDeleteDialogVisible(false)}>{i18n.t('cancel')}</Button>
              <Button onPress={() => deleteUserAsync()}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        {newUserModalVisible==false 
          && editUserModalVisible==false 
          //&& alertForWebVisible==false 
          && alertForNewWebModalVisible==false
          && alertForEditWebModalVisible==false
          && deleteDialogVisible==false
          && <View style={myCss.container}>
          <Spinner
            //visibility of Overlay Loading Spinner
            visible={isLoading}
            //Text with the Spinner
            textContent={i18n.t('loading')}
            //Text style of the Spinner Text
            textStyle={myCss.spinnerTextStyle}
          />

          <Title style={myCss.title}>Χρήστες</Title>
          <DataTable>
            {users.map((user: User, i) => (             
              user.email!="kaklanis@gmail.com" &&
              <DataTable.Row key={i} style={myCss.tableRow}>
                <DataTable.Title style={myCss.tableTitle}>{user.role==LoginManager.getInstance().ADMIN_ROLE ? i18n.t('administrator')
                                                                : user.role==LoginManager.getInstance().MHXANIKOS_ROLE ? i18n.t('mhxanikos')
                                                                : user.role==LoginManager.getInstance().ERGODHGOS_ROLE ? i18n.t('ergodhgos') 
                                                                : "ΑΓΝΩΣΤΟ!"}</DataTable.Title>
                <DataTable.Cell style={myCss.tableValueColumn}>{user.onoma + " " + user.epwnymo}</DataTable.Cell>
                <DataTable.Cell style={myCss.tableValueColumn}>{user.thlefwno}</DataTable.Cell>
                <DataTable.Cell style={myCss.tableValueColumn}>{user.email}</DataTable.Cell>
                <DataTable.Cell style={myCss.tableIcon}>
                  <IconButton
                    icon={({ color, size }) => (<MaterialCommunityIcons name="account-edit" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      const tempUserToBeEdited: User = {
                                        id: user.id,
                                        onoma: user.onoma,
                                        epwnymo: user.epwnymo,
                                        thlefwno: user.thlefwno,
                                        email: user.email,
                                        password: "", //svhnw password giati den exei nohma - einai encrypted!
                                        role: user.role,
                                        last_login: user.last_login //ek perissou
                                      };
                                      setEditedUser(tempUserToBeEdited); 
                                      setSelectedRoleEditUser(user.role);
                                      setEditUserModalVisible(true);
                                   }} />
                  <IconButton
                    icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      setUserIDToBeDeleted(user.id);
                                      setDeleteDialogVisible(true);
                                   }} />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
          <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="add" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} 
                                          onPress={() => {
                                                            const tempNewUser: User = {
                                                              id: -1,
                                                              onoma: "",
                                                              epwnymo: "",
                                                              thlefwno: "",
                                                              email: "",
                                                              password: "",
                                                              role: -1,
                                                              last_login: ""
                                                            };
                                                            setNewUser(tempNewUser); 
                                                            setSelectedRoleAddUser(-1);
                                                            setSelectedRoleEditUser(-1);
                                                            setNewUserModalVisible(true);
                                                         }}>{i18n.t('addNewUser')}</Button>
          <AwesomeAlert
            show={alertForWebVisible}
            showProgress={false}
            title={i18n.t('caution')}
            message={alertForWebText}
            closeOnTouchOutside={false}
            closeOnHardwareBackPress={false}
            showConfirmButton={true}
            confirmText="OK"
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => {
              setAlertForWebVisible(false);
            }}
            onConfirmPressed={() => {
              setAlertForWebVisible(false);
            }}  
          />
        </View>}
      </ScrollView>
    </SafeAreaView>
  );
};

export default ManageUsersScreen;