// CustomAutocompleteRenderer.tsx
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Autosuggest from 'react-autosuggest';
import ConstManager from '../../managers/ConstManager';

const CustomAutocompleteRenderer = (props: any) => {
  const [value, setValue] = useState(props.value!=undefined ? props.value : "");
  const [suggestions, setSuggestions] = useState([]);
  const [inputRect, setInputRect] = useState({ top: 0, left: 0, width: 0, height: 0 });

  const getSuggestions = (inputValue) => {
    if(inputValue) {
      const regex = new RegExp(inputValue.trim(), 'i');
      return props.suggestionsData.filter((item) => regex.test(item.value));
    }
    else
      return null;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const newSuggestions = getSuggestions(value);
    //console.log('Fetched Suggestions:', newSuggestions); // Debug log
    setSuggestions(newSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if(newValue==undefined || newValue=="")
      props.setValue(ConstManager.getInstance().OXI_ADEIA.toString());
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setValue(suggestion.value);
    //console.log("CustomAutocompleteRenderer - onSuggestionSelected -> suggestion: " + JSON.stringify(suggestion, null, 2));
    props.setValue(suggestion.id.toString());
  };

  const getSuggestionValue = (suggestion) => suggestion.value;

  const renderSuggestion = (suggestion) => <span>{suggestion.value}</span>;

  const inputProps = {
    placeholder: props?.suggestionsData[2]?.value === "Αναρρωτική" ? "Κανονική/Αναρρωτική" : "",
    value,
    onChange,
    onFocus: (e) => {
      const rect = e.target.getBoundingClientRect();
      setInputRect({ top: rect.top, left: rect.left, width: rect.width, height: rect.height });
    }
  };

  // Custom inline theme for styling
  const theme = {
    container: {
      position: 'relative',
      width: '100%',
    },
    suggestionsContainer: {
      position: 'absolute',
      top: inputRect.top + inputRect.height,
      left: inputRect.left,
      width: inputRect.width,
      zIndex: 1000, // High z-index to appear above other elements
      border: '1px solid #aaa',
      backgroundColor: '#fff',
      maxHeight: '200px',
      overflowY: 'auto'
    },
    suggestion: {
      //padding: '10px',
      margin: '0px',
      padding: '0px',
      borderBottom: '1px solid #ddd',
      cursor: 'pointer'
    },
    suggestionHighlighted: {
      backgroundColor: '#ddd'
    },
    input: {
      width: '100%',
      //padding: '10px',
      //border: '1px solid #aaa',
      //borderRadius: '4px'
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={theme}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestionsContainer={({ containerProps, children, query }) => {
          return ReactDOM.createPortal(
            <div {...containerProps} style={{ ...containerProps.style, ...theme.suggestionsContainer }}>
              {children}
            </div>,
            document.body
          );
        }}
      />
    </div>
  );
};

export default CustomAutocompleteRenderer;