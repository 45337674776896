import ConstManager from '../managers/ConstManager';
import { View, Text, Image, TouchableOpacity, Dimensions } from 'react-native';
import myCss from '../css/myCss';
import Footer from './Footer';
import React, { useEffect, useRef, useState } from 'react';
import UtilsManager from '../managers/UtilsManager';
import { Button, TextInput } from 'react-native-paper';
import i18n from '../utils/i18n';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import LoginManager from '../managers/LoginManager';
import RestManager from '../managers/RestManager';
import Spinner from 'react-native-loading-spinner-overlay';
import { useNavigation } from '@react-navigation/native';

const screenDimensions = Dimensions.get('window');

const HomeScreen = () => {
  const navigation = useNavigation();

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("HomeScreen: I'm ready!");
    const tmpStoredEmail = sessionStorage.getItem('storedEmail');
    const tmpStoredNonEncryptedPassword = sessionStorage.getItem('storedNonEncryptedPassword');
    const tmpStoredEncryptedPassword = sessionStorage.getItem('storedEncryptedPassword');

    //console.log("sessionStorage -> tmpStoredEmail: ", tmpStoredEmail);
    //console.log("sessionStorage -> tmpStoredNonEncryptedPassword: ", tmpStoredNonEncryptedPassword);
    //console.log("sessionStorage -> tmpStoredEncryptedPassword: ", tmpStoredEncryptedPassword);

    if(tmpStoredEmail) {
      setCurEmail(tmpStoredEmail);
      LoginManager.getInstance().username = tmpStoredEmail;
    }
    if(tmpStoredNonEncryptedPassword) {
      setCurPassword(tmpStoredNonEncryptedPassword);
      LoginManager.getInstance().password = tmpStoredNonEncryptedPassword;
    }
    if(tmpStoredEncryptedPassword) {
      setCurMD5EncodedPassword(tmpStoredEncryptedPassword);
      LoginManager.getInstance().md5EncodedPassword = tmpStoredEncryptedPassword;
    }
  }, [alreadyRunOnceOnViewLoad]);
  
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
 
  //const [curEmail, setCurEmail] = useState("kaklanis@gmail.com"); //development mode
  //const [curPassword, setCurPassword] = useState("nickAdm22!@");  //development mode
  const [curEmail, setCurEmail] = useState("");       //live server
  const [curPassword, setCurPassword] = useState(""); //live server
  useEffect(() => {
    (async () => {
      const digest = await UtilsManager.getInstance().generateMD5Hash_forWeb(curPassword);
      //console.log('Digest (web): ', digest);
      setCurMD5EncodedPassword(digest);
    })();
  }, [curPassword]);

  const [curMD5EncodedPassword, setCurMD5EncodedPassword] = useState('');

  async function loginFunc() {
    //first check if email is empty
    var tmpEmailIsEmpty : boolean = false;
    if(curEmail == "")
      tmpEmailIsEmpty = true;
    //then check if email is valid
    const tmpEmailIsValid = UtilsManager.getInstance().emailIsValid(curEmail);
    //then check if password is empty
    var tmpPasswordIsEmpty : boolean = false;
    if(curPassword == "")
      tmpPasswordIsEmpty = true;
    
    if(tmpEmailIsEmpty == true) {
      setAlertForWebText(i18n.t('pleaseCompleteEmail'));
      setAlertForWebVisible(true);
    }
    else if(tmpEmailIsValid == false) {
      setAlertForWebText(i18n.t('pleaseCompleteValidEmail'));
      setAlertForWebVisible(true);
    }
    else if(tmpPasswordIsEmpty == true) {
      setAlertForWebText(i18n.t('pleaseCompletePassword'));
      setAlertForWebVisible(true);
    }
    else {
      setIsLoading(true);
      
      //store user email/password locally on the device
      sessionStorage.setItem('storedEmail', curEmail);
      sessionStorage.setItem('storedNonEncryptedPassword', curPassword);
      //sessionStorage.setItem('storedEncryptedPassword', curMD5EncodedPassword);

      LoginManager.getInstance().username = curEmail;
      LoginManager.getInstance().password = curPassword;
      LoginManager.getInstance().md5EncodedPassword = curMD5EncodedPassword;

      var tmpRoleID : RoleID = {
        role: -1,
        ID: -1
      };
      tmpRoleID = await RestManager.getInstance().login() as RoleID;
      
      //console.log("login response -> tmpRoleID: ", JSON.stringify(tmpRoleID, null, 2))
      LoginManager.getInstance().role = tmpRoleID.role;
      LoginManager.getInstance().ID = tmpRoleID.ID;

      setIsLoading(false);

      //ADMIN_ROLE
      if(tmpRoleID.ID!==-1 && tmpRoleID.role===LoginManager.getInstance().ADMIN_ROLE)
      {
        //console.log("*** ADMIN MODE ***");
        LoginManager.getInstance().role = LoginManager.getInstance().ADMIN_ROLE;
        
        navigation.reset({
          index: 1,
          routes: [{ name: 'AdminControlPanel' }]
        });
      }
      //INTRUDER_ROLE
      else
      {
        LoginManager.getInstance().role = LoginManager.getInstance().INTRUDER_ROLE;
        
        setAlertForWebText(i18n.t('wrongEmailPassword'));
        setAlertForWebVisible(true);
      }

      //setIsLoading(false); An den to exw sxoliasmeno xtypaei giati paei na kanei setState th stigmh poy einai unmounted afoy exw hdh kanei navigate se allo view
    }
  }

  // Create a ref for the TextInput components
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  
  return (
    <View style={myCss.container}>
      <Spinner
        //visibility of Overlay Loading Spinner
        visible={isLoading}
        //Text with the Spinner
        textContent={i18n.t('loading')}
        //Text style of the Spinner Text
        textStyle={myCss.spinnerTextStyle}
      />
      <TouchableOpacity onPress={() => emailInputRef.current.focus()} style={{ marginTop: 5 }}>
        <TextInput
          ref={emailInputRef}
          label="Email"
          value={curEmail}
          onChangeText={tmpEmail => setCurEmail(tmpEmail)}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => passwordInputRef.current.focus()}>
        <TextInput
          ref={passwordInputRef}
          label="Κωδικός"
          value={curPassword}
          onChangeText={tmpPassword => setCurPassword(tmpPassword)}
          secureTextEntry
        />
      </TouchableOpacity>
      <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialCommunityIcons name="login" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={() => loginFunc()}>{i18n.t('login')}</Button>
      <Footer/>
      <AwesomeAlert
        show={alertForWebVisible}
        showProgress={false}
        title={i18n.t('caution')}
        message={alertForWebText}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="OK"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setAlertForWebVisible(false);
        }}
        onConfirmPressed={() => {
          setAlertForWebVisible(false);
        }}  
      />
    </View>
  );
};

export default HomeScreen;