import GUIManager from "./GUIManager";
import LoginManager from "./LoginManager";

class RestManager {

    public readonly ngrokURL: string = "https://services.handybooker.com/AppLogistiriou_WS/logistirio";  //live - production

    private static instance: RestManager;

    private constructor() {
        
    }

    public static getInstance(): RestManager {
        if (!RestManager.instance) {
            RestManager.instance = new RestManager();
        }

        return RestManager.instance;
    }

    public async login() : Promise<RoleID>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/login', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword
            }),
          })
          .then(response => response.json())
          .then(responseJson => {
            //console.log("Role: " + responseJson.role + ", ID: " + responseJson.ID);
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
    }

    /*****************/
    /* USERS - START */
    /*****************/

    public async getAllUsers() : Promise<Array<User>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllUsers', {
          method: 'POST',
          headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
          //console.log("getAllUsers: " + JSON.stringify(responseJson,null, 2));
          return responseJson;
      })
      .catch(error => {
          console.error(error);
      });
  }

  public async addUser(tmpUser : User, tmpEncryptedPassword : string) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addUser', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          onoma: tmpUser.onoma,
          epwnymo: tmpUser.epwnymo,
          thlefwno: tmpUser.thlefwno,
          email: tmpUser.email,
          password: tmpEncryptedPassword,
          role: tmpUser.role
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addUser response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editUser(tmpUser: User, tmpEncryptedPassword : string) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editUser', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpUser.id,
          onoma: tmpUser.onoma,
          epwnymo: tmpUser.epwnymo,
          thlefwno: tmpUser.thlefwno,
          email: tmpUser.email,
          password: tmpEncryptedPassword,
          role: tmpUser.role
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editUser response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteUser(tmpUserID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteUser', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpUserID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteUser response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /***************/
  /* USERS - END */
  /***************/
  
  /***********************/
  /* TYPOI ERGWN - START */
  /***********************/

  public async getAllTypoysErgwn() : Promise<Array<TyposErgou>> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllTypoysErgwn', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getAllTypoysErgwn: " + JSON.stringify(responseJson,null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  public async addTypoErgou(tmpTyposErgou : TyposErgou) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addTypoErgou', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          typos: tmpTyposErgou.typos
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addTypoErgou response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editTypoErgou(tmpTyposErgou : TyposErgou) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editTypoErgou', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpTyposErgou.id,
          typos: tmpTyposErgou.typos
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editTypoErgou response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteTypoErgou(tmpTyposErgouID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteTypoErgou', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpTyposErgouID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteTypoErgou response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /*********************/
  /* TYPOI ERGWN - END */
  /*********************/

  /***********************/
  /* ERGAZOMENOI - START */
  /***********************/

  public async getAllErgazomenoi() : Promise<Array<Ergazomenos>> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllErgazomenoi', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getAllErgazomenoi: " + JSON.stringify(responseJson,null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  public async addErgazomeno(tmpErgazomenos : Ergazomenos) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addErgazomeno', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          onoma: tmpErgazomenos.onoma,
          epwnymo: tmpErgazomenos.epwnymo,
          thlefwno: tmpErgazomenos.thlefwno,
          id_kathgorias: tmpErgazomenos.id_kathgorias,
          trapeza: tmpErgazomenos.trapeza,
          iban: tmpErgazomenos.iban,
          kostos_hmeromisthiou_kathimerinh: tmpErgazomenos.kostos_hmeromisthiou_kathimerinh,
          kostos_hmeromisthiou_savvato: tmpErgazomenos.kostos_hmeromisthiou_savvato,
          kostos_hmeromisthiou_kyriakh: tmpErgazomenos.kostos_hmeromisthiou_kyriakh,
          kostos_yperwrias: tmpErgazomenos.kostos_yperwrias,
          kostos_dianyktereyshs: tmpErgazomenos.kostos_dianyktereyshs,
          kostos_efka_kathimerinh: tmpErgazomenos.kostos_efka_kathimerinh,
          kostos_efka_savvato: tmpErgazomenos.kostos_efka_savvato,
          kostos_odoiporikwn_ana_wra: tmpErgazomenos.kostos_odoiporikwn_ana_wra
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addErgazomeno response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editErgazomeno(tmpErgazomenos : Ergazomenos) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editErgazomeno', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpErgazomenos.id,
          onoma: tmpErgazomenos.onoma,
          epwnymo: tmpErgazomenos.epwnymo,
          thlefwno: tmpErgazomenos.thlefwno,
          id_kathgorias: tmpErgazomenos.id_kathgorias,
          trapeza: tmpErgazomenos.trapeza,
          iban: tmpErgazomenos.iban,
          kostos_hmeromisthiou_kathimerinh: tmpErgazomenos.kostos_hmeromisthiou_kathimerinh,
          kostos_hmeromisthiou_savvato: tmpErgazomenos.kostos_hmeromisthiou_savvato,
          kostos_hmeromisthiou_kyriakh: tmpErgazomenos.kostos_hmeromisthiou_kyriakh,
          kostos_yperwrias: tmpErgazomenos.kostos_yperwrias,
          kostos_dianyktereyshs: tmpErgazomenos.kostos_dianyktereyshs,
          kostos_efka_kathimerinh: tmpErgazomenos.kostos_efka_kathimerinh,
          kostos_efka_savvato: tmpErgazomenos.kostos_efka_savvato,
          kostos_odoiporikwn_ana_wra: tmpErgazomenos.kostos_odoiporikwn_ana_wra
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editErgazomeno response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteErgazomeno(tmpErgazomenosID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteErgazomeno', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpErgazomenosID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteErgazomeno response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /*********************/
  /* ERGAZOMENOI - END */
  /*********************/

  /*************************************/
  /* ERGA ERGAZOMENOI ANA ERGO - START */
  /*************************************/

  public async getAllErgazomenoiAnaErgo() : Promise<Array<ErgoErgazomenos>> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllErgazomenoiAnaErgo', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getAllErgazomenoiAnaErgo: " + JSON.stringify(responseJson,null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  /***********************************/
  /* ERGA ERGAZOMENOI ANA ERGO - END */
  /***********************************/

  /********************************/
  /* ERGAZOMENOI ANA MERA - START */
  /********************************/

  public async getAllErgazomenoiAnaMera() : Promise<Array<ErgazomenosAnaMera>> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllErgazomenoiAnaMera', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getAllErgazomenoiAnaMera: " + JSON.stringify(responseJson,null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  public async getErgazomenoAnaMeraForSpecificErgoAndMonth(tmpHmeromhnia_MMYYYY: string, tmpIdErgou: number) : Promise<Array<ErgazomenosAnaMera>> {
    //console.log("getErgazomenoAnaMeraForSpecificErgoAndMonth -> tmpHmeromhnia_MMYYYY: " + tmpHmeromhnia_MMYYYY + ", tmpIdErgou: " + tmpIdErgou);
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getErgazomenoAnaMeraForSpecificErgoAndMonth', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          hmeromhnia: tmpHmeromhnia_MMYYYY,
          wresAnaErgo: [
            {
              id_ergou: tmpIdErgou
            }
          ]
        }
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getErgazomenoAnaMeraForSpecificErgoAndMonth: " + JSON.stringify(responseJson,null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  public async addErgazomenoAnaMera(tmpErgazomenosAnaMera : ErgazomenosAnaMera) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addErgazomenoAnaMera', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          hmeromhnia: tmpErgazomenosAnaMera.hmeromhnia,
          adeia: tmpErgazomenosAnaMera.adeia,
          id_ergazomenou: tmpErgazomenosAnaMera.id_ergazomenou,
          savvato: tmpErgazomenosAnaMera.savvato,
          kyriakh: tmpErgazomenosAnaMera.kyriakh,
          wresAnaErgo: [
            {
              id_ergou: tmpErgazomenosAnaMera.wresAnaErgo[0].id_ergou,
              wres_ergasias: tmpErgazomenosAnaMera.wresAnaErgo[0].wres_ergasias,
              yperwries: tmpErgazomenosAnaMera.wresAnaErgo[0].yperwries,
              dianyktereysh: tmpErgazomenosAnaMera.wresAnaErgo[0].dianyktereysh,
              odoiporika: tmpErgazomenosAnaMera.wresAnaErgo[0].odoiporika,
              id_kentrou_kostous: tmpErgazomenosAnaMera.wresAnaErgo[0].id_kentrou_kostous
            }
          ]
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addErgazomenoAnaMera response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editErgazomenoAnaMera(tmpErgazomenosAnaMera : ErgazomenosAnaMera) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editErgazomenoAnaMera', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpErgazomenosAnaMera.id,
          hmeromhnia: tmpErgazomenosAnaMera.hmeromhnia,
          adeia: tmpErgazomenosAnaMera.adeia,
          id_ergazomenou: tmpErgazomenosAnaMera.id_ergazomenou,
          savvato: tmpErgazomenosAnaMera.savvato,
          kyriakh: tmpErgazomenosAnaMera.kyriakh,
          wresAnaErgo: [
            {
              id_ergou: tmpErgazomenosAnaMera.wresAnaErgo[0].id_ergou,
              wres_ergasias: tmpErgazomenosAnaMera.wresAnaErgo[0].wres_ergasias,
              yperwries: tmpErgazomenosAnaMera.wresAnaErgo[0].yperwries,
              dianyktereysh: tmpErgazomenosAnaMera.wresAnaErgo[0].dianyktereysh,
              odoiporika: tmpErgazomenosAnaMera.wresAnaErgo[0].odoiporika,
              id_kentrou_kostous: tmpErgazomenosAnaMera.wresAnaErgo[0].id_kentrou_kostous
            }
          ]
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editErgazomenoAnaMera response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteErgazomenoAnaMera(tmpErgazomenosAnaMeraID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteErgazomenoAnaMera', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpErgazomenosAnaMeraID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteErgazomenoAnaMera response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /******************************/
  /* ERGAZOMENOI ANA MERA - END */
  /******************************/
  
  /**********************************/
  /* KATHGORIES ERGAZOMENWN - START */
  /**********************************/

  public async getAllKathgoriesErgazomenwn() : Promise<Array<KathgoriaErgazomenwn>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllKathgoriesErgazomenwn', {
          method: 'POST',
          headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
          //console.log("getAllKathgoriesErgazomenwn: " + JSON.stringify(responseJson,null, 2));
          return responseJson;
      })
      .catch(error => {
          console.error(error);
      });
  }

  public async addKathgoriaErgazomenwn(tmpKathgoriaErgazomenwn : KathgoriaErgazomenwn) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addKathgoriaErgazomenwn', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          kathgoria: tmpKathgoriaErgazomenwn.kathgoria
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addKathgoriaErgazomenwn response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editKathgoriaErgazomenwn(tmpKathgoriaErgazomenwn : KathgoriaErgazomenwn) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editKathgoriaErgazomenwn', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpKathgoriaErgazomenwn.id,
          kathgoria: tmpKathgoriaErgazomenwn.kathgoria
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editKathgoriaErgazomenwn response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteKathgoriaErgazomenwn(tmpKathgoriaErgazomenwnID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteKathgoriaErgazomenwn', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpKathgoriaErgazomenwnID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteKathgoriaErgazomenwn response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /********************************/
  /* KATHGORIES ERGAZOMENWN - END */
  /********************************/

  /**************************/
  /* KENTRA KOSTOYS - START */
  /**************************/

  public async getAllKentraKostous() : Promise<Array<KentroKostous>> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllKentraKostous', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getAllKentraKostous: " + JSON.stringify(responseJson,null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  public async addKentroKostous(tmpKentroKostous : KentroKostous) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addKentroKostous', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          kwdikos: tmpKentroKostous.kwdikos,
          perigrafh: tmpKentroKostous.perigrafh,
          tha_enhmerwnei_vivlio_kostologiou: tmpKentroKostous.tha_enhmerwnei_vivlio_kostologiou,
          typoiErgwn_ids: tmpKentroKostous.typoiErgwn_ids
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addKentroKostous response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editKentroKostous(tmpKentroKostous : KentroKostous) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editKentroKostous', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpKentroKostous.id,
          kwdikos: tmpKentroKostous.kwdikos,
          perigrafh: tmpKentroKostous.perigrafh,
          tha_enhmerwnei_vivlio_kostologiou: tmpKentroKostous.tha_enhmerwnei_vivlio_kostologiou,
          typoiErgwn_ids: tmpKentroKostous.typoiErgwn_ids
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editKentroKostous response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteKentroKostous(tmpKentroKostousID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteKentroKostous', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpKentroKostousID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteKentroKostous response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /************************/
  /* KENTRA KOSTOYS - END */
  /************************/

  /****************/
  /* ERGA - START */
  /****************/

  public async getAllErga() : Promise<Array<Ergo>> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllErga', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getAllErga: " + JSON.stringify(responseJson, null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  public async addErgo(tmpErgo : Ergo) : Promise<string | void> {
    //console.log("addErgo -> tmpErgo: " + JSON.stringify(tmpErgo, null, 2));
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addErgo', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          onoma: tmpErgo.onoma,
          kwdikos: tmpErgo.kwdikos,
          id_typou: tmpErgo.id_typou,
          id_mhxanikou: tmpErgo.id_mhxanikou,
          id_ergodhgou: tmpErgo.id_ergodhgou,
          ergazomenoi_ids: tmpErgo.ergazomenoi_ids
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addErgo response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editErgo(tmpErgo : Ergo) : Promise<string | void> {
    //console.log("editErgo -> tmpErgo: " + JSON.stringify(tmpErgo, null, 2));
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editErgo', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpErgo.id,
          onoma: tmpErgo.onoma,
          kwdikos: tmpErgo.kwdikos,
          id_typou: tmpErgo.id_typou,
          id_mhxanikou: tmpErgo.id_mhxanikou,
          id_ergodhgou: tmpErgo.id_ergodhgou,
          ergazomenoi_ids: tmpErgo.ergazomenoi_ids
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editErgo response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteErgo(tmpErgoID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteErgo', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpErgoID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteErgo response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /**************/
  /* ERGA - END */
  /**************/

  /******************/
  /* ARGIES - START */
  /******************/

  public async getAllArgies() : Promise<Array<Argia>> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/getAllArgies', {
        method: 'POST',
        headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword
      }),
    })
    .then(response => response.json())
    .then(responseJson => {
        //console.log("getAllArgies: " + JSON.stringify(responseJson, null, 2));
        return responseJson;
    })
    .catch(error => {
        console.error(error);
    });
  }

  public async addArgia(tmpArgia : Argia) : Promise<string | void> {
    //console.log("Argia -> tmpArgia: " + JSON.stringify(tmpArgia, null, 2));
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/addArgia', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          hmeromhnia: tmpArgia.hmeromhnia,
          perigrafh: tmpArgia.perigrafh
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("addArgia response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async editArgia(tmpArgia : Argia) : Promise<string | void> {
    //console.log("editArgia -> tmpArgia: " + JSON.stringify(tmpArgia, null, 2));
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/editArgia', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpArgia.id,
          hmeromhnia: tmpArgia.hmeromhnia,
          perigrafh: tmpArgia.perigrafh
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("editArgia response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  public async deleteArgia(tmpArgiaID: number) : Promise<string | void> {
    return fetch(GUIManager.getInstance().ngrokURL + '/AppLogistiriou_WS/logistirio/deleteArgia', {
      method: 'POST',
      headers: {
        Accept: 'application/json; charset=UTF-8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: LoginManager.getInstance().username.toLowerCase(),
        password: LoginManager.getInstance().md5EncodedPassword,
        serviceInput: {
          id: tmpArgiaID
        }
      }),
    })
    .then(response => response.text())
    .then(responseStr => {
      //console.log("deleteArgia response:", responseStr);
      return responseStr;
    })
    .catch(error => {
      console.error(error);
    });
  }

  /****************/
  /* ARGIES - END */
  /****************/

}

export default RestManager;