// CustomCheckboxRenderer.tsx
import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Checkbox } from 'react-native-paper';

const CustomCheckboxRenderer = (props: any) => {
  const [checked, setChecked] = useState(props.value);

  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    props.setValue(newChecked.toString()); // Update the grid with the new value
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text style={{ marginRight: 10 }}>{props.label}</Text>
      <Checkbox
        status={checked ? 'checked' : 'unchecked'}
        onPress={handleChange}
      />
    </View>
  );
};

export default CustomCheckboxRenderer;