import { CardContent } from '@material-ui/core';
import ConstManager from '../managers/ConstManager';
import { Dimensions, StyleSheet } from 'react-native';
import { Colors } from 'react-native-paper';

const screenDimensions = Dimensions.get('window');
const backgroundImage = require('../assets/background3.jpg');

const myCss = StyleSheet.create({
  bottomNavigation: {
    width: screenDimensions.width > ConstManager.getInstance().WEB_MAX_WIDTH ? ConstManager.getInstance().WEB_MAX_WIDTH : screenDimensions.width - ConstManager.getInstance().WIDTH_NEEDED_FOR_MENU,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    alignSelf: 'center',
    //padding: 10,
    //width: 1000,
    //height: 4500
    //justifyContent: 'flex-start',
    //backgroundImage: `url(${backgroundImage})`
    width: screenDimensions.width > ConstManager.getInstance().WEB_MAX_WIDTH ? ConstManager.getInstance().WEB_MAX_WIDTH : screenDimensions.width - ConstManager.getInstance().WIDTH_NEEDED_FOR_MENU,
    height: screenDimensions.height
  },
  containerGallery: {
    backgroundImage: `url(${backgroundImage})`
  },
  dialog: {
    backgroundColor: '#ecf0f1',
    alignContent: 'center',
    alignSelf: 'center',
    width: ConstManager.getInstance().WEB_MAX_WIDTH/1.8,
    maxWidth: ConstManager.getInstance().WEB_MAX_WIDTH/1.8
  },
  divider: {
    width: screenDimensions.width - 40,
    maxWidth: ConstManager.getInstance().WEB_MAX_WIDTH,
    backgroundColor: 'lightgray'
  },
  domainDropdownModal: {
    alignSelf: 'center',
    backgroundColor: Colors.blueGrey100,
    paddingBottom: 5,
    width: screenDimensions.width<=ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width*0.75 : ConstManager.getInstance().WEB_MAX_WIDTH*0.75
  },
  dropdownFullWidth: {
    alignSelf: 'center',
    backgroundColor: Colors.blueGrey100,
    paddingBottom: 5,
    width: screenDimensions.width < ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width-30 : ConstManager.getInstance().WEB_MAX_WIDTH-30
  },
  footer: {
    width: screenDimensions.width - 40,
    maxWidth: ConstManager.getInstance().WEB_MAX_WIDTH,
    //backgroundColor: 'blue',
    alignItems: 'center',
    marginTop: 10
  },
  footerText: {
    color: 'gray',
    fontSize: 12,
    marginTop: 5
  },
  header: {
    width: screenDimensions.width-20,
    marginTop: 5,
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  iconButton: {
    marginTop: 5
  },
  iconButtonLabel: {
    
  },
  iconButtonContent: {

  },
  mainApp: {
    width: screenDimensions.width - 40,
    maxWidth: ConstManager.getInstance().WEB_MAX_WIDTH,
    alignItems: 'center',
    //height: screenDimensions.height - 80,
    //backgroundColor: 'black'
  },
  menu: { 
    position: 'absolute',
    top: 120,
    left: screenDimensions.width < ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width-400 : (screenDimensions.width-ConstManager.getInstance().WEB_MAX_WIDTH)/2 + ConstManager.getInstance().WEB_MAX_WIDTH-282,
    overflow: 'visible'
  },
  modalContainer: {
    flex: 0.8,
    margin: 0,
    backgroundColor: Colors.blueGrey100,
    borderWidth: 4,
    borderColor: 'white',
    borderRadius: 5,
    alignSelf: "center",
    justifyContent: "flex-start",
    width: screenDimensions.width<=ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width*0.8 : ConstManager.getInstance().WEB_MAX_WIDTH*0.8,
    height: screenDimensions.height<=ConstManager.getInstance().WEB_MAX_HEIGHT ? screenDimensions.height*0.8 : ConstManager.getInstance().WEB_MAX_HEIGHT-(ConstManager.getInstance().WEB_MAX_HEIGHT/4)
},
  navigationBarElement: {
    color: 'white', 
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10
  },
  switch: {
    alignItems:'flex-end',
    flex: 1,
    marginRight: 70,
    marginBottom: 20
  },
  switchText: {
    fontSize: 16,  
    alignItems:'flex-start',
    marginLeft: 70,
    flex:10
  },
  tBcontainer: {
    flex: 1,
    padding: 10,
  },
  tBtableHeader: {
    flexDirection: 'row',
  },
  tBheaderRow: {
    flexDirection: 'row',
  },
  tBheaderCell: {
    padding: 10,
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: 'aliceblue',
  },
  tBrow: {
    flexDirection: 'row',
  },
  tBcell: {
    padding: 10,
    borderWidth: 1,
    borderColor: 'gray',
    backgroundColor: 'papayawhip',
  },
  tBtableFooter: {
    flexDirection: 'row',
  },
  tBfooterRow: {
    flexDirection: 'row',
  },
  tBfooterCell: {
    padding: 10,
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: 'aliceblue',
  },
  tBbuttonContainer: {
    marginTop: 10,
  },
  tBheader: {
    fontWeight: 'bold',
  },
  tableRow: {
    paddingLeft: 0, 
    paddingTop: 0, 
    paddingRight: 0
  },
  tableTitle: {
      flex: 3.5, 
      backgroundColor: Colors.blueGrey100, 
      justifyContent: 'space-evenly',
      paddingTop: 20
  },
  tableValueColumn: {
      flex: 5, 
      justifyContent: 'space-evenly',
      paddingLeft: 5
  },
  tableTitleKentraKostous: {
    flex: 5, 
    backgroundColor: Colors.blueGrey100, 
    justifyContent: 'space-evenly',
    paddingTop: 20
  },
  tableValueColumnKentraKostous: {
      flex: 3, 
      justifyContent: 'space-evenly',
      paddingLeft: 5
  },
  tableIcon: {
      flex: 3, 
      justifyContent:'space-evenly'
  },
  text: {
    color: 'white',
    textAlign: 'justify',
    marginTop: 20
  },
  textBoldRed: {
    fontWeight: 'bold',
    color: 'red'
  },
  textBoldWhite: {
    fontWeight: 'bold',
    color: 'white'
  },
  title: {
    backgroundColor: 'midnightblue',
    color: 'white',
    textAlign: 'center',
    width: ConstManager.getInstance().WEB_MAX_WIDTH
  },
  video: {
    margin: 10
  }
});

export default myCss;