import { View, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';
import myCss from '../../../css/myCss';
import Footer from '../../Footer';
import React, { useEffect, useRef, useState } from 'react';
import RestManager from '../../../managers/RestManager';
import i18n from '../../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataTable, IconButton, Colors, Title, Text, Button, Portal, Modal, TextInput, Dialog, Paragraph } from 'react-native-paper';
import LoginManager from '../../../managers/LoginManager';
import ConstManager from '../../../managers/ConstManager';
import AwesomeAlert from 'react-native-awesome-alerts';


const ManageTypousErgwnScreen = () => {
  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ManageTypousErgwnScreen: I'm ready!");
    setIsLoading(true);
    (async () => {
      const tmpAllTypoiErgwn: Array<User> = await RestManager.getInstance().getAllTypoysErgwn();
      setTypoiErgwn(tmpAllTypoiErgwn);
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);
  const [typoiErgwn, setTypoiErgwn] = useState<Array<TyposErgou>>([]);

  const [newTyposErgouModalVisible, setNewTyposErgouModalVisible] = useState(false);
  const hideNewTyposErgouModal = () => { 
    setNewTyposErgouModalVisible(false);
  }

  const [editTyposErgouModalVisible, setEditTyposErgouModalVisible] = useState(false);
  const hideEditTyposErgouModal = () => { 
    setEditTyposErgouModalVisible(false);
  }

  const [newTyposErgou, setNewTyposErgou] = useState<TyposErgou>({
    id: -1,
    typos: ""
  });

  const [editedTyposErgou, setEditedTyposErgou] = useState<TyposErgou>({
    id: -1,
    typos: ""
  });

  // Create a ref for the TextInput components
  const typosNewInputRef = useRef(null);
  
  const typosEditInputRef = useRef(null);

  const [alertForNewWebModalVisible, setAlertForNewWebModalVisible] = useState(false);
  const [alertForNewWebModalText, setAlertForNewWebModalText] = useState('');

  const [alertForEditWebModalVisible, setAlertForEditWebModalVisible] = useState(false);
  const [alertForEditWebModalText, setAlertForEditWebModalText] = useState('');

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  async function addNewTyposErgouAsync() 
  {
    if(newTyposErgou.typos == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε τύπο!");
      setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().addTypoErgou(newTyposErgou);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().TYPOSERGOU_ADDED_SUCCESSFULLY) {
          msgToShow = "Ο τύπος έργου προστέθηκε επιτυχώς!";
          //update array
          const tmpAllTypoiErgwn: Array<User> = await RestManager.getInstance().getAllTypoysErgwn();
          setTypoiErgwn(tmpAllTypoiErgwn);
          //init new obj
          setNewTyposErgou({
            id: -1,
            typos: ""
          });
        }
        setNewTyposErgouModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  async function editTyposErgouAsync() 
  {
    if(editedTyposErgou.typos == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε τύπο!");
      setAlertForEditWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().editTypoErgou(editedTyposErgou);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().TYPOSERGOU_EDITED_SUCCESSFULLY) {
          msgToShow = "Ο τύπος έργου ενημερώθηκε επιτυχώς!";
          //update array
          const tmpAllTypoiErgwn: Array<User> = await RestManager.getInstance().getAllTypoysErgwn();
          setTypoiErgwn(tmpAllTypoiErgwn);
          //init edit obj
          setEditedTyposErgou({
            id: -1,
            typos: ""
          });
        }
        setEditTyposErgouModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const hideDeleteDialog = () => setDeleteDialogVisible(false);

  const [typosErgouIDToBeDeleted, setTyposErgouIDToBeDeleted] = useState(-1);

  async function deleteTyposErgouAsync() {
    hideDeleteDialog();
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    webServiceResponseStr = await RestManager.getInstance().deleteTypoErgou(typosErgouIDToBeDeleted);
    if(typeof webServiceResponseStr === 'string') {
      var msgToShow: string = "";
      if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
        msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
      else if(webServiceResponseStr == ConstManager.getInstance().TYPOSERGOU_DELETED_SUCCESSFULLY) {
        msgToShow = "Ο τύπος έργου διεγράφη επιτυχώς!";
        //update array
        const tmpAllTypoiErgwn: Array<User> = await RestManager.getInstance().getAllTypoysErgwn();
          setTypoiErgwn(tmpAllTypoiErgwn);
        //init new TyposErgoiu obj
        setNewTyposErgou({
          id: -1,
          typos: ""
        });
      }
      setAlertForWebText(msgToShow);
      setAlertForWebVisible(true);
    }
    setIsLoading(false);
  }
  
  return (
    <SafeAreaView>
      <ScrollView>

      <Portal>
          <Modal visible={newTyposErgouModalVisible} onDismiss={hideNewTyposErgouModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Νέος τύπος έργου</Title>
              <TouchableOpacity onPress={() => typosNewInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={typosNewInputRef}
                  label="Τύπος"
                  value={newTyposErgou.typos}
                  onChangeText={tmpTypos => setNewTyposErgou({...newTyposErgou, typos: tmpTypos })}
                />
              </TouchableOpacity>
              <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={addNewTyposErgouAsync}>{i18n.t('save')}</Button>
              <AwesomeAlert
                show={alertForNewWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForNewWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Modal visible={editTyposErgouModalVisible} onDismiss={hideEditTyposErgouModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Υπάρχων τύπος έργου</Title>
              <TouchableOpacity onPress={() => typosEditInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={typosEditInputRef}
                  label="Τύπος"
                  value={editedTyposErgou.typos}
                  onChangeText={tmpTypos => setEditedTyposErgou({...editedTyposErgou, typos: tmpTypos })}
                />
              </TouchableOpacity>
              <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={editTyposErgouAsync}>{i18n.t('save')}</Button>
              <AwesomeAlert
                show={alertForEditWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForEditWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Dialog visible={deleteDialogVisible} onDismiss={hideDeleteDialog} style={myCss.dialog}>
            <Dialog.Title>Διαγραφή τύπου έργου!</Dialog.Title>
            <Dialog.Content>
              <Paragraph>{i18n.t('areYouSure')}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setDeleteDialogVisible(false)}>{i18n.t('cancel')}</Button>
              <Button onPress={() => deleteTyposErgouAsync()}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        <View style={myCss.container}>
          <Spinner
            //visibility of Overlay Loading Spinner
            visible={isLoading}
            //Text with the Spinner
            textContent={i18n.t('loading')}
            //Text style of the Spinner Text
            textStyle={myCss.spinnerTextStyle}
          />

          <Title style={myCss.title}>Τύποι έργων</Title>
          <DataTable>
            {typoiErgwn.map((typosErgou: TyposErgou, i) => (             
              <DataTable.Row key={i} style={myCss.tableRow}>
                <DataTable.Title style={myCss.tableTitle}>{i18n.t('perigrafh')}</DataTable.Title>
                <DataTable.Cell style={myCss.tableValueColumn}>{typosErgou.typos}</DataTable.Cell>
                <DataTable.Cell style={myCss.tableIcon}>
                <IconButton
                    icon={({ color, size }) => (<MaterialIcons name="edit" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      const tempTyposErgouToBeEdited: TyposErgou = {
                                        id: typosErgou.id,
                                        typos: typosErgou.typos
                                      };
                                      setEditedTyposErgou(tempTyposErgouToBeEdited); 
                                      setEditTyposErgouModalVisible(true);
                                   }} />
                  <IconButton
                    icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      setTyposErgouIDToBeDeleted(typosErgou.id);
                                      setDeleteDialogVisible(true);
                                   }} />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
          <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="add" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} 
                                          onPress={() => {
                                                            const tempNewTypoErgou: TyposErgou = {
                                                              id: -1,
                                                              typos: ""
                                                            };
                                                            setNewTyposErgou(tempNewTypoErgou); 
                                                            setNewTyposErgouModalVisible(true);
                                                         }}>{i18n.t('addNewTypoErgou')}</Button>
          <AwesomeAlert
            show={alertForWebVisible}
            showProgress={false}
            title={i18n.t('caution')}
            message={alertForWebText}
            closeOnTouchOutside={false}
            closeOnHardwareBackPress={false}
            showConfirmButton={true}
            confirmText="OK"
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => {
              setAlertForWebVisible(false);
            }}
            onConfirmPressed={() => {
              setAlertForWebVisible(false);
            }}  
          />        
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ManageTypousErgwnScreen;