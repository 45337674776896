import React, { memo, useEffect, useRef, useState } from "react";
import { FlatList, Platform, View } from "react-native";
import { Button, Dialog, Paragraph, Appbar, Portal, Menu, Divider, Badge, Text } from "react-native-paper";
import { useNavigation } from '@react-navigation/native';
import i18n from '../../utils/i18n';
import LoginManager from "../../managers/LoginManager";
import myCss from "../../css/myCss";
import { MaterialCommunityIcons } from "@expo/vector-icons";
   
type Props = {
  screenTitle: string;
};  
  
const AdminMenuOptions = ({ 
                            screenTitle
                          }: Props) => {
  const navigation = useNavigation();

  const [menuVisible, setMenuVisible] = useState(false);
  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);
  const hideLogoutDialog = () => setLogoutDialogVisible(false);

  const MORE_ICON = Platform.OS === 'ios' ? 'dots-horizontal' : 'dots-vertical';
  
  function goToManageUsers() {
    closeMenu();
    navigation.navigate('ManageUsers', { title: i18n.t('users'), backLabel: i18n.t('back') });
  }

  function goToManageKathgoriesErgazomenwn() {
    closeMenu();
    navigation.navigate('ManageKathgoriesErgazomenwnScreen', { title: i18n.t('kathgoriesErgazomenwn'), backLabel: i18n.t('back') });
  }

  function goToManageTypousErgwn() {
    closeMenu();
    navigation.navigate('ManageTypousErgwnScreen', { title: i18n.t('typoiErgwn'), backLabel: i18n.t('back') });
  }

  function goToManageKentraKostous() {
    closeMenu();
    navigation.navigate('ManageKentraKostousScreen', { title: i18n.t('kentraKostous'), backLabel: i18n.t('back') });
  }

  function goToManageArgies() {
    closeMenu();
    navigation.navigate('ManageArgiesScreen', { title: i18n.t('argies'), backLabel: i18n.t('back') });
  }
  
  function logout() {
    setLogoutDialogVisible(false);

    LoginManager.getInstance().logout();
    navigation.reset({
      index: 1,
      routes: [{ name: 'Home' }]
      //key: null
    });
  }

  return (
    <Appbar.Header>
        <Appbar.Content title={screenTitle} />
        <Portal>
          <Dialog visible={logoutDialogVisible} onDismiss={hideLogoutDialog} style={myCss.dialog}>
            <Dialog.Title>{i18n.t('logout')}</Dialog.Title>
            <Dialog.Content>
              <Paragraph>{i18n.t('areYouSure')}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setLogoutDialogVisible(false)}>{i18n.t('cancel')}</Button>
              <Button onPress={() => logout()}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        
        <Menu
          visible={menuVisible}
          onDismiss={closeMenu}
          anchor={<Appbar.Action icon={({ color, size }) => <MaterialCommunityIcons name={MORE_ICON} size={size} color="white" />} onPress={openMenu} />}
          style={myCss.menu}
          >
            <Menu.Item onPress={goToManageUsers} title={i18n.t('users')} icon={({ color, size }) => (<MaterialCommunityIcons name="account-group" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={goToManageKathgoriesErgazomenwn} title={i18n.t('kathgoriesErgazomenwn')} icon={({ color, size }) => (<MaterialCommunityIcons name="account-details" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={goToManageTypousErgwn} title={i18n.t('typoiErgwn')} icon={({ color, size }) => (<MaterialCommunityIcons name="format-list-bulleted-type" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={goToManageKentraKostous} title={i18n.t('kentraKostous')} icon={({ color, size }) => (<MaterialCommunityIcons name="cash-multiple" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={goToManageArgies} title={i18n.t('argies')} icon={({ color, size }) => (<MaterialCommunityIcons name="calendar" color={color} size={size} />)} />
            <Divider />
            <Menu.Item onPress={() => {closeMenu(); setLogoutDialogVisible(true);}} title={i18n.t('logout')} icon={({ color, size }) => (<MaterialCommunityIcons name="logout" color={color} size={size} />)} />
        </Menu>            
    </Appbar.Header>
   );
  };
 
  export default memo(AdminMenuOptions);