// -*- coding: utf-8 -*-
import { MD5 } from 'crypto-js';
import ConstManager from './ConstManager';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import jsPDF from 'jspdf';


interface ImageDimensions {
    width: number;
    height: number;
}

class UtilsManager {

    private static instance: UtilsManager;

    private constructor() { 

    }

    public static getInstance(): UtilsManager {
        if (!UtilsManager.instance) {
            UtilsManager.instance = new UtilsManager();
        }

        return UtilsManager.instance;
    }

    public async generateMD5Hash_forWeb(input) {
        try {
            const hash = await MD5(input).toString();
            return hash;
        } catch (error) {
            throw error;
        }
    }
    
    public emailIsValid(email) : boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const res = re.test(String(email).toLowerCase());
        //console.log("emailIsValid: ", res, " (", email, ")");
        return res;
    }

    public getDaysOfMonth(selectedDate: Date): DayInfo[] {
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth();
    
        // Get the number of days in the selected month
        const daysInMonth = new Date(year, month + 1, 0).getDate();
    
        // Initialize an array to hold the day information
        const daysArray: DayInfo[] = [];
    
        // Loop through each day of the month
        for (let day = 1; day <= daysInMonth; day++) {
          const date = new Date(year, month, day);
          const day_id = date.getDate().toString().padStart(2, '0');
          const month_id = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
          const day_name = date.toLocaleDateString('el', { weekday: 'long' }); // Get day name in Greek
    
          // Add the year to the day information
          daysArray.push({ day_id, month_id, day_name, year });
        }
    
        //console.log("UtilsManager - getDaysOfMonth -> " + JSON.stringify(daysArray, null, 2));
        return daysArray;
    }

    public getDateInFormatMMYYYY(selectedDate: Date): string {
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
        const year = selectedDate.getFullYear();
 
        const res: string = month + "/" + year;
    
        //console.log("UtilsManager - getDateInFormatMMYYYY -> " + res);
        return res;
    }

    public getDateInFormatOnomaMhnaEtos(selectedDate: Date): string {
        const month = selectedDate.getMonth(); // Months start from 0
        let monthName = "";
        if(month == 0)
            monthName = "Ιανουάριος";
        else if(month == 1)
            monthName = "Φεβρουάριος";
        else if(month == 2)
            monthName = "Μάρτιος";
        else if(month == 3)
            monthName = "Απρίλιος";
        else if(month == 4)
            monthName = "Μάιος";
        else if(month == 5)
            monthName = "Ιούνιος";
        else if(month == 6)
            monthName = "Ιούλιος";
        else if(month == 7)
            monthName = "Αύγουστος";
        else if(month == 8)
            monthName = "Σεπτέμβριος";
        else if(month == 9)
            monthName = "Οκτώβριος";
        else if(month == 10)
            monthName = "Νοέμβριος";
        else if(month == 11)
            monthName = "Δεκέμβριος";
        const year = selectedDate.getFullYear();

        const res: string = monthName + " " + year;
    
        //console.log("UtilsManager - getDateInFormatOnomaMhnaEtos -> " + res);
        return res;
    }

    public getErgazomenosAnaMeraObjFromTableRowObject(tmpIDErgou: number, tmpRowDataObj: any) : ErgazomenosAnaMera[] {
        let resErgazomenoiAnaMera: ErgazomenosAnaMera[] = [];
        
        let tmpHmeromhnia: string = "";
        let tmpHmera: string = "";
        let tmpIsSavvato: boolean = false;
        let tmpIsKyriakh: boolean = false;
        
        const tmpOnomaHmeras: string = "";
        //there is only one hmeromhnia element!
        if(tmpRowDataObj.th_hmeromhnia != null) {
            const tmpSpaceIndex: number = tmpRowDataObj.th_hmeromhnia.indexOf(" ");
            tmpHmeromhnia = tmpRowDataObj.th_hmeromhnia.slice(0, tmpSpaceIndex);
            tmpHmera = tmpRowDataObj.th_hmeromhnia.substring(tmpSpaceIndex+1, tmpRowDataObj.th_hmeromhnia.length);
            if(tmpHmera == "Σάββατο")
                tmpIsSavvato = true;
            else if(tmpHmera == "Κυριακή")
                tmpIsKyriakh = true;
            //console.log("tmpHmera: " + tmpHmera);
        }
        
        let tmpRowDataObjStr: string = JSON.stringify(tmpRowDataObj);
        let tmpCounter = 1;
        while(tmpRowDataObjStr.includes("_adeia")
                || tmpRowDataObjStr.includes("_kentro_kostous")
                || tmpRowDataObjStr.includes("_kostos_hmeromisthiou_kathimerinh")
                || tmpRowDataObjStr.includes("_kostos_hmeromisthiou_savvato")
                || tmpRowDataObjStr.includes("_kostos_hmeromisthiou_kyriakh")
                || tmpRowDataObjStr.includes("_kostos_yperwrias")
                || tmpRowDataObjStr.includes("_kostos_dianyktereyshs")
                || tmpRowDataObjStr.includes("_kostos_odoiporikwn")) {
            if(tmpRowDataObjStr.includes("_adeia")) {
                const thΕmployeeX_index: number = tmpRowDataObjStr.indexOf("thΕmployeeX_");
                const adeia_index: number = tmpRowDataObjStr.indexOf("_adeia");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeX_index + 12, adeia_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);
                let tmpAdeiaStr: string = tmpRowDataObjStr.slice(adeia_index+6+3, adeia_index+6+3+tmpRowDataObjStr.substring(adeia_index+6+3, tmpRowDataObjStr.length).indexOf("\""));
                
                let tmpAdeiaId: number = ConstManager.getInstance().OXI_ADEIA;
                if(tmpAdeiaStr!=null && tmpAdeiaStr!=undefined && tmpAdeiaStr.length>0) {
                    if(tmpAdeiaStr.includes("ID:") == false)
                        tmpAdeiaId = parseInt(tmpAdeiaStr);
                    else {
                        const id_index: number = tmpAdeiaStr.indexOf("ID:");
                        tmpAdeiaStr = tmpAdeiaStr.slice(id_index+3, tmpAdeiaStr.length);
                        //console.log("PAW NA PARW ID APO DESC -> tmpKentroKostousIdStr: " + tmpKentroKostousIdStr);
                        tmpAdeiaId = parseInt(tmpAdeiaStr);
                    }
                }

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        resErgazomenoiAnaMera[i].adeia = tmpAdeiaId;
                    }
                }
                if(idFound == false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: tmpAdeiaId,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: -1.0,
                                yperwries: -1.0,
                                dianyktereysh: false,
                                odoiporika: -1.0,
                                id_kentrou_kostous: -1
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 1 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeX_index, adeia_index+6, "done" + tmpCounter); //ayto to kanw gia na mhn ksanampei sto idio if toy while
            }
            if(tmpRowDataObjStr.includes("_kentro_kostous")) {
                const thΕmployeeA_index: number = tmpRowDataObjStr.indexOf("thΕmployeeA_");
                const kentroKostous_index: number = tmpRowDataObjStr.indexOf("_kentro_kostous");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeA_index + 12, kentroKostous_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);

                let tmpKentroKostousIdStr: string = tmpRowDataObjStr.slice(kentroKostous_index+15+3, kentroKostous_index+15+3+tmpRowDataObjStr.substring(kentroKostous_index+15+3, tmpRowDataObjStr.length).indexOf("\""));
                //console.log("tmpKentroKostousIdStr: " + tmpKentroKostousIdStr);
                let tmpKentroKostousId: number = -1;
                if(tmpKentroKostousIdStr!=null && tmpKentroKostousIdStr!=undefined && tmpKentroKostousIdStr.length>0) {
                    if(tmpKentroKostousIdStr.includes("ID:") == false)
                        tmpKentroKostousId = parseInt(tmpKentroKostousIdStr);
                    else {
                        const id_index: number = tmpKentroKostousIdStr.indexOf("ID:");
                        tmpKentroKostousIdStr = tmpKentroKostousIdStr.slice(id_index+3, tmpKentroKostousIdStr.length);
                        //console.log("PAW NA PARW ID APO DESC -> tmpKentroKostousIdStr: " + tmpKentroKostousIdStr);
                        tmpKentroKostousId = parseInt(tmpKentroKostousIdStr);
                    }
                }

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        resErgazomenoiAnaMera[i].wresAnaErgo[0].id_kentrou_kostous = tmpKentroKostousId;
                    }
                }
                if(idFound==false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: ConstManager.getInstance().OXI_ADEIA,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: -1.0,
                                yperwries: -1.0,
                                dianyktereysh: false,
                                odoiporika: -1.0,
                                id_kentrou_kostous: tmpKentroKostousId,
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 2 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeA_index, kentroKostous_index+15, "done" + tmpCounter);
            }            
            if(tmpRowDataObjStr.includes("_kostos_hmeromisthiou_kathimerinh")) {
                const thΕmployeeB_index: number = tmpRowDataObjStr.indexOf("thΕmployeeB_");
                const kostosHmeromisthiouKathimerinh_index: number = tmpRowDataObjStr.indexOf("_kostos_hmeromisthiou_kathimerinh");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeB_index + 12, kostosHmeromisthiouKathimerinh_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);
                const tmpWresErgasiasStr: string = tmpRowDataObjStr.slice(kostosHmeromisthiouKathimerinh_index+33+3, kostosHmeromisthiouKathimerinh_index+33+3+tmpRowDataObjStr.substring(kostosHmeromisthiouKathimerinh_index+33+3, tmpRowDataObjStr.length).indexOf("\""));
                let tmpWresErgasias: number = -1.0;
                if(tmpWresErgasiasStr!=null && tmpWresErgasiasStr!=undefined && tmpWresErgasiasStr.length>0)
                    tmpWresErgasias = parseFloat(tmpWresErgasiasStr.replaceAll(",", "."));

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        //console.log("paw na setarw wres kai vrhka yparxon: " + JSON.stringify(resErgazomenoiAnaMera[i], null, 2));
                        resErgazomenoiAnaMera[i].wresAnaErgo[0].wres_ergasias = tmpWresErgasias;
                    }
                }
                if(idFound == false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: ConstManager.getInstance().OXI_ADEIA,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: tmpWresErgasias,
                                yperwries: -1.0,
                                dianyktereysh: false,
                                odoiporika: -1.0,
                                id_kentrou_kostous: -1,
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 3 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeB_index, kostosHmeromisthiouKathimerinh_index+33, "done" + tmpCounter); //ayto to kanw gia na mhn ksanampei sto idio if toy while
            }
            if(tmpRowDataObjStr.includes("_kostos_hmeromisthiou_savvato")) {
                const thΕmployeeC_index: number = tmpRowDataObjStr.indexOf("thΕmployeeC_");
                const kostos_hmeromisthiouSavvato_index: number = tmpRowDataObjStr.indexOf("_kostos_hmeromisthiou_savvato");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeC_index + 12, kostos_hmeromisthiouSavvato_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);
                const tmpWresErgasiasStr: string = tmpRowDataObjStr.slice(kostos_hmeromisthiouSavvato_index+29+3, kostos_hmeromisthiouSavvato_index+29+3+tmpRowDataObjStr.substring(kostos_hmeromisthiouSavvato_index+29+3, tmpRowDataObjStr.length).indexOf("\""));
                let tmpWresErgasias: number = -1.0;
                if(tmpWresErgasiasStr!=null && tmpWresErgasiasStr!=undefined && tmpWresErgasiasStr.length>0)
                    tmpWresErgasias = parseFloat(tmpWresErgasiasStr.replaceAll(",", "."));

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        resErgazomenoiAnaMera[i].wresAnaErgo[0].wres_ergasias = tmpWresErgasias;
                    }
                }
                if(idFound == false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: ConstManager.getInstance().OXI_ADEIA,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: tmpWresErgasias,
                                yperwries: -1.0,
                                dianyktereysh: false,
                                odoiporika: -1.0,
                                id_kentrou_kostous: -1
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 4 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeC_index, kostos_hmeromisthiouSavvato_index+29, "done" + tmpCounter); //ayto to kanw gia na mhn ksanampei sto idio if toy while
            }
            if(tmpRowDataObjStr.includes("_kostos_hmeromisthiou_kyriakh")) {
                const thΕmployeeD_index: number = tmpRowDataObjStr.indexOf("thΕmployeeD_");
                const kostosHmeromisthiouKyriakh_index: number = tmpRowDataObjStr.indexOf("_kostos_hmeromisthiou_kyriakh");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeD_index + 12, kostosHmeromisthiouKyriakh_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);
                const tmpWresErgasiasStr: string = tmpRowDataObjStr.slice(kostosHmeromisthiouKyriakh_index+29+3, kostosHmeromisthiouKyriakh_index+29+3+tmpRowDataObjStr.substring(kostosHmeromisthiouKyriakh_index+29+3, tmpRowDataObjStr.length).indexOf("\""));
                let tmpWresErgasias: number = -1.0;
                if(tmpWresErgasiasStr!=null && tmpWresErgasiasStr!=undefined && tmpWresErgasiasStr.length>0)
                    tmpWresErgasias = parseFloat(tmpWresErgasiasStr.replaceAll(",", "."));

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        resErgazomenoiAnaMera[i].wresAnaErgo[0].wres_ergasias = tmpWresErgasias;
                    }
                }
                if(idFound == false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: ConstManager.getInstance().OXI_ADEIA,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: tmpWresErgasias,
                                yperwries: -1.0,
                                dianyktereysh: false,
                                odoiporika: -1.0,
                                id_kentrou_kostous: -1
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 5 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeD_index, kostosHmeromisthiouKyriakh_index+29, "done" + tmpCounter); //ayto to kanw gia na mhn ksanampei sto idio if toy while
            }
            if(tmpRowDataObjStr.includes("_kostos_yperwrias")) {
                const thΕmployeeE_index: number = tmpRowDataObjStr.indexOf("thΕmployeeE_");
                const kostosYperwrias_index: number = tmpRowDataObjStr.indexOf("_kostos_yperwrias");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeE_index + 12, kostosYperwrias_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);
                const tmpYperwriesStr: string = tmpRowDataObjStr.slice(kostosYperwrias_index+17+3, kostosYperwrias_index+17+3+tmpRowDataObjStr.substring(kostosYperwrias_index+17+3, tmpRowDataObjStr.length).indexOf("\""));
                let tmpYperwries: number = -1.0;
                if(tmpYperwriesStr!=null && tmpYperwriesStr!=undefined && tmpYperwriesStr.length>0)
                    tmpYperwries = parseFloat(tmpYperwriesStr.replaceAll(",", "."));

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        resErgazomenoiAnaMera[i].wresAnaErgo[0].yperwries = tmpYperwries;
                    }
                }
                if(idFound == false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: ConstManager.getInstance().OXI_ADEIA,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: -1.0,
                                yperwries: tmpYperwries,
                                dianyktereysh: false,
                                odoiporika: -1.0,
                                id_kentrou_kostous: -1
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 6 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeE_index, kostosYperwrias_index+17, "done" + tmpCounter); //ayto to kanw gia na mhn ksanampei sto idio if toy while
            }
            if(tmpRowDataObjStr.includes("_kostos_dianyktereyshs")) {
                const thΕmployeeF_index: number = tmpRowDataObjStr.indexOf("thΕmployeeF_");
                const dianyktereysh_index: number = tmpRowDataObjStr.indexOf("_kostos_dianyktereyshs");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeF_index + 12, dianyktereysh_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);
                const tmpDianyktereyshStr: string = tmpRowDataObjStr.slice(dianyktereysh_index+22+3, dianyktereysh_index+22+3+tmpRowDataObjStr.substring(dianyktereysh_index+22+3, tmpRowDataObjStr.length).indexOf("\""));
                let tmpDianyktereysh: boolean = false;
                if(tmpDianyktereyshStr!=null && tmpDianyktereyshStr!=undefined && tmpDianyktereyshStr.length>0)
                    tmpDianyktereysh = tmpDianyktereyshStr === "true";

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        resErgazomenoiAnaMera[i].wresAnaErgo[0].dianyktereysh = tmpDianyktereysh;
                    }
                }
                if(idFound == false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: ConstManager.getInstance().OXI_ADEIA,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: -1.0,
                                yperwries: -1.0,
                                dianyktereysh: tmpDianyktereysh,
                                odoiporika: -1.0,
                                id_kentrou_kostous: -1
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 7 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeF_index, dianyktereysh_index+22, "done" + tmpCounter); //ayto to kanw gia na mhn ksanampei sto idio if toy while
            }
            if(tmpRowDataObjStr.includes("_kostos_odoiporikwn")) {
                const thΕmployeeG_index: number = tmpRowDataObjStr.indexOf("thΕmployeeG_");
                const odoiporika_index: number = tmpRowDataObjStr.indexOf("_kostos_odoiporikwn");
                const tmpErgazomenosIDStr: string = tmpRowDataObjStr.slice(thΕmployeeG_index + 12, odoiporika_index);
                const tmpIdErgazomenou: number = parseInt(tmpErgazomenosIDStr, 10);
                const tmpOdoiporikAStr: string = tmpRowDataObjStr.slice(odoiporika_index+19+3, odoiporika_index+19+3+tmpRowDataObjStr.substring(odoiporika_index+19+3, tmpRowDataObjStr.length).indexOf("\""));
                let tmpOdoiporika: number = -1.0;
                if(tmpOdoiporikAStr!=null && tmpOdoiporikAStr!=undefined && tmpOdoiporikAStr.length>0)
                    tmpOdoiporika = parseFloat(tmpOdoiporikAStr.replaceAll(",", "."));

                //check if this ergazomenos is already included in the array            
                let idFound = false;
                for(let i=0; i<resErgazomenoiAnaMera.length; i++) {
                    if(resErgazomenoiAnaMera[i].id_ergazomenou == tmpIdErgazomenou) {
                        idFound = true;
                        resErgazomenoiAnaMera[i].wresAnaErgo[0].odoiporika = tmpOdoiporika;
                    }
                }
                if(idFound == false) {
                    let tmpErgazomenosAnaMera: ErgazomenosAnaMera = {
                        id: -1,
                        hmeromhnia: tmpHmeromhnia,
                        savvato: tmpIsSavvato,
                        kyriakh: tmpIsKyriakh,
                        id_ergazomenou: tmpIdErgazomenou,
                        adeia: ConstManager.getInstance().OXI_ADEIA,
                        wresAnaErgo: [
                            {
                                id_ergou: tmpIDErgou,
                                wres_ergasias: -1.0,
                                yperwries: -1.0,
                                dianyktereysh: false,
                                odoiporika: tmpOdoiporika,
                                id_kentrou_kostous: -1
                            }
                        ],
                        total_wres_ergasias: -1.0,
                        total_yperwries: -1.0,
                        total_dianyktereysh: false,
                        total_odoiporika: -1.0,
                        total_wres_ergasias_tooltip: "",
                        total_yperwries_tooltip: "",
                        total_dianyktereysh_tooltip: "",
                        total_odoiporika_tooltip: "",
                        total_kentra_kostous_tooltip: ""
                    };
                    resErgazomenoiAnaMera.push(tmpErgazomenosAnaMera);
                    //console.log("* 8 * Prosthesa neo: " + JSON.stringify(tmpErgazomenosAnaMera, null, 2));
                }

                tmpRowDataObjStr = this.replaceSubstring(tmpRowDataObjStr, thΕmployeeG_index, odoiporika_index+19, "done" + tmpCounter); //ayto to kanw gia na mhn ksanampei sto idio if toy while
            }
            tmpCounter++;
        }
        
        //console.log("UtilsManager -> getErgazomenosAnaMeraObjFromTableRowObject -> resErgazomenosAnaMera: " + JSON.stringify(resErgazomenoiAnaMera, null, 2));
        
        return resErgazomenoiAnaMera;
    }

    public replaceSubstring(str: string, startIndex: number, endIndex: number, replacement: string): string {
        //console.log("replaceSubstring PRIN: " + str);
        if (startIndex < 0 || endIndex > str.length || startIndex > endIndex) {
            throw new Error("Invalid start or end index");
        }
    
        const before = str.slice(0, startIndex);
        const after = str.slice(endIndex);
        const res = before + replacement + after;
    
        //console.log("replaceSubstring META: " + res);
        return res;
    }

    public getErgazomenoFromArray(allErgazomenoiErgou: Ergazomenos[], tmpIDErgazomenou: number): Ergazomenos {
        let resErgazomenos: Ergazomenos = {
            id: -1,
            onoma: "",
            epwnymo: "",
            thlefwno: "",
            id_kathgorias: -1,
            trapeza: "",
            iban: "",
            kostos_hmeromisthiou_kathimerinh: -1.0,
            kostos_hmeromisthiou_savvato: -1.0,
            kostos_hmeromisthiou_kyriakh: -1.0,
            kostos_yperwrias: -1.0,
            kostos_dianyktereyshs: -1.0,
            kostos_efka_kathimerinh: -1.0,
            kostos_efka_savvato: -1.0,
            kostos_odoiporikwn_ana_wra: -1.0
        };
        for(let i=0; i<allErgazomenoiErgou.length; i++) {
            if(allErgazomenoiErgou[i].id == tmpIDErgazomenou)
                resErgazomenos = allErgazomenoiErgou[i];
        }
        return resErgazomenos;
    }

    public getSynoloGiaErgazomeno(tmpIDSelectedErgou: number,
                                  allKentraKostous: KentroKostous[],
                                  allErga: Ergo[],
                                  allErgazomenoiErgou: Ergazomenos[], 
                                  allErgazomenoiAnaMeraGiaSelectedErgoAndMonth: ErgazomenosAnaMera[], 
                                  tmpIDSelectedErgazomenou: number, 
                                  tmpWhatToCalculate: number) : SynoloInfo {
        let resSynoloInfo: SynoloInfo = {
            totalWresKathimerini: 0.0,
            totalWresSavvato: 0.0,
            totalWresKyriakh: 0.0,
            totalYperwries: 0.0,
            totalDianyktereyseis: 0,
            totalWresOdoiporikwn: 0,
            totalPoso: 0.0,
            totalEfkaKathimerini: 0.0,
            totalEfkaSavvato: 0.0,
            totalEfkaSynolo: 0.0,
            synolaParousiasKathimerinesSeMeres: 0.0,
            synolaParousiasSavvataSeMeres: 0.0,
            synolaParousiasKyriakesSeMeres: 0.0,
            synolaParousiasYperwriesSeWres: 0.0,
            synolaParousiasDianyktereyseisSeMeres: 0.0,
            synolaParousiasOdoiporikaSeWres: 0.0,
            explanation: "",
            synolaAnaKentroKostous: [],
            synolaAnaErgo: []
        };
        for(let i=0; i<allErgazomenoiAnaMeraGiaSelectedErgoAndMonth.length; i++) {
            const tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth: ErgazomenosAnaMera = allErgazomenoiAnaMeraGiaSelectedErgoAndMonth[i];
            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.id_ergazomenou == tmpIDSelectedErgazomenou) {
                //wres ergasias kathimerini
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_KATHIMERINES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_KATHIMERINES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SYNOLO
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_KATHIMERINES_SE_MERES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY) {
                    if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==false && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==false) {
                        resSynoloInfo.totalWresKathimerini = resSynoloInfo.totalWresKathimerini + tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_wres_ergasias;
                    }
                }
                //wres ergasias Savvato
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SAVVATA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SAVVATA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SYNOLO
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_SAVVATA_SE_MERES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY) {
                    if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==true && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==false) {
                        resSynoloInfo.totalWresSavvato = resSynoloInfo.totalWresSavvato + tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_wres_ergasias;
                    }
                }
                //wres ergasias Kyriakh
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_KYRIAKES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_KYRIAKES_SE_MERES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY) {
                    if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==false && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==true) {
                        resSynoloInfo.totalWresKyriakh = resSynoloInfo.totalWresKyriakh + tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_wres_ergasias;
                    }
                }
                //yperwries
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_YPERWRIES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_YPERWRIES_SE_WRES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY) {
                    if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_yperwries != -1.0)
                        resSynoloInfo.totalYperwries = resSynoloInfo.totalYperwries + tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_yperwries;
                }
                //dianyktereyseis
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_DIANYKTEREYSEIS
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_DIANYKTEREYSEIS_SE_MERES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY) {
                    if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_dianyktereysh == true) {
                        resSynoloInfo.totalDianyktereyseis = resSynoloInfo.totalDianyktereyseis + 1;
                    }
                }
                //odoiporika
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_ODOIPORIKA
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_ODOIPORIKA_SE_WRES
                    || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY) {
                    if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_odoiporika != -1.0) {
                        resSynoloInfo.totalWresOdoiporikwn = resSynoloInfo.totalWresOdoiporikwn + tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_odoiporika;
                    }
                }
                //ana kentro kostous
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_KENTRO_KOSTOYS) {                    
                    for(let k=0; k<tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.wresAnaErgo.length; k++) { //gia kathe WresAnaErgo
                        const tmpWresAnaErgo: WresAnaErgo = tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.wresAnaErgo[k];
                        if(tmpWresAnaErgo.id_kentrou_kostous!=-1 &&
                            (tmpWresAnaErgo.id_ergou==tmpIDSelectedErgou || tmpIDSelectedErgou==ConstManager.getInstance().OLA_TA_ERGA) ){
                            //edw tha apothikeysw to synolo ana kentro kostoys
                            let tmpSynoloInfoKentraKostous: SynoloInfoKentraKostous = {
                                kentroKostousID: -1,
                                kentroKostousDesc: "",

                                totalWresKathimerini: 0.0,
                                totalWresSavvato: 0.0,
                                totalWresKyriakh: 0.0,
                                totalYperwries: 0.0,
                                totalDianyktereyseis: 0.0,
                                totalWresOdoiporikwn: 0.0,
                                
                                totalPoso: 0.0,
                                explanation: ""
                            };

                            tmpSynoloInfoKentraKostous.kentroKostousID = tmpWresAnaErgo.id_kentrou_kostous;
                            //get perigrafh kentrou kostous
                            for(let tmpKentraCnt=0; tmpKentraCnt<allKentraKostous.length; tmpKentraCnt++) {
                                const tempKentroKostous: KentroKostous = allKentraKostous[tmpKentraCnt];
                                if(tempKentroKostous.id == tmpSynoloInfoKentraKostous.kentroKostousID)
                                    tmpSynoloInfoKentraKostous.kentroKostousDesc = tempKentroKostous.kwdikos + " (" + tempKentroKostous.perigrafh + ")";
                            }

                            //search if there is already added an entry for this kentro kostous
                            let tmpExistingItemIndex = -1;
                            for(let tmpCnt=0; tmpCnt<resSynoloInfo.synolaAnaKentroKostous.length; tmpCnt++) {
                                if(tmpWresAnaErgo.id_kentrou_kostous == resSynoloInfo.synolaAnaKentroKostous[tmpCnt].kentroKostousID)
                                    tmpExistingItemIndex = tmpCnt;
                            }
                            if(tmpExistingItemIndex != -1)
                                tmpSynoloInfoKentraKostous = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex];
                            //NOW SET ITS PROPERTIES!
                            //wres ergasias kathimerini                        
                            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==false && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==false) {
                                tmpSynoloInfoKentraKostous.totalWresKathimerini = tmpSynoloInfoKentraKostous.totalWresKathimerini + tmpWresAnaErgo.wres_ergasias;
                            }
                            //wres ergasias Savvato
                            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==true && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==false) {
                                tmpSynoloInfoKentraKostous.totalWresSavvato = tmpSynoloInfoKentraKostous.totalWresSavvato + tmpWresAnaErgo.wres_ergasias;
                            }
                            //wres ergasias Kyriakh
                            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==false && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==true) {
                                tmpSynoloInfoKentraKostous.totalWresKyriakh = tmpSynoloInfoKentraKostous.totalWresKyriakh + tmpWresAnaErgo.wres_ergasias;
                            }
                            //yperwries
                            if(tmpWresAnaErgo.yperwries != -1.0)
                                tmpSynoloInfoKentraKostous.totalYperwries = tmpSynoloInfoKentraKostous.totalYperwries + tmpWresAnaErgo.yperwries;
                            //dianyktereyseis
                            if(tmpWresAnaErgo.dianyktereysh == true) {
                                tmpSynoloInfoKentraKostous.totalDianyktereyseis = tmpSynoloInfoKentraKostous.totalDianyktereyseis + 1;
                            }
                            //odoiporika
                            if(tmpWresAnaErgo.odoiporika != -1.0) {
                                tmpSynoloInfoKentraKostous.totalWresOdoiporikwn = tmpSynoloInfoKentraKostous.totalWresOdoiporikwn + tmpWresAnaErgo.odoiporika;
                            }

                            //if(tmpExistingItemIndex != -1) epeidh einai by reference de xreiazetai na kanw kati -> to arxiko item exei hdh tropopoihthei
                            if(tmpExistingItemIndex == -1)
                                resSynoloInfo.synolaAnaKentroKostous.push(tmpSynoloInfoKentraKostous);
                        }
                    }
                }
                //ana ergo
                if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_ERGO) {                    
                    for(let k=0; k<tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.wresAnaErgo.length; k++) { //gia kathe WresAnaErgo
                        const tmpWresAnaErgo: WresAnaErgo = tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.wresAnaErgo[k];
                        if(tmpWresAnaErgo.id_ergou==tmpIDSelectedErgou || tmpIDSelectedErgou==ConstManager.getInstance().OLA_TA_ERGA){
                            //edw tha apothikeysw to synolo ana kentro kostoys
                            let tmpSynoloInfoErgo: SynoloInfoErgo = {
                                ergoID: -1,
                                ergoOnoma: "",
                                ergoDesc: "",

                                totalWresKathimerini: 0.0,
                                totalWresSavvato: 0.0,
                                totalWresKyriakh: 0.0,
                                totalYperwries: 0.0,
                                totalDianyktereyseis: 0.0,
                                totalWresOdoiporikwn: 0.0,
                                
                                totalPoso: 0.0,
                                explanation: ""
                            };

                            tmpSynoloInfoErgo.ergoID = tmpWresAnaErgo.id_ergou;
                            //get perigrafh ergou
                            for(let tmpErgaCnt=0; tmpErgaCnt<allErga.length; tmpErgaCnt++) {
                                const tempErgo: Ergo = allErga[tmpErgaCnt];
                                if(tempErgo.id == tmpSynoloInfoErgo.ergoID)
                                    tmpSynoloInfoErgo.ergoDesc = tempErgo.onoma + " (" + tempErgo.kwdikos + ")";
                            }

                            //search if there is already added an entry for this ergo
                            let tmpExistingItemIndex = -1;
                            for(let tmpCnt=0; tmpCnt<resSynoloInfo.synolaAnaErgo.length; tmpCnt++) {
                                if(tmpWresAnaErgo.id_ergou == resSynoloInfo.synolaAnaErgo[tmpCnt].ergoID)
                                    tmpExistingItemIndex = tmpCnt;
                            }
                            if(tmpExistingItemIndex != -1)
                                tmpSynoloInfoErgo = resSynoloInfo.synolaAnaErgo[tmpExistingItemIndex];
                            //NOW SET ITS PROPERTIES!
                            //wres ergasias kathimerini                        
                            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==false && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==false) {
                                tmpSynoloInfoErgo.totalWresKathimerini = tmpSynoloInfoErgo.totalWresKathimerini + tmpWresAnaErgo.wres_ergasias;
                            }
                            //wres ergasias Savvato
                            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==true && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==false) {
                                tmpSynoloInfoErgo.totalWresSavvato = tmpSynoloInfoErgo.totalWresSavvato + tmpWresAnaErgo.wres_ergasias;
                            }
                            //wres ergasias Kyriakh
                            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.savvato==false && tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.kyriakh==true) {
                                tmpSynoloInfoErgo.totalWresKyriakh = tmpSynoloInfoErgo.totalWresKyriakh + tmpWresAnaErgo.wres_ergasias;
                            }
                            //yperwries
                            if(tmpWresAnaErgo.yperwries != -1.0)
                                tmpSynoloInfoErgo.totalYperwries = tmpSynoloInfoErgo.totalYperwries + tmpWresAnaErgo.yperwries;
                            //dianyktereyseis
                            if(tmpWresAnaErgo.dianyktereysh == true) {
                                tmpSynoloInfoErgo.totalDianyktereyseis = tmpSynoloInfoErgo.totalDianyktereyseis + 1;
                            }
                            //odoiporika
                            if(tmpWresAnaErgo.odoiporika != -1.0) {
                                tmpSynoloInfoErgo.totalWresOdoiporikwn = tmpSynoloInfoErgo.totalWresOdoiporikwn + tmpWresAnaErgo.odoiporika;
                            }

                            //if(tmpExistingItemIndex != -1) epeidh einai by reference de xreiazetai na kanw kati -> to arxiko item exei hdh tropopoihthei
                            if(tmpExistingItemIndex == -1)
                                resSynoloInfo.synolaAnaErgo.push(tmpSynoloInfoErgo);
                        }
                    }
                }
            }
        }

        const tmpErgazomenos: Ergazomenos = this.getErgazomenoFromArray(allErgazomenoiErgou, tmpIDSelectedErgazomenou);

        if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_KATHIMERINES) {
            resSynoloInfo.totalPoso = parseFloat(((resSynoloInfo.totalWresKathimerini/8) * tmpErgazomenos.kostos_hmeromisthiou_kathimerinh).toFixed(2));
            if(resSynoloInfo.totalPoso > 0)
                resSynoloInfo.explanation = "(" + resSynoloInfo.totalWresKathimerini.toString() + "/8) * " + tmpErgazomenos.kostos_hmeromisthiou_kathimerinh.toString() + " = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SAVVATA) {
            resSynoloInfo.totalPoso = (resSynoloInfo.totalWresSavvato/8) * tmpErgazomenos.kostos_hmeromisthiou_savvato;
            if(resSynoloInfo.totalPoso > 0)
                resSynoloInfo.explanation = "(" + resSynoloInfo.totalWresSavvato.toString() + "/8) * " + tmpErgazomenos.kostos_hmeromisthiou_savvato.toString() + " = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_KYRIAKES) {
            resSynoloInfo.totalPoso = (resSynoloInfo.totalWresKyriakh/8) * tmpErgazomenos.kostos_hmeromisthiou_kyriakh;
            if(resSynoloInfo.totalPoso > 0)
                resSynoloInfo.explanation = "(" + resSynoloInfo.totalWresKyriakh.toString() + "/8) * " + tmpErgazomenos.kostos_hmeromisthiou_kyriakh.toString() + " = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_YPERWRIES) {
            resSynoloInfo.totalPoso = resSynoloInfo.totalYperwries * tmpErgazomenos.kostos_yperwrias;
            if(resSynoloInfo.totalPoso > 0)
                resSynoloInfo.explanation = resSynoloInfo.totalYperwries.toString() + " * " + tmpErgazomenos.kostos_yperwrias.toString() + " = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_DIANYKTEREYSEIS) {
            resSynoloInfo.totalPoso = resSynoloInfo.totalDianyktereyseis * tmpErgazomenos.kostos_dianyktereyshs;
            if(resSynoloInfo.totalPoso > 0)
                resSynoloInfo.explanation = resSynoloInfo.totalDianyktereyseis.toString() + " * " + tmpErgazomenos.kostos_dianyktereyshs.toString() + " = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_ODOIPORIKA) {
            resSynoloInfo.totalPoso = resSynoloInfo.totalWresOdoiporikwn * tmpErgazomenos.kostos_odoiporikwn_ana_wra;
            if(resSynoloInfo.totalPoso > 0)
                resSynoloInfo.explanation = resSynoloInfo.totalWresOdoiporikwn.toString() + " * " + tmpErgazomenos.kostos_odoiporikwn_ana_wra.toString() + " = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_KATHIMERINES) {
            resSynoloInfo.totalEfkaKathimerini = parseFloat(((resSynoloInfo.totalWresKathimerini/8) * tmpErgazomenos.kostos_efka_kathimerinh).toFixed(2));
            if(resSynoloInfo.totalEfkaKathimerini > 0)
                resSynoloInfo.explanation = "(" + resSynoloInfo.totalWresKathimerini.toString() + "/8) * " + tmpErgazomenos.kostos_efka_kathimerinh.toString() + " = " + resSynoloInfo.totalEfkaKathimerini.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SAVVATA) {
            resSynoloInfo.totalEfkaSavvato = parseFloat(((resSynoloInfo.totalWresSavvato/8) * tmpErgazomenos.kostos_efka_savvato).toFixed(2));
            if(resSynoloInfo.totalEfkaSavvato > 0)
                resSynoloInfo.explanation = "(" + resSynoloInfo.totalWresSavvato.toString() + "/8) * " + tmpErgazomenos.kostos_efka_savvato.toString() + " = " + resSynoloInfo.totalEfkaSavvato.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SYNOLO) {
            resSynoloInfo.totalEfkaKathimerini = parseFloat(((resSynoloInfo.totalWresKathimerini/8) * tmpErgazomenos.kostos_efka_kathimerinh).toFixed(2));
            resSynoloInfo.totalEfkaSavvato = parseFloat(((resSynoloInfo.totalWresSavvato/8) * tmpErgazomenos.kostos_efka_savvato).toFixed(2));
            resSynoloInfo.totalEfkaSynolo = parseFloat((resSynoloInfo.totalEfkaKathimerini + resSynoloInfo.totalEfkaSavvato).toFixed(2));
            if(resSynoloInfo.totalEfkaSynolo > 0)
                resSynoloInfo.explanation = resSynoloInfo.totalEfkaKathimerini.toFixed(2) + " + " + resSynoloInfo.totalEfkaSavvato.toFixed(2) + " = " + resSynoloInfo.totalEfkaSynolo.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA) {
            const tmpPoso_kathimerines = (resSynoloInfo.totalWresKathimerini/8) * tmpErgazomenos.kostos_hmeromisthiou_kathimerinh;
            const tmpPoso_savvata = (resSynoloInfo.totalWresSavvato/8) * tmpErgazomenos.kostos_hmeromisthiou_savvato;
            const tmpPoso_kyriakes = (resSynoloInfo.totalWresKyriakh/8) * tmpErgazomenos.kostos_hmeromisthiou_kyriakh;
            const tmpPoso_yperwries = resSynoloInfo.totalYperwries * tmpErgazomenos.kostos_yperwrias;
            const tmpPoso_dianyktereyseis = resSynoloInfo.totalDianyktereyseis * tmpErgazomenos.kostos_dianyktereyshs;
            const tmpPoso_odoiporika = resSynoloInfo.totalWresOdoiporikwn * tmpErgazomenos.kostos_odoiporikwn_ana_wra;
            
            //if(tmpErgazomenos.id_kathgorias != 1) { //ID:1 einai MISTHWTOS
                resSynoloInfo.totalPoso = tmpPoso_kathimerines + tmpPoso_savvata + tmpPoso_kyriakes + tmpPoso_yperwries + tmpPoso_dianyktereyseis + tmpPoso_odoiporika;
                if(resSynoloInfo.totalPoso > 0)
                    resSynoloInfo.explanation = "(" + tmpPoso_kathimerines + " + " + tmpPoso_savvata + " + " + tmpPoso_kyriakes + " + " + tmpPoso_yperwries + " + " + tmpPoso_dianyktereyseis + " + " + tmpPoso_odoiporika + ") = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
            //}
            //else { //gia MISTHWTOYS mhn ypologizeis kostos gia kathimerines
            //    resSynoloInfo.totalPoso = tmpPoso_savvata + tmpPoso_kyriakes + tmpPoso_yperwries + tmpPoso_dianyktereyseis + tmpPoso_odoiporika;
            //    if(resSynoloInfo.totalPoso > 0)
            //        resSynoloInfo.explanation = "(" + tmpPoso_savvata + " + " + tmpPoso_kyriakes + " + " + tmpPoso_yperwries + " + " + tmpPoso_dianyktereyseis + " + " + tmpPoso_odoiporika + ") = " + resSynoloInfo.totalPoso.toString() + " €";
            //}
        }
        //synola parousias
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_KATHIMERINES_SE_MERES) {
            resSynoloInfo.synolaParousiasKathimerinesSeMeres = resSynoloInfo.totalWresKathimerini/8;
            if(resSynoloInfo.synolaParousiasKathimerinesSeMeres > 0)
                resSynoloInfo.explanation = resSynoloInfo.totalWresKathimerini + "/8 = " + resSynoloInfo.synolaParousiasKathimerinesSeMeres.toFixed(2) + " ημέρες";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_SAVVATA_SE_MERES) {
            resSynoloInfo.synolaParousiasSavvataSeMeres = resSynoloInfo.totalWresSavvato/8;
            if(resSynoloInfo.synolaParousiasSavvataSeMeres > 0)
                resSynoloInfo.explanation = resSynoloInfo.totalWresSavvato + "/8 = " + resSynoloInfo.synolaParousiasSavvataSeMeres.toFixed(2) + " ημέρες";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_KYRIAKES_SE_MERES) {
            resSynoloInfo.synolaParousiasKyriakesSeMeres = resSynoloInfo.totalWresKyriakh/8;
            if(resSynoloInfo.synolaParousiasKyriakesSeMeres > 0)
                resSynoloInfo.explanation = resSynoloInfo.totalWresKyriakh + "/8 = " + resSynoloInfo.synolaParousiasKyriakesSeMeres.toFixed(2) + " ημέρες";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_YPERWRIES_SE_WRES) {
            resSynoloInfo.synolaParousiasYperwriesSeWres = resSynoloInfo.totalYperwries;
            if(resSynoloInfo.synolaParousiasYperwriesSeWres > 0)
                resSynoloInfo.explanation = resSynoloInfo.synolaParousiasYperwriesSeWres.toFixed(2) + " ώρες";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_DIANYKTEREYSEIS_SE_MERES) {
            resSynoloInfo.synolaParousiasDianyktereyseisSeMeres = resSynoloInfo.totalDianyktereyseis;
            if(resSynoloInfo.synolaParousiasDianyktereyseisSeMeres > 0)
                resSynoloInfo.explanation = resSynoloInfo.synolaParousiasDianyktereyseisSeMeres.toFixed(2) + " ημέρες";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_SYNOLA_PAROUSIAS_ERGAZOMENOY_ODOIPORIKA_SE_WRES) {
            resSynoloInfo.synolaParousiasOdoiporikaSeWres = resSynoloInfo.totalWresOdoiporikwn;
            if(resSynoloInfo.synolaParousiasOdoiporikaSeWres > 0)
                resSynoloInfo.explanation = resSynoloInfo.synolaParousiasOdoiporikaSeWres.toFixed(2) + " ώρες";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY) {
            //metrhta
            const tmpPoso_kathimerines = (resSynoloInfo.totalWresKathimerini/8) * tmpErgazomenos.kostos_hmeromisthiou_kathimerinh;
            const tmpPoso_savvata = (resSynoloInfo.totalWresSavvato/8) * tmpErgazomenos.kostos_hmeromisthiou_savvato;
            const tmpPoso_kyriakes = (resSynoloInfo.totalWresKyriakh/8) * tmpErgazomenos.kostos_hmeromisthiou_kyriakh;
            const tmpPoso_yperwries = resSynoloInfo.totalYperwries * tmpErgazomenos.kostos_yperwrias;
            const tmpPoso_dianyktereyseis = resSynoloInfo.totalDianyktereyseis * tmpErgazomenos.kostos_dianyktereyshs;
            const tmpPoso_odoiporika = resSynoloInfo.totalWresOdoiporikwn * tmpErgazomenos.kostos_odoiporikwn_ana_wra;
            
            const tmpMetrhta: number = tmpPoso_kathimerines + tmpPoso_savvata + tmpPoso_kyriakes + tmpPoso_yperwries + tmpPoso_dianyktereyseis + tmpPoso_odoiporika;
            
            //efka
            resSynoloInfo.totalEfkaKathimerini = parseFloat(((resSynoloInfo.totalWresKathimerini/8) * tmpErgazomenos.kostos_efka_kathimerinh).toFixed(2));
            resSynoloInfo.totalEfkaSavvato = parseFloat(((resSynoloInfo.totalWresSavvato/8) * tmpErgazomenos.kostos_efka_savvato).toFixed(2));
            resSynoloInfo.totalEfkaSynolo = parseFloat((resSynoloInfo.totalEfkaKathimerini + resSynoloInfo.totalEfkaSavvato).toFixed(2));
            
            //synolo
            resSynoloInfo.totalPoso = tmpMetrhta + resSynoloInfo.totalEfkaSynolo;
            if(resSynoloInfo.totalPoso > 0)
                resSynoloInfo.explanation = tmpMetrhta.toString() + " + " + resSynoloInfo.totalEfkaSynolo.toString() + " = " + resSynoloInfo.totalPoso.toFixed(2) + " €";
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_KENTRO_KOSTOYS) {
            for(let tempCounter=0; tempCounter<resSynoloInfo.synolaAnaKentroKostous.length; tempCounter++) {
                let tempSynoloInfoKentraKostous: SynoloInfoKentraKostous = resSynoloInfo.synolaAnaKentroKostous[tempCounter];

                //metrhta
                const tmpPosoKentrouKostous_kathimerines = (tempSynoloInfoKentraKostous.totalWresKathimerini/8) * tmpErgazomenos.kostos_hmeromisthiou_kathimerinh;
                const tmpPosoKentrouKostous_savvata = (tempSynoloInfoKentraKostous.totalWresSavvato/8) * tmpErgazomenos.kostos_hmeromisthiou_savvato;
                const tmpPosoKentrouKostous_kyriakes = (tempSynoloInfoKentraKostous.totalWresKyriakh/8) * tmpErgazomenos.kostos_hmeromisthiou_kyriakh;
                const tmpPosoKentrouKostous_yperwries = tempSynoloInfoKentraKostous.totalYperwries * tmpErgazomenos.kostos_yperwrias;
                const tmpPosoKentrouKostous_dianyktereyseis = tempSynoloInfoKentraKostous.totalDianyktereyseis * tmpErgazomenos.kostos_dianyktereyshs;
                const tmpPosoKentrouKostous_odoiporika = tempSynoloInfoKentraKostous.totalWresOdoiporikwn * tmpErgazomenos.kostos_odoiporikwn_ana_wra;

                tempSynoloInfoKentraKostous.totalPoso = tmpPosoKentrouKostous_kathimerines + tmpPosoKentrouKostous_savvata + tmpPosoKentrouKostous_kyriakes + tmpPosoKentrouKostous_yperwries + tmpPosoKentrouKostous_dianyktereyseis + tmpPosoKentrouKostous_odoiporika;
                resSynoloInfo.totalPoso = resSynoloInfo.totalPoso + tempSynoloInfoKentraKostous.totalPoso;

                if(tempSynoloInfoKentraKostous.totalPoso > 0)
                    tempSynoloInfoKentraKostous.explanation = tempSynoloInfoKentraKostous.totalPoso.toFixed(2) + " €";
            }           
        }
        else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_ERGO) {
            for(let tempCounter=0; tempCounter<resSynoloInfo.synolaAnaErgo.length; tempCounter++) {
                let tempSynoloInfoErgo: SynoloInfoErgo = resSynoloInfo.synolaAnaErgo[tempCounter];

                //metrhta
                const tmpPosoKentrouKostous_kathimerines = (tempSynoloInfoErgo.totalWresKathimerini/8) * tmpErgazomenos.kostos_hmeromisthiou_kathimerinh;
                const tmpPosoKentrouKostous_savvata = (tempSynoloInfoErgo.totalWresSavvato/8) * tmpErgazomenos.kostos_hmeromisthiou_savvato;
                const tmpPosoKentrouKostous_kyriakes = (tempSynoloInfoErgo.totalWresKyriakh/8) * tmpErgazomenos.kostos_hmeromisthiou_kyriakh;
                const tmpPosoKentrouKostous_yperwries = tempSynoloInfoErgo.totalYperwries * tmpErgazomenos.kostos_yperwrias;
                const tmpPosoKentrouKostous_dianyktereyseis = tempSynoloInfoErgo.totalDianyktereyseis * tmpErgazomenos.kostos_dianyktereyshs;
                const tmpPosoKentrouKostous_odoiporika = tempSynoloInfoErgo.totalWresOdoiporikwn * tmpErgazomenos.kostos_odoiporikwn_ana_wra;

                tempSynoloInfoErgo.totalPoso = tmpPosoKentrouKostous_kathimerines + tmpPosoKentrouKostous_savvata + tmpPosoKentrouKostous_kyriakes + tmpPosoKentrouKostous_yperwries + tmpPosoKentrouKostous_dianyktereyseis + tmpPosoKentrouKostous_odoiporika;
                resSynoloInfo.totalPoso = resSynoloInfo.totalPoso + tempSynoloInfoErgo.totalPoso;

                if(tempSynoloInfoErgo.totalPoso > 0)
                    tempSynoloInfoErgo.explanation = tempSynoloInfoErgo.totalPoso.toFixed(2) + " €";
            }           
        }
        return resSynoloInfo;
    }

    public getSynoloGiaErgo(tmpIDSelectedErgou: number, allKentraKostous: KentroKostous[], allErga: Ergo[], allErgazomenoiErgou: Ergazomenos[], allErgazomenoiAnaMeraGiaSelectedErgoAndMonth: ErgazomenosAnaMera[], tmpWhatToCalculate: number) : SynoloInfo {
        let resSynoloInfo: SynoloInfo = {
            totalWresKathimerini: 0.0,
            totalWresSavvato: 0.0,
            totalWresKyriakh: 0.0,
            totalYperwries: 0.0,
            totalDianyktereyseis: 0,
            totalWresOdoiporikwn: 0,
            totalPoso: 0.0,
            totalEfkaKathimerini: 0.0,
            totalEfkaSavvato: 0.0,
            totalEfkaSynolo: 0.0,
            synolaParousiasKathimerinesSeMeres: 0.0,
            synolaParousiasSavvataSeMeres: 0.0,
            synolaParousiasKyriakesSeMeres: 0.0,
            synolaParousiasYperwriesSeWres: 0.0,
            synolaParousiasDianyktereyseisSeMeres: 0.0,
            synolaParousiasOdoiporikaSeWres: 0.0,
            explanation: "",
            synolaAnaKentroKostous: [],
            synolaAnaErgo: []
        };
        for(let i=0; i<allErgazomenoiErgou.length; i++) {
            const tmpErgazomenos: Ergazomenos = this.getErgazomenoFromArray(allErgazomenoiErgou, allErgazomenoiErgou[i].id);
            const tmpSynoloInfoErgazomenou : SynoloInfo = this.getSynoloGiaErgazomeno(tmpIDSelectedErgou, allKentraKostous, allErga, allErgazomenoiErgou, allErgazomenoiAnaMeraGiaSelectedErgoAndMonth, tmpErgazomenos.id, tmpWhatToCalculate);
            if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_ERGAZOMENOY_SYNOLO_METRHTA
                || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_KENTRO_KOSTOYS
                || tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIA_KOSTOLOGHSH_ERGAZOMENOY_ANA_ERGO) {
                resSynoloInfo.totalPoso = resSynoloInfo.totalPoso + tmpSynoloInfoErgazomenou.totalPoso;
                resSynoloInfo.explanation = resSynoloInfo.totalPoso.toString() + " €";
                for(let j=0; j<tmpSynoloInfoErgazomenou.synolaAnaKentroKostous.length; j++) {
                    //des an yparxei hdh eggrafh gia ayto to kentro kostoys (apo allo ergazomeno)
                    let tmpExistingItemIndex = -1;
                    for(let tempCounter=0; tempCounter<resSynoloInfo.synolaAnaKentroKostous.length; tempCounter++) {
                        if(resSynoloInfo.synolaAnaKentroKostous[tempCounter].kentroKostousID == tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].kentroKostousID)
                            tmpExistingItemIndex = tempCounter;
                    }
                    if(tmpExistingItemIndex == -1)
                        resSynoloInfo.synolaAnaKentroKostous.push(tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j]);
                    else { //update existing item
                        resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresKathimerini = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresKathimerini + tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].totalWresKathimerini;
                        resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresSavvato = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresSavvato + tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].totalWresSavvato;
                        resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresKyriakh = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresKyriakh + tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].totalWresKyriakh;
                        resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalYperwries = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalYperwries + tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].totalYperwries;
                        resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalDianyktereyseis = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalDianyktereyseis + tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].totalDianyktereyseis;
                        resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresOdoiporikwn = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalWresOdoiporikwn + tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].totalWresOdoiporikwn;
                        
                        resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalPoso = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalPoso + tmpSynoloInfoErgazomenou.synolaAnaKentroKostous[j].totalPoso;
                        if(resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalPoso > 0)
                            resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].explanation = resSynoloInfo.synolaAnaKentroKostous[tmpExistingItemIndex].totalPoso.toFixed(2) + " €";
                    }
                }
                for(let j=0; j<tmpSynoloInfoErgazomenou.synolaAnaErgo.length; j++) {
                    resSynoloInfo.synolaAnaErgo.push(tmpSynoloInfoErgazomenou.synolaAnaErgo[j]);
                }
            }
            else if(tmpWhatToCalculate == ConstManager.getInstance().YPOLOGISE_MHNIAIO_KOSTOS_EFKA_ERGAZOMENOY_SYNOLO) {
                resSynoloInfo.totalPoso = resSynoloInfo.totalPoso + tmpSynoloInfoErgazomenou.totalEfkaSynolo;
                resSynoloInfo.explanation = resSynoloInfo.totalPoso.toFixed(2).toString() + " €";
            }            
        }

        if(resSynoloInfo.totalPoso > 0)
            resSynoloInfo.explanation = resSynoloInfo.totalPoso.toFixed(2) + " €";
        return resSynoloInfo;
    }

    public getParousiesMhnaGiaErgazomeno(allErgazomenoiAnaMeraGiaSelectedErgoAndMonth: ErgazomenosAnaMera[], tmpIDSelectedErgazomenou: number) : number {
        let resParousies: number = 0;

        let hmeromhniesPouExounHdhYpologistei: Array<string> = [];

        for(let i=0; i<allErgazomenoiAnaMeraGiaSelectedErgoAndMonth.length; i++) {
            const tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth: ErgazomenosAnaMera = allErgazomenoiAnaMeraGiaSelectedErgoAndMonth[i];
            if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.id_ergazomenou == tmpIDSelectedErgazomenou) {
                if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.adeia == ConstManager.getInstance().OXI_ADEIA) {
                    let tmpHmeromhniaExeiHdhYpologistei: boolean = false;
                    for(let j=0; j<hmeromhniesPouExounHdhYpologistei.length; j++) {
                        if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.hmeromhnia == hmeromhniesPouExounHdhYpologistei[j])
                            tmpHmeromhniaExeiHdhYpologistei = true;
                    }
                    if(tmpHmeromhniaExeiHdhYpologistei == false) {
                        if(tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_wres_ergasias > 0
                            || tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_yperwries > 0
                            || tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_dianyktereysh == true
                            || tmpErgazomenosAnaMeraGiaSelectedErgoAndMonth.total_odoiporika > 0
                        ) {
                            resParousies = resParousies + 1;
                        }
                    }
                }
            }
        }

        return resParousies;
    }

    public exportToExcel(excelData, fileName) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        (async () => {
            const ws = XLSX.utils.json_to_sheet(excelData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

            /*ws["A1"].s = {	// set the style for target cell
                font: {
                  name: '宋体',
                  sz: 24,
                  bold: true,
                  color: { rgb: "FFFFAA00" }
                },
              };*/

            // Define the style to be applied conditionally
            const headerStyle = {
                font: {
                    name: 'Arial',
                    sz: 14,
                    bold: true,
                    color: { rgb: "FFFFFF" }
                },
                fill: {
                    fgColor: { rgb: "0036FF" }
                }
            };

            // Iterate over each cell in the worksheet to apply the conditional style
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let R = range.s.r; R <= range.e.r; ++R) {
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    const cell_address = { c: C, r: R };
                    const cell_ref = XLSX.utils.encode_cell(cell_address);
                    const cell = ws[cell_ref];
                    if (cell && (cell.v === "Ημερομηνία") || cell.v === "Εργαζόμενος") {
                        cell.s = headerStyle;
                    }
                }
            }

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        })();
    }

    public getKwdikoKaiPerigrafhKentrouKostousFromID(tmpAllKentraKostous: Array<KentroKostous>, tmpKentroKostousID: number, forPDF: boolean) : string {
        let resPerigrafh: string = "";
        for(let i=0; i<tmpAllKentraKostous.length; i++) {
            if(tmpKentroKostousID == tmpAllKentraKostous[i].id) {
                if(forPDF == false)
                    resPerigrafh = tmpAllKentraKostous[i].kwdikos + " (" + tmpAllKentraKostous[i].perigrafh + ") ID:" + tmpAllKentraKostous[i].id;
                else
                    resPerigrafh = tmpAllKentraKostous[i].kwdikos + " (" + tmpAllKentraKostous[i].perigrafh + ")";
            }
        }
        return resPerigrafh;
    }

    public generatePDF(allDaysOfSelectedMonth: Array<DayInfo>) {
        // Create a jsPDF instance with landscape orientation
        const doc = new jsPDF({
            orientation: 'landscape',
        });

        let tableData = [
            ['Ημερομηνία']
        ];
        for(let i=0; i<allDaysOfSelectedMonth.length; i++) {
            const tmpDayInfo: DayInfo = allDaysOfSelectedMonth[i];
            tableData.push([tmpDayInfo.day_id + "/" + tmpDayInfo.month_id + "/" + tmpDayInfo.year +  " " + tmpDayInfo.day_name])
        }
            

        // Table data
        /*const tableData = [
            ['Cell 1', 'Cell 2', 'Cell 3'],
            ['Style 1', 'Style 2', 'Style 3'],
            ['Content 1', 'Content 2', 'Content 3'],
        ];*/

        // Calculate dimensions and positions for the table cells
        const startX = 10;
        const startY = 10;
        const cellWidth = 50;
        const cellHeight = 20;

        // Iterate through each row and column to create the table
        for (let row = 0; row < tableData.length; row++) {
            for (let col = 0; col < tableData[row].length; col++) {
            const text = tableData[row][col];
            const xPos = startX + col * cellWidth;
            const yPos = startY + row * cellHeight;

            // Apply cell style if defined
            /*const style = cellStyles[(row * 3) + (col + 1)];
            if (style) {
                doc.setFillColor(style.fillColor);
                doc.rect(xPos, yPos, cellWidth, cellHeight, 'F');
            }*/

            // Print content
            doc.text(text, xPos + 5, yPos + 10);
            }
        }

        // Save the PDF
        doc.save('landscape_table.pdf');
    }

    public getMaxStrLengthOfAllTheContentsOfTableRow(tmpErgazomenosAnaMera: ErgazomenosAnaMera) : number {
        let res: number = 0;
        if(tmpErgazomenosAnaMera.total_kentra_kostous_tooltip.replaceAll(" + ", "\n").length > res)
            res = tmpErgazomenosAnaMera.total_kentra_kostous_tooltip.length;
        if(tmpErgazomenosAnaMera.total_wres_ergasias_tooltip.replaceAll("ΥΠΕΡΒΑΣΗ: ", "").replaceAll(" + ", "\n").length > res)
            res = tmpErgazomenosAnaMera.total_wres_ergasias_tooltip.length;
        if(tmpErgazomenosAnaMera.total_yperwries_tooltip.length > res)
            res = tmpErgazomenosAnaMera.total_yperwries_tooltip.length;
        if(tmpErgazomenosAnaMera.total_dianyktereysh_tooltip.length > res)
            res = tmpErgazomenosAnaMera.total_dianyktereysh_tooltip.length;
        if(tmpErgazomenosAnaMera.total_odoiporika_tooltip.length > res)
            res = tmpErgazomenosAnaMera.total_odoiporika_tooltip.length;
        return res;
    }   
    
    public getTableCellHeight(tmpErgazomenosAnaMera: ErgazomenosAnaMera) : number {
        let res: number = 0;
        const maxCellStrLength = this.getMaxStrLengthOfAllTheContentsOfTableRow(tmpErgazomenosAnaMera);
        //gia font: 8 
            //kathe cell xwraei peripou 22 xarakthres
            //kathe grammh exei height peripou 5
        let numberOfLinesNeeded = Math.ceil(maxCellStrLength/22); //Math.ceil kanei stroggylopoihsh pros ta panw px 1.1 -> 2
        if(numberOfLinesNeeded == 0)
            numberOfLinesNeeded = 1;
        const heightOfLine = 5;
        res = numberOfLinesNeeded * heightOfLine;
        //console.log("getTableCellHeight -> tmpErgazomenosAnaMera: ", JSON.stringify(tmpErgazomenosAnaMera, null, 2), ", maxCellStrLength: ", maxCellStrLength, ", numberOfLinesNeeded: ", numberOfLinesNeeded, ", res: ", res);
        return res;
    }

    public kentroKostousBelongsToTypoErgou(tmpKentroKostous: KentroKostous, tmpTyposErgouID: number) : boolean {
        let res: boolean = false;
        for(let i=0; i<tmpKentroKostous.typoiErgwn_ids.length; i++) {
            if(tmpKentroKostous.typoiErgwn_ids[i] == tmpTyposErgouID)
                res = true;
        }
        return res;
    }

    public isPositiveNumber(input: string): boolean {
        // Use the built-in Number function to attempt to convert the string to a number
        const num = Number(input);
    
        // Check if the result is a number >=0 and if the input string is not empty or just spaces
        return !isNaN(num) && num >= 0 && input.trim() !== '';
    }
}

export default UtilsManager;