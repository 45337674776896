import { View, SafeAreaView, ScrollView, TouchableOpacity, Dimensions } from 'react-native';
import myCss from '../../css/myCss';
import Footer from '../Footer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import RestManager from '../../managers/RestManager';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataTable, IconButton, Colors, Title, Text, Button, Portal, Modal, TextInput, Dialog, Paragraph, Switch } from 'react-native-paper';
import LoginManager from '../../managers/LoginManager';
import DropDown from 'react-native-paper-dropdown';
import ConstManager from '../../managers/ConstManager';
import AwesomeAlert from 'react-native-awesome-alerts';
import UtilsManager from '../../managers/UtilsManager';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigation } from '@react-navigation/native';

const screenDimensions = Dimensions.get('window');

const ManageErgaScreen = () => {
  const navigation = useNavigation();
  
  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ManageErgaScreen: I'm ready!");
    setIsLoading(true);
    (async () => {
      const tmpAllTypoiErgwn: Array<TyposErgou> = await RestManager.getInstance().getAllTypoysErgwn();
      setTypoiErgwn(tmpAllTypoiErgwn);

      const tmpAllUsers: Array<User> = await RestManager.getInstance().getAllUsers();
      setUsers(tmpAllUsers);

      const tmpAllKathgoriesErgazomenwn: Array<KathgoriaErgazomenwn> = await RestManager.getInstance().getAllKathgoriesErgazomenwn();
      setKathgoriesErgazomenwn(tmpAllKathgoriesErgazomenwn);

      const tmpErgazomenoi: Array<Ergazomenos> = await RestManager.getInstance().getAllErgazomenoi();
      setAllErgazomenoi(tmpErgazomenoi);

      const tmpAllErga: Array<Ergo> = await RestManager.getInstance().getAllErga();
      setErga(tmpAllErga);
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);

  function getPerigrafhTypouErgouApoID(tmpTyposErgouID: number) : string {
    for(let i=0; i<typoiErgwn.length; i++) {
      if(typoiErgwn[i].id == tmpTyposErgouID)
        return typoiErgwn[i].typos;
    }
    return "Άγνωστο!";
  }
  function getOnomatepwnymoApoUserID(tmpUserID: number) : string {
    for(let i=0; i<users.length; i++) {
      if(users[i].id == tmpUserID)
        return (users[i].onoma + " " + users[i].epwnymo);
    }
    return "";
  }

  const [kathgoriesErgazomenwn, setKathgoriesErgazomenwn] = useState<Array<KathgoriaErgazomenwn>>([]);

  function getPerigrafhKathgoriasErgazomenwnApoID(tmpKathgoriaErgazomenwnID: number) : string {
    for(let i=0; i<kathgoriesErgazomenwn.length; i++) {
      if(kathgoriesErgazomenwn[i].id == tmpKathgoriaErgazomenwnID)
        return kathgoriesErgazomenwn[i].kathgoria;
    }
    return "Άγνωστο!";
  }
 
  const [isLoading, setIsLoading] = useState(false);
  const [typoiErgwn, setTypoiErgwn] = useState<Array<TyposErgou>>([]);
  useEffect(() => {
    const newDropdownListItems = typoiErgwn.map((item) => ({
        label: item.typos,
        value: item.id,
    }));
    setTypoiErgwnDropdownItems(newDropdownListItems);

    //filter - start
    const newFilterDropdownListItems = [
      {
          label: 'Όλα',
          value: -1000
      },
      ...typoiErgwn.map((item) => ({
          label: item.typos,
          value: item.id,
      })),
    ];
    setFilterDropdownListItems(newFilterDropdownListItems);
    //filter - end
  }, [typoiErgwn]);
  const [users, setUsers] = useState<Array<User>>([]);
  useEffect(() => {
    const newMhxanikoiDropdownItems = users
      .filter((item) => item.role === LoginManager.getInstance().MHXANIKOS_ROLE)
      .map((item) => ({
        label: item.onoma + " " + item.epwnymo,
        value: item.id,
    }));
    setMhxanikoiDropdownItems(newMhxanikoiDropdownItems);

    const newErgodhgoiDropdownItems = users
      .filter((item) => item.role === LoginManager.getInstance().ERGODHGOS_ROLE)
      .map((item) => ({
        label: item.onoma + " " + item.epwnymo,
        value: item.id,
    }));
    setErgodhgoiDropdownItems(newErgodhgoiDropdownItems);
  }, [users]);
  const [erga, setErga] = useState<Array<Ergo>>([]);
  const [typoiErgwnDropdownItems, setTypoiErgwnDropdownItems] = useState([]);
  const [mhxanikoiDropdownItems, setMhxanikoiDropdownItems] = useState([]);
  const [ergodhgoiDropdownItems, setErgodhgoiDropdownItems] = useState([]);

  const [allErgazomenoi, setAllErgazomenoi] = useState<Array<Ergazomenos>>([]);
  const [allErgazomenoiDropdownListItems, setAllErgazomenoiDropdownListItems] = useState<Array<{ kathgoria_ergazomenou: string, onoma: string, id_ergazomenou: number }>>([]);
  const [selectedErgazomenoiItems, setSelectedErgazomenoiItems] = useState<Array<{ kathgoria_ergazomenou: string, onoma: string, id_ergazomenou: number }>>([]);
  const [selectedErgazomenoiIDs, setSelectedErgazomenoiIDs] = useState<Array<number>>([]);
  useEffect(() => {
    const newAllErgazomenoiDropdownListItems = allErgazomenoi.map((item) => ({
        kathgoria_ergazomenou: getPerigrafhKathgoriasErgazomenwnApoID(item.id_kathgorias),
        onoma: item.onoma + " " + item.epwnymo,
        id_ergazomenou: item.id,
    }));
    console.log("newAllErgazomenoiDropdownListItems: " + JSON.stringify(newAllErgazomenoiDropdownListItems, null, 2));
    setAllErgazomenoiDropdownListItems(newAllErgazomenoiDropdownListItems);
  }, [allErgazomenoi]);
  useEffect(() => {
    // Initialize an array to hold the selected items
    const tempNewSelectedItems = [];
    if(selectedErgazomenoiIDs!=null && selectedErgazomenoiIDs!=undefined) {
      for(let i=0; i<selectedErgazomenoiIDs.length; i++) {
        for(let j=0; j<allErgazomenoiDropdownListItems.length; j++) {
          if (allErgazomenoiDropdownListItems[j].id_ergazomenou === selectedErgazomenoiIDs[i]) {
            // Add the new item to the array
            tempNewSelectedItems.push({
              kathgoria_ergazomenou: allErgazomenoiDropdownListItems[j].kathgoria_ergazomenou,
              onoma: allErgazomenoiDropdownListItems[j].onoma,
              id_ergazomenou: selectedErgazomenoiIDs[i]
            });
          }
        }
      }
    }
    console.log("useEffect [selectedErgazomenoiIDs] -> tempNewSelectedItems: " + JSON.stringify(tempNewSelectedItems, null, 2));
    setSelectedErgazomenoiItems(tempNewSelectedItems);

    setNewErgo({...newErgo, ergazomenoi_ids: selectedErgazomenoiIDs });
    setEditedErgo({...editedErgo, ergazomenoi_ids: selectedErgazomenoiIDs });
  }, [selectedErgazomenoiIDs, allErgazomenoiDropdownListItems]);

  const [newErgoModalVisible, setNewErgoModalVisible] = useState(false);
  const hideNewErgoModal = () => { 
    setNewErgoModalVisible(false);
  }
  useEffect(() => {
    setSelectedErgazomenoiItems([]); //init
  }, [newErgoModalVisible]);

  const [editErgoModalVisible, setEditErgoModalVisible] = useState(false);
  const hideEditErgoModal = () => { 
    setEditErgoModalVisible(false);
  }
  
  const [newErgo, setNewErgo] = useState<Ergo>({
    id: -1,
    onoma: "",
    kwdikos: "",
    id_typou: -1,
    id_mhxanikou: -1,
    id_ergodhgou: -1,
    ergazomenoi_ids: []
  });

  const [editedErgo, setEditedErgo] = useState<Ergo>({
    id: -1,
    onoma: "",
    kwdikos: "",
    id_typou: -1,
    id_mhxanikou: -1,
    id_ergodhgou: -1,
    ergazomenoi_ids: []
  });
  
  // Create a ref for the TextInput components
  const onomaNewInputRef = useRef(null);
  const kwdikosNewInputRef = useRef(null);
  
  const onomaEditInputRef = useRef(null);
  const kwdikosEditInputRef = useRef(null);
  
  const [alertForNewWebModalVisible, setAlertForNewWebModalVisible] = useState(false);
  const [alertForNewWebModalText, setAlertForNewWebModalText] = useState('');

  const [alertForEditWebModalVisible, setAlertForEditWebModalVisible] = useState(false);
  const [alertForEditWebModalText, setAlertForEditWebModalText] = useState('');

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  useEffect(() => {
    if(alertForWebText!="" && alertForWebVisible == false) {
      navigation.reset({
        index: 1,
        routes: [{ name: 'AdminControlPanel' }]
      });
    }
  }, [alertForWebVisible]);
  const [alertForWebText, setAlertForWebText] = useState('');

  //filter - start
  const [filterDropdownListItems, setFilterDropdownListItems] = useState<Array<{ label: string, value: number }>>([]);
  const [showFilterDropdownList, setShowFilterDropdownList] = useState(false);
  const [filterSelectedTyposErgouID, setFilterSelectedTyposErgouID] = useState(-1000);
  //filter - end
  
  const [showTypoiErgwnDropdownList, setShowTypoiErgwnDropdownList] = useState(false);
  const [showMhxanikoiDropdownList, setShowMhxanikoiDropdownList] = useState(false);
  const [showErgodhgoiDropdownList, setShowErgodhgoiDropdownList] = useState(false);

  //ADD - start
  const [selectedTyposErgouAddErgo, setSelectedTyposErgouAddErgo] = useState(-1);
  useEffect(() => {
    setNewErgo({...newErgo, id_typou: selectedTyposErgouAddErgo });
  }, [selectedTyposErgouAddErgo]);
  
  const [selectedMhxanikosAddErgo, setSelectedMhxanikosAddErgo] = useState(-1);
  useEffect(() => {
    setNewErgo({...newErgo, id_mhxanikou: selectedMhxanikosAddErgo });
  }, [selectedMhxanikosAddErgo]);

  const [selectedErgodhgosAddErgo, setSelectedErgodhgosAddErgo] = useState(-1);
  useEffect(() => {
    setNewErgo({...newErgo, id_ergodhgou: selectedErgodhgosAddErgo });
  }, [selectedErgodhgosAddErgo]);
  //ADD -end

  //EDIT - start
  const [selectedTyposErgouEditErgo, setSelectedTyposErgouEditErgo] = useState(-1);
  useEffect(() => {
    setEditedErgo({...editedErgo, id_typou: selectedTyposErgouEditErgo });
  }, [selectedTyposErgouEditErgo]);
  
  const [selectedMhxanikosEditErgo, setSelectedMhxanikosEditErgo] = useState(-1);
  useEffect(() => {
    setEditedErgo({...editedErgo, id_mhxanikou: selectedMhxanikosEditErgo });
  }, [selectedMhxanikosEditErgo]);

  const [selectedErgodhgosEditErgo, setSelectedErgodhgosEditErgo] = useState(-1);
  useEffect(() => {
    setEditedErgo({...editedErgo, id_ergodhgou: selectedErgodhgosEditErgo });
  }, [selectedErgodhgosEditErgo]);
  //EDIT -end

  const addNewErgoAsync = useCallback(async () => {
    if(newErgo.id_typou == -1) {
      setAlertForNewWebModalText("Παρακαλώ επιλέξτε τύπο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgo.onoma == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε όνομα!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgo.kwdikos == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κωδικό!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgo.id_mhxanikou == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ επιλέξτε μηχανικό!");
      setAlertForNewWebModalVisible(true);
    }
    /*else if(newErgo.id_ergodhgou == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ επιλέξτε εργοδηγό!");
      setAlertForNewWebModalVisible(true);
    }*/
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().addErgo(newErgo);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().ERGO_ADDED_SUCCESSFULLY) {
          msgToShow = "Το έργο προστέθηκε επιτυχώς!";
          //update array
          const tmpAllErga: Array<Ergo> = await RestManager.getInstance().getAllErga();
          setErga(tmpAllErga);
          //init new obj
          setNewErgo({
            id: -1,
            onoma: "",
            kwdikos: "",
            id_typou: -1,
            id_mhxanikou: -1,
            id_ergodhgou: -1,
            ergazomenoi_ids: []
          });
        }
        setNewErgoModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }, [newErgo]);

  const editErgoAsync = useCallback(async () => {
    if(editedErgo.id_typou == -1) {
      setAlertForEditWebModalText("Παρακαλώ επιλέξτε τύπο!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedErgo.onoma == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε όνομα!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedErgo.kwdikos == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε κωδικό!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedErgo.id_mhxanikou == -1.0) {
      setAlertForEditWebModalText("Παρακαλώ επιλέξτε μηχανικό!");
      setAlertForEditWebModalVisible(true);
    }
    /*else if(editedErgo.id_ergodhgou == -1.0) {
      setAlertForEditWebModalText("Παρακαλώ επιλέξτε εργοδηγό!");
      setAlertForEditWebModalVisible(true);
    }*/
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().editErgo(editedErgo);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().ERGO_EDITED_SUCCESSFULLY) {
          msgToShow = "Τα στοιχεία του έργου ενημερώθηκαν επιτυχώς!";
          //update array
          const tmpAllErga: Array<Ergo> = await RestManager.getInstance().getAllErga();
          setErga(tmpAllErga);
          //init new obj
          setEditedErgo({
            id: -1,
            onoma: "",
            kwdikos: "",
            id_typou: -1,
            id_mhxanikou: -1,
            id_ergodhgou: -1,
            ergazomenoi_ids: []
          });
        }
        setEditErgoModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }, [editedErgo]);

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const hideDeleteDialog = () => setDeleteDialogVisible(false);

  const [ergoIDToBeDeleted, setErgoIDToBeDeleted] = useState(-1);

  async function deleteErgoAsync() {
    hideDeleteDialog();
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    webServiceResponseStr = await RestManager.getInstance().deleteErgo(ergoIDToBeDeleted);
    if(typeof webServiceResponseStr === 'string') {
      var msgToShow: string = "";
      if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
        msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
      else if(webServiceResponseStr == ConstManager.getInstance().ERGO_DELETED_SUCCESSFULLY) {
        msgToShow = "Το έργο διεγράφη επιτυχώς!";
        //update array
        const tmpAllErga: Array<Ergo> = await RestManager.getInstance().getAllErga();
        setErga(tmpAllErga);
        //init new obj
        setNewErgo({
          id: -1,
          onoma: "",
          kwdikos: "",
          id_typou: -1,
          id_mhxanikou: -1,
          id_ergodhgou: -1,
          ergazomenoi_ids: []
        });
      }
      setAlertForWebText(msgToShow);
      setAlertForWebVisible(true);
    }
    setIsLoading(false);
  }
  
  return (
    <SafeAreaView style={myCss.appSubviewWidth}>
      <Portal>
        <Modal visible={newErgoModalVisible} onDismiss={hideNewErgoModal} contentContainerStyle={myCss.modalContainer}>
          <ScrollView>
            <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Νέο έργο</Title>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Τύπος έργου"
                  mode={'outlined'}
                  visible={showTypoiErgwnDropdownList}
                  showDropDown={() => setShowTypoiErgwnDropdownList(true)}
                  onDismiss={() => setShowTypoiErgwnDropdownList(false)}
                  value={selectedTyposErgouAddErgo}
                  setValue={setSelectedTyposErgouAddErgo}
                  list={typoiErgwnDropdownItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showTypoiErgwnDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            <TouchableOpacity onPress={() => onomaNewInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={onomaNewInputRef}
                label="Όνομα"
                value={newErgo.onoma}
                onChangeText={tmpOnoma => setNewErgo({...newErgo, onoma: tmpOnoma })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kwdikosNewInputRef.current.focus()}>
              <TextInput
                ref={kwdikosNewInputRef}
                label="Κωδικός"
                value={newErgo.kwdikos}
                onChangeText={tmpKwdikos => setNewErgo({...newErgo, kwdikos: tmpKwdikos })}
              />
            </TouchableOpacity>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Μηχανικός"
                  mode={'outlined'}
                  visible={showMhxanikoiDropdownList}
                  showDropDown={() => setShowMhxanikoiDropdownList(true)}
                  onDismiss={() => setShowMhxanikoiDropdownList(false)}
                  value={selectedMhxanikosAddErgo}
                  setValue={setSelectedMhxanikosAddErgo}
                  list={mhxanikoiDropdownItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showMhxanikoiDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Εργοδηγός"
                  mode={'outlined'}
                  visible={showErgodhgoiDropdownList}
                  showDropDown={() => setShowErgodhgoiDropdownList(true)}
                  onDismiss={() => setShowErgodhgoiDropdownList(false)}
                  value={selectedErgodhgosAddErgo}
                  setValue={setSelectedErgodhgosAddErgo}
                  list={ergodhgoiDropdownItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showErgodhgoiDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            {ConstManager.getInstance().USE_ERGAZOMENOI_ANA_ERGO==true && <Multiselect
              options={allErgazomenoiDropdownListItems} // Options to display in the dropdown
              selectedValues={selectedErgazomenoiItems} // Preselected value to persist in dropdown
              onSelect={(selectedList, selectedItem) => {
                //console.log("onSelect -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onSelect -> selectedItem: " + JSON.stringify(selectedItem, null, 2));
                setSelectedErgazomenoiIDs([...selectedErgazomenoiIDs, selectedItem.id_ergazomenou]); //"useEfect - selectedErgazomenoiIDs" will trigger setSelectedErgazomenoiItems
              }}
              onRemove={(selectedList, removedItem) => {
                //console.log("onRemove -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onRemove -> removedItem: " + JSON.stringify(removedItem, null, 2));
                const tmpUpdatedIDs = selectedErgazomenoiIDs.filter(item => item !== removedItem.id_ergazomenou);
                setSelectedErgazomenoiIDs(tmpUpdatedIDs); //"useEfect - selectedErgazomenoiIDs" will trigger setSelectedErgazomenoiItems
              }}
              displayValue="onoma" // Property name to display in the dropdown options
              showCheckbox={true}
              groupBy="kathgoria_ergazomenou"
              placeholder="Εργαζόμενοι"
              style={{ 
                chips: { background: "green" }, 
                searchBox: { paddingTop: 10, paddingBottom: 10, border: "2px solid blue", borderRadius: 5 },
                multiselectContainer: { width: screenDimensions.width<=ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width*0.6 : ConstManager.getInstance().WEB_MAX_WIDTH*0.6, marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5 }
              }}
            />}
            <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={addNewErgoAsync}>{i18n.t('save')}</Button>
            <AwesomeAlert
              show={alertForNewWebModalVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForNewWebModalText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}  
            />
          </ScrollView>           
        </Modal>
      </Portal>

      <Portal>
        <Modal visible={editErgoModalVisible} onDismiss={hideEditErgoModal} contentContainerStyle={myCss.modalContainer}>
          <ScrollView>
            <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Υπάρχον έργο</Title>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Τύπος έργου"
                  mode={'outlined'}
                  visible={showTypoiErgwnDropdownList}
                  showDropDown={() => setShowTypoiErgwnDropdownList(true)}
                  onDismiss={() => setShowTypoiErgwnDropdownList(false)}
                  value={selectedTyposErgouEditErgo}
                  setValue={setSelectedTyposErgouEditErgo}
                  list={typoiErgwnDropdownItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showTypoiErgwnDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            <TouchableOpacity onPress={() => onomaEditInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={onomaEditInputRef}
                label="Όνομα"
                value={editedErgo.onoma}
                onChangeText={tmpOnoma => setEditedErgo({...editedErgo, onoma: tmpOnoma })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kwdikosEditInputRef.current.focus()}>
              <TextInput
                ref={kwdikosEditInputRef}
                label="Κωδικός"
                value={editedErgo.kwdikos}
                onChangeText={tmpKwdikos => setEditedErgo({...editedErgo, kwdikos: tmpKwdikos })}
              />
            </TouchableOpacity>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Μηχανικός"
                  mode={'outlined'}
                  visible={showMhxanikoiDropdownList}
                  showDropDown={() => setShowMhxanikoiDropdownList(true)}
                  onDismiss={() => setShowMhxanikoiDropdownList(false)}
                  value={selectedMhxanikosEditErgo}
                  setValue={setSelectedMhxanikosEditErgo}
                  list={mhxanikoiDropdownItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showMhxanikoiDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Εργοδηγός"
                  mode={'outlined'}
                  visible={showErgodhgoiDropdownList}
                  showDropDown={() => setShowErgodhgoiDropdownList(true)}
                  onDismiss={() => setShowErgodhgoiDropdownList(false)}
                  value={selectedErgodhgosEditErgo}
                  setValue={setSelectedErgodhgosEditErgo}
                  list={ergodhgoiDropdownItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showErgodhgoiDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            {ConstManager.getInstance().USE_ERGAZOMENOI_ANA_ERGO==true && <Multiselect
              options={allErgazomenoiDropdownListItems} // Options to display in the dropdown
              selectedValues={selectedErgazomenoiItems} // Preselected value to persist in dropdown
              onSelect={(selectedList, selectedItem) => {
                //console.log("onSelect -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onSelect -> selectedItem: " + JSON.stringify(selectedItem, null, 2));
                setSelectedErgazomenoiIDs([...selectedErgazomenoiIDs, selectedItem.id_ergazomenou]); //"useEfect - selectedErgazomenoiIDs" will trigger setSelectedErgazomenoiItems
              }}
              onRemove={(selectedList, removedItem) => {
                //console.log("onRemove -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onRemove -> removedItem: " + JSON.stringify(removedItem, null, 2));
                const tmpUpdatedIDs = selectedErgazomenoiIDs.filter(item => item !== removedItem.id_ergazomenou);
                setSelectedErgazomenoiIDs(tmpUpdatedIDs); //"useEfect - selectedErgazomenoiIDs" will trigger setSelectedErgazomenoiItems
              }}
              displayValue="onoma" // Property name to display in the dropdown options
              showCheckbox={true}
              groupBy="kathgoria_ergazomenou"
              placeholder="Εργαζόμενοι"
              style={{ 
                chips: { background: "green" }, 
                searchBox: { paddingTop: 10, paddingBottom: 10, border: "2px solid blue", borderRadius: 5 },
                multiselectContainer: { width: screenDimensions.width<=ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width*0.6 : ConstManager.getInstance().WEB_MAX_WIDTH*0.6, marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5 }
              }}
            />}
            <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={editErgoAsync}>{i18n.t('save')}</Button>
            <AwesomeAlert
              show={alertForNewWebModalVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForNewWebModalText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}  
            />
          </ScrollView>           
        </Modal>
      </Portal>

      <Portal>
        <Dialog visible={deleteDialogVisible} onDismiss={hideDeleteDialog} style={myCss.dialog}>
          <Dialog.Title>Διαγραφή έργου!</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{i18n.t('areYouSure')}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setDeleteDialogVisible(false)}>{i18n.t('cancel')}</Button>
            <Button onPress={() => deleteErgoAsync()}>Ok</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      {newErgoModalVisible==false 
        && editErgoModalVisible==false 
        && alertForWebVisible==false 
        && alertForNewWebModalVisible==false
        && alertForEditWebModalVisible==false
        && deleteDialogVisible==false
        && <ScrollView style={{/*height: 4000*/}}>
        <View style={myCss.container}>
          <Spinner
            //visibility of Overlay Loading Spinner
            visible={isLoading}
            //Text with the Spinner
            textContent={i18n.t('loading')}
            //Text style of the Spinner Text
            textStyle={myCss.spinnerTextStyle}
          />

          <Title style={myCss.title}>Έργα</Title>
          <View style={myCss.dropdownFullWidth}>
            <DropDown
                label="Τύπος έργου"
                mode={'outlined'}
                visible={showFilterDropdownList}
                showDropDown={() => setShowFilterDropdownList(true)}
                onDismiss={() => setShowFilterDropdownList(false)}
                value={filterSelectedTyposErgouID}
                setValue={setFilterSelectedTyposErgouID}
                list={filterDropdownListItems}
                inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showFilterDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
            />
          </View>
          <DataTable>
            {erga
              .filter((ergo) => 
                filterSelectedTyposErgouID === -1000 || 
              ergo.id_typou === filterSelectedTyposErgouID
              )
              .map((ergo: Ergo, i) => (             
              <DataTable.Row key={i} style={myCss.tableRow}>
                <DataTable.Title style={myCss.tableTitle}>{getPerigrafhTypouErgouApoID(ergo.id_typou)}</DataTable.Title>
                <DataTable.Cell style={myCss.tableValueColumn}>
                  <View style={{ direction: "column" }}>
                    <Text><Text style={{ fontWeight: 'bold' }}>Όνομα: </Text>{ergo.onoma}</Text>
                    <Text><Text style={{ fontWeight: 'bold' }}>Κωδικός: </Text>{ergo.kwdikos}</Text>
                    {getOnomatepwnymoApoUserID(ergo.id_mhxanikou)!="" && <Text><Text style={{ fontWeight: 'bold' }}>Μηχανικός: </Text>{getOnomatepwnymoApoUserID(ergo.id_mhxanikou)}</Text>}
                    {getOnomatepwnymoApoUserID(ergo.id_ergodhgou)!="" && <Text><Text style={{ fontWeight: 'bold' }}>Εργοδηγός: </Text>{getOnomatepwnymoApoUserID(ergo.id_ergodhgou)}</Text>}
                  </View>
                </DataTable.Cell>
                <DataTable.Cell style={myCss.tableIcon}>
                <IconButton
                    icon={({ color, size }) => (<MaterialIcons name="edit" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      const tempErgoToBeEdited: Ergo = {
                                        id: ergo.id,
                                        onoma: ergo.onoma,
                                        kwdikos: ergo.kwdikos,
                                        id_typou: ergo.id_typou,
                                        id_mhxanikou: ergo.id_mhxanikou,
                                        id_ergodhgou: ergo.id_ergodhgou
                                      };
                                      setEditedErgo(tempErgoToBeEdited); 
                                      setSelectedTyposErgouEditErgo(ergo.id_typou);
                                      setSelectedMhxanikosEditErgo(ergo.id_mhxanikou);
                                      setSelectedErgodhgosEditErgo(ergo.id_ergodhgou);
                                      setSelectedErgazomenoiIDs(ergo.ergazomenoi_ids);
                                      setEditErgoModalVisible(true);
                                   }} />
                  <IconButton
                    icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      setErgoIDToBeDeleted(ergo.id);
                                      setDeleteDialogVisible(true);
                                   }} />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
          <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="add" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} 
                                          onPress={() => {
                                                            const tempNewErgo: Ergo = {
                                                              id: -1,
                                                              onoma: "",
                                                              kwdikos: "",
                                                              id_typou: -1,
                                                              id_mhxanikou: -1,
                                                              id_ergodhgou: -1
                                                            };
                                                            setNewErgo(tempNewErgo); 
                                                            setSelectedTyposErgouAddErgo(-1);
                                                            setSelectedMhxanikosAddErgo(-1);
                                                            setSelectedErgodhgosAddErgo(-1);
                                                            setSelectedErgazomenoiIDs([]);
                                                            setNewErgoModalVisible(true);
                                                         }}>{i18n.t('addNewErgo')}</Button>
        </View>
      </ScrollView>}
      <AwesomeAlert
        show={alertForWebVisible}
        showProgress={false}
        title={i18n.t('caution')}
        message={alertForWebText}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="OK"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setAlertForWebVisible(false);
        }}
        onConfirmPressed={() => {
          setAlertForWebVisible(false);
        }}  
      />
    </SafeAreaView>
  );
};

export default ManageErgaScreen;