import { View, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';
import myCss from '../../../css/myCss';
import Footer from '../../Footer';
import React, { useEffect, useRef, useState } from 'react';
import RestManager from '../../../managers/RestManager';
import i18n from '../../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataTable, IconButton, Colors, Title, Text, Button, Portal, Modal, TextInput, Dialog, Paragraph } from 'react-native-paper';
import LoginManager from '../../../managers/LoginManager';
import ConstManager from '../../../managers/ConstManager';
import AwesomeAlert from 'react-native-awesome-alerts';
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigation } from '@react-navigation/native';
import { el } from "date-fns/locale/el";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from 'date-fns';
registerLocale("el", el);


const ManageArgiesScreen = () => {
  const navigation = useNavigation();

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ManageArgiesScreen: I'm ready!");
    setIsLoading(true);
    (async () => {
      const tmpAllArgies: Array<Argia> = await RestManager.getInstance().getAllArgies();
      setAllArgies(tmpAllArgies);      
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);
  const [allArgies, setAllArgies] = useState<Array<Argia>>([]);

  const [newArgiaModalVisible, setNewArgiaModalVisible] = useState(false);
  const hideNewArgiaModal = () => { 
    setNewArgiaModalVisible(false);
  }

  const [editArgiaModalVisible, setEditArgiaModalVisible] = useState(false);
  const hideEditArgiaModal = () => { 
    setEditArgiaModalVisible(false);
  }

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  useEffect(() => {
    console.log("isDatePickerOpen: " + isDatePickerOpen);
  }, [isDatePickerOpen]);

  const handleChange = (date) => {
    if(newArgiaModalVisible == true)
        setNewArgia({...newArgia, hmeromhnia: format(date, 'dd/MM/yyyy') });
    else if(editArgiaModalVisible == true)
        setEditedArgia({...editedArgia, hmeromhnia: format(date, 'dd/MM/yyyy') });
  };

  const [newArgia, setNewArgia] = useState<Argia>({
    id: -1,
    hmeromhnia: format(new Date(), 'dd/MM/yyyy'),
    perigrafh: ""
  });

  const [editedArgia, setEditedArgia] = useState<Argia>({
    id: -1,
    hmeromhnia: format(new Date(), 'dd/MM/yyyy'),
    perigrafh: ""
  });

  // Create a ref for the TextInput components
  const perigrafhNewInputRef = useRef(null);
  
  const perigrafhEditInputRef = useRef(null);

  const [alertForNewWebModalVisible, setAlertForNewWebModalVisible] = useState(false);
  const [alertForNewWebModalText, setAlertForNewWebModalText] = useState('');

  const [alertForEditWebModalVisible, setAlertForEditWebModalVisible] = useState(false);
  const [alertForEditWebModalText, setAlertForEditWebModalText] = useState('');

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  useEffect(() => {
    if(alertForWebText!="" && alertForWebVisible == false) {
      navigation.reset({
        index: 1,
        routes: [{ name: 'AdminControlPanel' }]
      });
    }
  }, [alertForWebVisible]);
  const [alertForWebText, setAlertForWebText] = useState('');

  async function addNewArgiaAsync() 
  {
    if(newArgia.hmeromhnia == "") {
        setAlertForNewWebModalText("Παρακαλώ συμπληρώστε ημερομηνία!");
        setAlertForNewWebModalVisible(true);
    }
    else if(newArgia.perigrafh == "") {
        setAlertForNewWebModalText("Παρακαλώ συμπληρώστε περιγραφή!");
        setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().addArgia(newArgia);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().ARGIA_ADDED_SUCCESSFULLY) {
          msgToShow = "Η αργία προστέθηκε επιτυχώς!";
          //update array
          const tmpAllArgies: Array<Argia> = await RestManager.getInstance().getAllArgies();
          setAllArgies(tmpAllArgies);
          //init new obj
          setNewArgia({
            id: -1,
            hmeromhnia: format(new Date(), 'dd/MM/yyyy'),
            perigrafh: ""
          });
        }
        setNewArgiaModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  async function editArgiaAsync() 
  {
    if(editedArgia.hmeromhnia == "") {
        setAlertForNewWebModalText("Παρακαλώ συμπληρώστε ημερομηνία!");
        setAlertForNewWebModalVisible(true);
    }
    else if(editedArgia.perigrafh == "") {
        setAlertForNewWebModalText("Παρακαλώ συμπληρώστε περιγραφή!");
        setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().editArgia(editedArgia);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().ARGIA_EDITED_SUCCESSFULLY) {
          msgToShow = "Η αργία ενημερώθηκε επιτυχώς!";
          //update array
          const tmpAllArgies: Array<Argia> = await RestManager.getInstance().getAllArgies();
          setAllArgies(tmpAllArgies);
          //init edit obj
          setEditedArgia({
            id: -1,
            hmeromhnia: format(new Date(), 'dd/MM/yyyy'),
            perigrafh: ""
          });
        }
        setEditArgiaModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const hideDeleteDialog = () => setDeleteDialogVisible(false);

  const [argiaIDToBeDeleted, setArgiaIDToBeDeleted] = useState(-1);

  async function deleteArgiaAsync() {
    hideDeleteDialog();
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    webServiceResponseStr = await RestManager.getInstance().deleteArgia(argiaIDToBeDeleted);
    if(typeof webServiceResponseStr === 'string') {
      var msgToShow: string = "";
      if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
        msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
      else if(webServiceResponseStr == ConstManager.getInstance().ARGIA_DELETED_SUCCESSFULLY) {
        msgToShow = "Η αργία διεγράφη επιτυχώς!";
        //update array
        const tmpAllArgies: Array<Argia> = await RestManager.getInstance().getAllArgies();
        setAllArgies(tmpAllArgies);
        //init new obj
        setNewArgia({
            id: -1,
            hmeromhnia: format(new Date(), 'dd/MM/yyyy'),
            perigrafh: ""
        });
      }
      setAlertForWebText(msgToShow);
      setAlertForWebVisible(true);
    }
    setIsLoading(false);
  }

  
  
  return (
    <SafeAreaView style={myCss.container}>

      <Portal>
          <Modal visible={newArgiaModalVisible} onDismiss={hideNewArgiaModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Νέα αργία</Title>
              <View style={{ flex: 1, alignItems: "center" }}>
                <DatePicker
                    locale="el"
                    selected={parse(newArgia.hmeromhnia, 'dd/MM/yyyy', new Date())}
                    onChange={handleChange}
                    dateFormat="dd/MM/yyyy"
                    onCalendarOpen={() => setIsDatePickerOpen(true)}
                    onCalendarClose={() => setIsDatePickerOpen(false)}
                />
              </View>
              {isDatePickerOpen==false && <TouchableOpacity onPress={() => perigrafhNewInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={perigrafhNewInputRef}
                  label="Περιγραφή"
                  value={newArgia.perigrafh}
                  onChangeText={tmpPerigrafh => setNewArgia({...newArgia, perigrafh: tmpPerigrafh })}
                />
              </TouchableOpacity>}
              {isDatePickerOpen==false && <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={addNewArgiaAsync}>{i18n.t('save')}</Button>}
              <AwesomeAlert
                show={alertForNewWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForNewWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Modal visible={editArgiaModalVisible} onDismiss={hideEditArgiaModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Υπάρχουσα αργία</Title>
              <View style={{ flex: 1, alignItems: "center" }}>
                <DatePicker
                    locale="el"
                    selected={parse(editedArgia.hmeromhnia, 'dd/MM/yyyy', new Date())}
                    onChange={handleChange}
                    dateFormat="dd/MM/yyyy"
                    onCalendarOpen={() => setIsDatePickerOpen(true)}
                    onCalendarClose={() => setIsDatePickerOpen(false)}
                />
              </View>
              {isDatePickerOpen==false && <TouchableOpacity onPress={() => perigrafhEditInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={perigrafhEditInputRef}
                  label="Περιγραφή"
                  value={editedArgia.perigrafh}
                  onChangeText={tmpPerigrafh => setEditedArgia({...editedArgia, perigrafh: tmpPerigrafh })}
                />
              </TouchableOpacity>}
              {isDatePickerOpen==false && <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={editArgiaAsync}>{i18n.t('save')}</Button>}
              <AwesomeAlert
                show={alertForEditWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForEditWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Dialog visible={deleteDialogVisible} onDismiss={hideDeleteDialog} style={myCss.dialog}>
            <Dialog.Title>Διαγραφή αργίας!</Dialog.Title>
            <Dialog.Content>
              <Paragraph>{i18n.t('areYouSure')}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setDeleteDialogVisible(false)}>{i18n.t('cancel')}</Button>
              <Button onPress={() => deleteArgiaAsync()}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        {newArgiaModalVisible==false
          && editArgiaModalVisible==false
          && deleteDialogVisible==false
          && alertForWebVisible==false
          && <ScrollView>
          <View style={myCss.container}>
            <Spinner
              //visibility of Overlay Loading Spinner
              visible={isLoading}
              //Text with the Spinner
              textContent={i18n.t('loading')}
              //Text style of the Spinner Text
              textStyle={myCss.spinnerTextStyle}
            />

            <Title style={myCss.title}>Αργίες</Title>
            <DataTable>
              {allArgies.map((tmpArgia: Argia, i) => (             
                <DataTable.Row key={i} style={myCss.tableRow}>
                  <DataTable.Title style={myCss.tableTitle}>{tmpArgia.hmeromhnia}</DataTable.Title>
                  <DataTable.Cell style={myCss.tableValueColumn}>{tmpArgia.perigrafh}</DataTable.Cell>
                  <DataTable.Cell style={myCss.tableIcon}>
                  <IconButton
                      icon={({ color, size }) => (<MaterialIcons name="edit" color={color} size={size} />)}
                      color={Colors.blue900}
                      size={35}
                      onPress={() => { 
                                        setEditedArgia(tmpArgia); 
                                        setEditArgiaModalVisible(true);
                                    }} />
                    <IconButton
                      icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                      color={Colors.blue900}
                      size={35}
                      onPress={() => { 
                                        setArgiaIDToBeDeleted(tmpArgia.id);
                                        setDeleteDialogVisible(true);
                                    }} />
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
            </DataTable>
            <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="add" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} 
                                            onPress={() => {
                                                              const tempNewArgia: Argia = {
                                                                id: -1,
                                                                hmeromhnia: format(new Date(), 'dd/MM/yyyy'),
                                                                perigrafh: ""
                                                              };
                                                              setNewArgia(tempNewArgia); 
                                                              setNewArgiaModalVisible(true);
                                                          }}>{i18n.t('addNewArgia')}</Button>
          </View>
        </ScrollView>}
        <AwesomeAlert
          show={alertForWebVisible}
          showProgress={false}
          title={i18n.t('caution')}
          message={alertForWebText}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="OK"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setAlertForWebVisible(false);
          }}
          onConfirmPressed={() => {
            setAlertForWebVisible(false);
          }}  
        />  
    </SafeAreaView>
  );
};

export default ManageArgiesScreen;