import { View, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';
import myCss from '../../css/myCss';
import Footer from '../Footer';
import React, { useEffect, useRef, useState } from 'react';
import RestManager from '../../managers/RestManager';
import i18n from '../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataTable, IconButton, Colors, Title, Text, Button, Portal, Modal, TextInput, Dialog, Paragraph, Switch } from 'react-native-paper';
import LoginManager from '../../managers/LoginManager';
import DropDown from 'react-native-paper-dropdown';
import ConstManager from '../../managers/ConstManager';
import AwesomeAlert from 'react-native-awesome-alerts';
import UtilsManager from '../../managers/UtilsManager';
import { useNavigation } from '@react-navigation/native';


const ManageErgazomenousScreen = () => {
  const navigation = useNavigation();
  
  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ManageErgazomenousScreen: I'm ready!");
    setIsLoading(true);
    (async () => {
      const tmpAllKathgoriesErgazomenwn: Array<KathgoriaErgazomenwn> = await RestManager.getInstance().getAllKathgoriesErgazomenwn();
      setKathgoriesErgazomenwn(tmpAllKathgoriesErgazomenwn);

      const tmpAllErgazomenoi: Array<Ergazomenos> = await RestManager.getInstance().getAllErgazomenoi();
      setErgazomenoi(tmpAllErgazomenoi);
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);
 
  const [isLoading, setIsLoading] = useState(false);
  const [kathgoriesErgazomenwn, setKathgoriesErgazomenwn] = useState<Array<KathgoriaErgazomenwn>>([]);
  const [ergazomenoi, setErgazomenoi] = useState<Array<Ergazomenos>>([]);

  function getPerigrafhKathgoriasErgazomenwnApoID(tmpKathgoriaErgazomenwnID: number) : string {
    for(let i=0; i<kathgoriesErgazomenwn.length; i++) {
      if(kathgoriesErgazomenwn[i].id == tmpKathgoriaErgazomenwnID)
        return kathgoriesErgazomenwn[i].kathgoria;
    }
    return "Άγνωστο!";
  }

  const [newErgazomenosModalVisible, setNewErgazomenosModalVisible] = useState(false);
  const hideNewErgazomenosModal = () => { 
    setNewErgazomenosModalVisible(false);
  }

  const [editErgazomenosModalVisible, setEditErgazomenosModalVisible] = useState(false);
  const hideEditErgazomenosModal = () => { 
    setEditErgazomenosModalVisible(false);
  }

  const [newErgazomenos, setNewErgazomenos] = useState<Ergazomenos>({
    id: -1,
    onoma: "",
    epwnymo: "",
    thlefwno: "",
    id_kathgorias: -1,
    trapeza: "",
    iban: "",
    kostos_hmeromisthiou_kathimerinh: -1.0,
    kostos_hmeromisthiou_savvato: -1.0,
    kostos_hmeromisthiou_kyriakh: -1.0,
    kostos_yperwrias: -1.0,
    kostos_dianyktereyshs: -1.0,
    kostos_efka_kathimerinh: -1.0,
    kostos_efka_savvato: -1.0,
    kostos_odoiporikwn_ana_wra: -1.0
  });

  const [editedErgazomenos, setEditedErgazomenos] = useState<Ergazomenos>({
    id: -1,
    onoma: "",
    epwnymo: "",
    thlefwno: "",
    id_kathgorias: -1,
    trapeza: "",
    iban: "",
    kostos_hmeromisthiou_kathimerinh: -1.0,
    kostos_hmeromisthiou_savvato: -1.0,
    kostos_hmeromisthiou_kyriakh: -1.0,
    kostos_yperwrias: -1.0,
    kostos_dianyktereyshs: -1.0,
    kostos_efka_kathimerinh: -1.0,
    kostos_efka_savvato: -1.0,
    kostos_odoiporikwn_ana_wra: -1.0
  });

  // Create a ref for the TextInput components
  const onomaNewInputRef = useRef(null);
  const epwnymoNewInputRef = useRef(null);
  const thlefwnoNewInputRef = useRef(null);
  const trapezaNewInputRef = useRef(null);
  const ibanNewInputRef = useRef(null);
  const kostosHmeromisthiouKathimerinhNewInputRef = useRef(null);
  const kostosHmeromisthiouSavvatoNewInputRef = useRef(null);
  const kostosHmeromisthiouKyriakhNewInputRef = useRef(null);
  const kostosYperwriasNewInputRef = useRef(null);
  const kostosDianyktereyshsNewInputRef = useRef(null);
  const kostosEfkaKathimerinhNewInputRef = useRef(null);
  const kostosEfkaSavvatoNewInputRef = useRef(null);
  const kostosOdoiporikwnAnaWraNewInputRef = useRef(null);

  const onomaEditInputRef = useRef(null);
  const epwnymoEditInputRef = useRef(null);
  const thlefwnoEditInputRef = useRef(null);
  const trapezaEditInputRef = useRef(null);
  const ibanEditInputRef = useRef(null);
  const kostosHmeromisthiouKathimerinhEditInputRef = useRef(null);
  const kostosHmeromisthiouSavvatoEditInputRef = useRef(null);
  const kostosHmeromisthiouKyriakhEditInputRef = useRef(null);
  const kostosYperwriasEditInputRef = useRef(null);
  const kostosDianyktereyshsEditInputRef = useRef(null);
  const kostosEfkaKathimerinhEditInputRef = useRef(null);
  const kostosEfkaSavvatoEditInputRef = useRef(null);
  const kostosOdoiporikwnAnaWraEditInputRef = useRef(null);

  const [alertForNewWebModalVisible, setAlertForNewWebModalVisible] = useState(false);
  const [alertForNewWebModalText, setAlertForNewWebModalText] = useState('');

  const [alertForEditWebModalVisible, setAlertForEditWebModalVisible] = useState(false);
  const [alertForEditWebModalText, setAlertForEditWebModalText] = useState('');

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  useEffect(() => {
    if(alertForWebText!="" && alertForWebVisible == false) {
      navigation.reset({
        index: 1,
        routes: [{ name: 'AdminControlPanel' }]
      });
    }
  }, [alertForWebVisible]);

  const [alertForWebText, setAlertForWebText] = useState('');

  //filter - start
  const [filterDropdownListItems, setFilterDropdownListItems] = useState<Array<{ label: string, value: number }>>([]);
  const [showFilterDropdownList, setShowFilterDropdownList] = useState(false);
  const [filterSelectedKathgoriaErgazomenwnID, setFilterSelectedKathgoriaErgazomenwnID] = useState(-1000);
  //filter - end

  const [dropdownListItems, setDropdownListItems] = useState<Array<{ label: string, value: number }>>([]);
  useEffect(() => {
    const newDropdownListItems = kathgoriesErgazomenwn.map((item) => ({
        label: item.kathgoria,
        value: item.id,
    }));
    setDropdownListItems(newDropdownListItems);

    //filter - start
    const newFilterDropdownListItems = [
      {
          label: 'Όλοι',
          value: ConstManager.getInstance().OLOI_OI_ERGAZOMENOI
      },
      ...kathgoriesErgazomenwn.map((item) => ({
          label: item.kathgoria,
          value: item.id,
      })),
    ];
    setFilterDropdownListItems(newFilterDropdownListItems);
    //filter - end
  }, [kathgoriesErgazomenwn]);
  const [showKathgoriaErgazomenouDropdownList, setShowKathgoriaErgazomenouDropdownList] = useState(false);

  const [selectedKathgoriaErgazomenouAddErgazomenos, setSelectedKathgoriaErgazomenouAddErgazomenos] = useState(-1);
  useEffect(() => {
    setNewErgazomenos({...newErgazomenos, id_kathgorias: selectedKathgoriaErgazomenouAddErgazomenos });
  }, [selectedKathgoriaErgazomenouAddErgazomenos]);

  const [selectedKathgoriaErgazomenouEditErgazomenos, setSelectedKathgoriaErgazomenouEditErgazomenos] = useState(-1);
  useEffect(() => {
    setEditedErgazomenos({...editedErgazomenos, id_kathgorias: selectedKathgoriaErgazomenouEditErgazomenos });
  }, [selectedKathgoriaErgazomenouEditErgazomenos]);

  async function addNewErgazomenoAsync() 
  {
    if(newErgazomenos.onoma == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε όνομα!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.epwnymo == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε επώνυμο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.id_kathgorias == -1) {
      setAlertForNewWebModalText("Παρακαλώ επιλέξτε κατηγορία εργαζομένου!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.trapeza == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε όνομα τράπεζας!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.iban == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε IBAN!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_hmeromisthiou_kathimerinh == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ημερομισθίου για καθημερινή!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_hmeromisthiou_savvato == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ημερομισθίου για Σάββατο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_hmeromisthiou_kyriakh == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ημερομισθίου για Κυριακή!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_yperwrias == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε (>=0) κόστος υπερωρίας!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_dianyktereyshs == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) διανυκτέρευσης!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_efka_kathimerinh == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ΕΦΚΑ για καθημερινή!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_efka_savvato == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ΕΦΚΑ για Σάββατο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newErgazomenos.kostos_odoiporikwn_ana_wra == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) οδοιπορικών ανά ώρα!");
      setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().addErgazomeno(newErgazomenos);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().ERGAZOMENOS_ADDED_SUCCESSFULLY) {
          msgToShow = "Ο εργαζόμενος προστέθηκε επιτυχώς!";
          //update array
          const tmpAllErgazomenoi: Array<Ergazomenos> = await RestManager.getInstance().getAllErgazomenoi();
          setErgazomenoi(tmpAllErgazomenoi);
          //init new obj
          setNewErgazomenos({
            id: -1,
            onoma: "",
            epwnymo: "",
            thlefwno: "",
            id_kathgorias: -1,
            trapeza: "",
            iban: "",
            kostos_hmeromisthiou_kathimerinh: -1.0,
            kostos_hmeromisthiou_savvato: -1.0,
            kostos_hmeromisthiou_kyriakh: -1.0,
            kostos_yperwrias: -1.0,
            kostos_dianyktereyshs: -1.0,
            kostos_efka_kathimerinh: -1.0,
            kostos_efka_savvato: -1.0,
            kostos_odoiporikwn_ana_wra: -1.0
          });
        }
        setNewErgazomenosModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  async function editErgazomenoAsync() 
  {
    if(editedErgazomenos.onoma == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε όνομα!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.epwnymo == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε επώνυμο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.id_kathgorias == -1) {
      setAlertForNewWebModalText("Παρακαλώ επιλέξτε κατηγορία εργαζομένου!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.trapeza == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε όνομα τράπεζας!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.iban == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε IBAN!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_hmeromisthiou_kathimerinh == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ημερομισθίου για καθημερινή!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_hmeromisthiou_savvato == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ημερομισθίου για Σάββατο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_hmeromisthiou_kyriakh == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ημερομισθίου για Κυριακή!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_yperwrias == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) υπερωρίας!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_dianyktereyshs == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) διανυκτέρευσης!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_efka_kathimerinh == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ΕΦΚΑ για καθημερινή!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_efka_savvato == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) ΕΦΚΑ για Σάββατο!");
      setAlertForNewWebModalVisible(true);
    }
    else if(editedErgazomenos.kostos_odoiporikwn_ana_wra == -1.0) {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κόστος (>=0) οδοιπορικών ανά ώρα!");
      setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().editErgazomeno(editedErgazomenos);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().ERGAZOMENOS_EDITED_SUCCESSFULLY) {
          msgToShow = "Τα στοιχεία του εργαζομένου ενημερώθηκαν επιτυχώς!";
          //update array
          const tmpAllErgazomenoi: Array<Ergazomenos> = await RestManager.getInstance().getAllErgazomenoi();
          setErgazomenoi(tmpAllErgazomenoi);
          //init new obj
          setEditedErgazomenos({
            id: -1,
            onoma: "",
            epwnymo: "",
            thlefwno: "",
            id_kathgorias: -1,
            trapeza: "",
            iban: "",
            kostos_hmeromisthiou_kathimerinh: -1.0,
            kostos_hmeromisthiou_savvato: -1.0,
            kostos_hmeromisthiou_kyriakh: -1.0,
            kostos_yperwrias: -1.0,
            kostos_dianyktereyshs: -1.0,
            kostos_efka_kathimerinh: -1.0,
            kostos_efka_savvato: -1.0,
            kostos_odoiporikwn_ana_wra: -1.0
          });
        }
        setEditErgazomenosModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const hideDeleteDialog = () => setDeleteDialogVisible(false);

  const [ergazomenosIDToBeDeleted, setErgazomenosIDToBeDeleted] = useState(-1);

  async function deleteErgazomenoAsync() {
    hideDeleteDialog();
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    webServiceResponseStr = await RestManager.getInstance().deleteErgazomeno(ergazomenosIDToBeDeleted);
    if(typeof webServiceResponseStr === 'string') {
      var msgToShow: string = "";
      if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
        msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
      else if(webServiceResponseStr == ConstManager.getInstance().ERGAZOMENOS_DELETED_SUCCESSFULLY) {
        msgToShow = "Ο εργαζόμενος διεγράφη επιτυχώς!";
        //update array
        const tmpAllErgazomenoi: Array<Ergazomenos> = await RestManager.getInstance().getAllErgazomenoi();
        setErgazomenoi(tmpAllErgazomenoi);
        //init new obj
        setNewErgazomenos({
          id: -1,
          onoma: "",
          epwnymo: "",
          thlefwno: "",
          id_kathgorias: -1,
          trapeza: "",
          iban: "",
          kostos_hmeromisthiou_kathimerinh: -1.0,
          kostos_hmeromisthiou_savvato: -1.0,
          kostos_hmeromisthiou_kyriakh: -1.0,
          kostos_yperwrias: -1.0,
          kostos_dianyktereyshs: -1.0,
          kostos_efka_kathimerinh: -1.0,
          kostos_efka_savvato: -1.0,
          kostos_odoiporikwn_ana_wra: -1.0
        });
      }
      setAlertForWebText(msgToShow);
      setAlertForWebVisible(true);
    }
    setIsLoading(false);
  }
  
  return (
    <SafeAreaView style={myCss.container}>
      <Portal>
        <Modal visible={newErgazomenosModalVisible} onDismiss={hideNewErgazomenosModal} contentContainerStyle={myCss.modalContainer}>
          <ScrollView>
            <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Νέος εργαζόμενος</Title>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Κατηγορία εργαζομένου"
                  mode={'outlined'}
                  visible={showKathgoriaErgazomenouDropdownList}
                  showDropDown={() => setShowKathgoriaErgazomenouDropdownList(true)}
                  onDismiss={() => setShowKathgoriaErgazomenouDropdownList(false)}
                  value={selectedKathgoriaErgazomenouAddErgazomenos}
                  setValue={setSelectedKathgoriaErgazomenouAddErgazomenos}
                  list={dropdownListItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showKathgoriaErgazomenouDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            <TouchableOpacity onPress={() => onomaNewInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={onomaNewInputRef}
                label="Όνομα"
                value={newErgazomenos.onoma}
                onChangeText={tmpOnoma => setNewErgazomenos({...newErgazomenos, onoma: tmpOnoma })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => epwnymoNewInputRef.current.focus()}>
              <TextInput
                ref={epwnymoNewInputRef}
                label="Επώνυμο"
                value={newErgazomenos.epwnymo}
                onChangeText={tmpEpwnymo => setNewErgazomenos({...newErgazomenos, epwnymo: tmpEpwnymo })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => thlefwnoNewInputRef.current.focus()}>
              <TextInput
                ref={thlefwnoNewInputRef}
                label="Τηλέφωνο"
                value={newErgazomenos.thlefwno}
                onChangeText={tmpThlefwno => setNewErgazomenos({...newErgazomenos, thlefwno: tmpThlefwno })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => trapezaNewInputRef.current.focus()}>
              <TextInput
                ref={trapezaNewInputRef}
                label="Τράπεζα"
                value={newErgazomenos.trapeza}
                onChangeText={tmpTrapeza => setNewErgazomenos({...newErgazomenos, trapeza: tmpTrapeza })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => ibanNewInputRef.current.focus()}>
              <TextInput
                ref={ibanNewInputRef}
                label="IBAN"
                value={newErgazomenos.iban}
                onChangeText={tmpIban => setNewErgazomenos({...newErgazomenos, iban: tmpIban })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosHmeromisthiouKathimerinhNewInputRef.current.focus()}>
              <TextInput
                ref={kostosHmeromisthiouKathimerinhNewInputRef}
                label="Κόστος ημερομισθίου για καθημερινή (€)"
                value={newErgazomenos.kostos_hmeromisthiou_kathimerinh!=-1 ? newErgazomenos.kostos_hmeromisthiou_kathimerinh : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_hmeromisthiou_kathimerinh: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_hmeromisthiou_kathimerinh: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosHmeromisthiouSavvatoNewInputRef.current.focus()}>
              <TextInput
                ref={kostosHmeromisthiouSavvatoNewInputRef}
                label="Κόστος ημερομισθίου για Σάββατο (€)"
                value={newErgazomenos.kostos_hmeromisthiou_savvato!=-1 ? newErgazomenos.kostos_hmeromisthiou_savvato : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_hmeromisthiou_savvato: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_hmeromisthiou_savvato: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosHmeromisthiouKyriakhNewInputRef.current.focus()}>
              <TextInput
                ref={kostosHmeromisthiouKyriakhNewInputRef}
                label="Κόστος ημερομισθίου για Κυριακή (€)"
                value={newErgazomenos.kostos_hmeromisthiou_kyriakh!=-1 ? newErgazomenos.kostos_hmeromisthiou_kyriakh : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_hmeromisthiou_kyriakh: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_hmeromisthiou_kyriakh: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosYperwriasNewInputRef.current.focus()}>
              <TextInput
                ref={kostosYperwriasNewInputRef}
                label="Κόστος υπερωρίας (€)"
                value={newErgazomenos.kostos_yperwrias!=-1 ? newErgazomenos.kostos_yperwrias : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_yperwrias: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_yperwrias: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosDianyktereyshsNewInputRef.current.focus()}>
              <TextInput
                ref={kostosDianyktereyshsNewInputRef}
                label="Κόστος διανυκτέρευσης (€)"
                value={newErgazomenos.kostos_dianyktereyshs!=-1 ? newErgazomenos.kostos_dianyktereyshs : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_dianyktereyshs: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_dianyktereyshs: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosEfkaKathimerinhNewInputRef.current.focus()}>
              <TextInput
                ref={kostosEfkaKathimerinhNewInputRef}
                label="Κόστος ΕΦΚΑ για καθημερινή (€)"
                value={newErgazomenos.kostos_efka_kathimerinh!=-1 ? newErgazomenos.kostos_efka_kathimerinh : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_efka_kathimerinh: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_efka_kathimerinh: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosEfkaSavvatoNewInputRef.current.focus()}>
              <TextInput
                ref={kostosEfkaSavvatoNewInputRef}
                label="Κόστος ΕΦΚΑ για Σάββατο (€)"
                value={newErgazomenos.kostos_efka_savvato!=-1 ? newErgazomenos.kostos_efka_savvato : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_efka_savvato: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_efka_savvato: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosOdoiporikwnAnaWraNewInputRef.current.focus()}>
              <TextInput
                ref={kostosOdoiporikwnAnaWraNewInputRef}
                label="Κόστος οδοιπορικών ανά ώρα (€)"
                value={newErgazomenos.kostos_odoiporikwn_ana_wra!=-1 ? newErgazomenos.kostos_odoiporikwn_ana_wra : ""}
                onChangeText={tmpKostosOdoiporikwnAnaWra => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostosOdoiporikwnAnaWra.trim()))
                    setNewErgazomenos({...newErgazomenos, kostos_odoiporikwn_ana_wra: tmpKostosOdoiporikwnAnaWra.trim() });
                  else {
                    if(tmpKostosOdoiporikwnAnaWra.trim() == "")
                      setNewErgazomenos({...newErgazomenos, kostos_odoiporikwn_ana_wra: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>              
            <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={addNewErgazomenoAsync}>{i18n.t('save')}</Button>
            <AwesomeAlert
              show={alertForNewWebModalVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForNewWebModalText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}  
            />
          </ScrollView>           
        </Modal>
      </Portal>

      <Portal>
        <Modal visible={editErgazomenosModalVisible} onDismiss={hideEditErgazomenosModal} contentContainerStyle={myCss.modalContainer}>
          <ScrollView>
            <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Υπάρχων εργαζόμενος</Title>
            <View style={myCss.domainDropdownModal}>
              <DropDown
                  label="Κατηγορία εργαζομένου"
                  mode={'outlined'}
                  visible={showKathgoriaErgazomenouDropdownList}
                  showDropDown={() => setShowKathgoriaErgazomenouDropdownList(true)}
                  onDismiss={() => setShowKathgoriaErgazomenouDropdownList(false)}
                  value={selectedKathgoriaErgazomenouEditErgazomenos}
                  setValue={setSelectedKathgoriaErgazomenouEditErgazomenos}
                  list={dropdownListItems}
                  inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showKathgoriaErgazomenouDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
              />
            </View>
            <TouchableOpacity onPress={() => onomaEditInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={onomaEditInputRef}
                label="Όνομα"
                value={editedErgazomenos.onoma}
                onChangeText={tmpOnoma => setEditedErgazomenos({...editedErgazomenos, onoma: tmpOnoma })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => epwnymoEditInputRef.current.focus()}>
              <TextInput
                ref={epwnymoEditInputRef}
                label="Επώνυμο"
                value={editedErgazomenos.epwnymo}
                onChangeText={tmpEpwnymo => setEditedErgazomenos({...editedErgazomenos, epwnymo: tmpEpwnymo })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => thlefwnoEditInputRef.current.focus()}>
              <TextInput
                ref={thlefwnoEditInputRef}
                label="Τηλέφωνο"
                value={editedErgazomenos.thlefwno}
                onChangeText={tmpThlefwno => setEditedErgazomenos({...editedErgazomenos, thlefwno: tmpThlefwno })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => trapezaEditInputRef.current.focus()}>
              <TextInput
                ref={trapezaEditInputRef}
                label="Τράπεζα"
                value={editedErgazomenos.trapeza}
                onChangeText={tmpTrapeza => setEditedErgazomenos({...editedErgazomenos, trapeza: tmpTrapeza })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => ibanEditInputRef.current.focus()}>
              <TextInput
                ref={ibanEditInputRef}
                label="IBAN"
                value={editedErgazomenos.iban}
                onChangeText={tmpIban => setEditedErgazomenos({...editedErgazomenos, iban: tmpIban })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosHmeromisthiouKathimerinhEditInputRef.current.focus()}>
              <TextInput
                ref={kostosHmeromisthiouKathimerinhEditInputRef}
                label="Κόστος ημερομισθίου για καθημερινή"
                value={editedErgazomenos.kostos_hmeromisthiou_kathimerinh!=-1 ? editedErgazomenos.kostos_hmeromisthiou_kathimerinh : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_hmeromisthiou_kathimerinh: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_hmeromisthiou_kathimerinh: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosHmeromisthiouSavvatoEditInputRef.current.focus()}>
              <TextInput
                ref={kostosHmeromisthiouSavvatoEditInputRef}
                label="Κόστος ημερομισθίου για Σάββατο"
                value={editedErgazomenos.kostos_hmeromisthiou_savvato!=-1 ? editedErgazomenos.kostos_hmeromisthiou_savvato : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_hmeromisthiou_savvato: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_hmeromisthiou_savvato: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosHmeromisthiouKyriakhEditInputRef.current.focus()}>
              <TextInput
                ref={kostosHmeromisthiouKyriakhEditInputRef}
                label="Κόστος ημερομισθίου για Κυριακή"
                value={editedErgazomenos.kostos_hmeromisthiou_kyriakh!=-1 ? editedErgazomenos.kostos_hmeromisthiou_kyriakh : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_hmeromisthiou_kyriakh: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_hmeromisthiou_kyriakh: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosYperwriasEditInputRef.current.focus()}>
              <TextInput
                ref={kostosYperwriasEditInputRef}
                label="Κόστος υπερωρίας"
                value={editedErgazomenos.kostos_yperwrias!=-1 ? editedErgazomenos.kostos_yperwrias : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_yperwrias: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_yperwrias: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosDianyktereyshsEditInputRef.current.focus()}>
              <TextInput
                ref={kostosDianyktereyshsEditInputRef}
                label="Κόστος διανυκτέρευσης"
                value={editedErgazomenos.kostos_dianyktereyshs!=-1 ? editedErgazomenos.kostos_dianyktereyshs : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_dianyktereyshs: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_dianyktereyshs: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosEfkaKathimerinhEditInputRef.current.focus()}>
              <TextInput
                ref={kostosEfkaKathimerinhEditInputRef}
                label="Κόστος ΕΦΚΑ για καθημερινή"
                value={editedErgazomenos.kostos_efka_kathimerinh!=-1 ? editedErgazomenos.kostos_efka_kathimerinh : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_efka_kathimerinh: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_efka_kathimerinh: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosEfkaSavvatoEditInputRef.current.focus()}>
              <TextInput
                ref={kostosEfkaSavvatoEditInputRef}
                label="Κόστος ΕΦΚΑ για Σάββατο"
                value={editedErgazomenos.kostos_efka_savvato!=-1 ? editedErgazomenos.kostos_efka_savvato : ""}
                onChangeText={tmpKostos => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostos.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_efka_savvato: tmpKostos.trim() });
                  else {
                    if(tmpKostos.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_efka_savvato: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => kostosOdoiporikwnAnaWraEditInputRef.current.focus()}>
              <TextInput
                ref={kostosOdoiporikwnAnaWraEditInputRef}
                label="Κόστος οδοιπορικών ανά ώρα"
                value={editedErgazomenos.kostos_odoiporikwn_ana_wra!=-1 ? editedErgazomenos.kostos_odoiporikwn_ana_wra : ""}
                onChangeText={tmpKostosOdoiporikwnAnaWra => {
                  if(UtilsManager.getInstance().isPositiveNumber(tmpKostosOdoiporikwnAnaWra.trim()))
                    setEditedErgazomenos({...editedErgazomenos, kostos_odoiporikwn_ana_wra: tmpKostosOdoiporikwnAnaWra.trim() });
                  else {
                    if(tmpKostosOdoiporikwnAnaWra.trim() == "")
                      setEditedErgazomenos({...editedErgazomenos, kostos_odoiporikwn_ana_wra: -1 });
                    else {
                      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε έναν αριθμό μεγαλύτερο ή ίσο από 0!");
                      setAlertForNewWebModalVisible(true);
                    }
                  }
                }}
              />
            </TouchableOpacity>              
            <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={editErgazomenoAsync}>{i18n.t('save')}</Button>
            <AwesomeAlert
              show={alertForNewWebModalVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForNewWebModalText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}  
            />
          </ScrollView>           
        </Modal>
      </Portal>

      <Portal>
        <Dialog visible={deleteDialogVisible} onDismiss={hideDeleteDialog} style={myCss.dialog}>
          <Dialog.Title>Διαγραφή εργαζομένου!</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{i18n.t('areYouSure')}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setDeleteDialogVisible(false)}>{i18n.t('cancel')}</Button>
            <Button onPress={() => deleteErgazomenoAsync()}>Ok</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      {newErgazomenosModalVisible==false 
        && editErgazomenosModalVisible==false 
        && alertForWebVisible==false 
        && alertForNewWebModalVisible==false
        && alertForEditWebModalVisible==false
        && deleteDialogVisible==false
        && <ScrollView style={{/*height: 4000*/}}>
        <View style={myCss.container}>
          <Spinner
            //visibility of Overlay Loading Spinner
            visible={isLoading}
            //Text with the Spinner
            textContent={i18n.t('loading')}
            //Text style of the Spinner Text
            textStyle={myCss.spinnerTextStyle}
          />

          <Title style={myCss.title}>Εργαζόμενοι</Title>
          <View style={myCss.dropdownFullWidth}>
            <DropDown
                label="Κατηγορία εργαζομένων"
                mode={'outlined'}
                visible={showFilterDropdownList}
                showDropDown={() => setShowFilterDropdownList(true)}
                onDismiss={() => setShowFilterDropdownList(false)}
                value={filterSelectedKathgoriaErgazomenwnID}
                setValue={setFilterSelectedKathgoriaErgazomenwnID}
                list={filterDropdownListItems}
                inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showFilterDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
            />
          </View>
          <DataTable>
            {ergazomenoi
              .filter((ergazomenos) => 
                filterSelectedKathgoriaErgazomenwnID === ConstManager.getInstance().OLOI_OI_ERGAZOMENOI || 
                ergazomenos.id_kathgorias === filterSelectedKathgoriaErgazomenwnID
              )
              .map((ergazomenos: Ergazomenos, i) => (             
              <DataTable.Row key={i} style={myCss.tableRow}>
                <DataTable.Title style={myCss.tableTitle}>{getPerigrafhKathgoriasErgazomenwnApoID(ergazomenos.id_kathgorias)}</DataTable.Title>
                <DataTable.Cell style={myCss.tableValueColumn}>
                  <View style={{ direction: "column" }}>
                    <Text><Text style={{ fontWeight: 'bold' }}>Ονοματεπώνυμο: </Text>{ergazomenos.onoma + " " + ergazomenos.epwnymo}</Text>
                    <Text><Text style={{ fontWeight: 'bold' }}>Τηλέφωνο: </Text>{ergazomenos.thlefwno}</Text>
                    <Text><Text style={{ fontWeight: 'bold' }}>Τράπεζα: </Text>{ergazomenos.trapeza}</Text>
                    <Text><Text style={{ fontWeight: 'bold' }}>IBAN: </Text>{ergazomenos.iban}</Text>
                    {ergazomenos.kostos_hmeromisthiou_kathimerinh>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος ημερομισθίου καθημερινή: </Text>{ergazomenos.kostos_hmeromisthiou_kathimerinh}</Text>}
                    {ergazomenos.kostos_hmeromisthiou_savvato>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος ημερομισθίου Σάββατο: </Text>{ergazomenos.kostos_hmeromisthiou_savvato}</Text>}
                    {ergazomenos.kostos_hmeromisthiou_kyriakh>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος ημερομισθίου Κυριακή: </Text>{ergazomenos.kostos_hmeromisthiou_kyriakh}</Text>}
                    {ergazomenos.kostos_yperwrias>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος υπερωρίας: </Text>{ergazomenos.kostos_yperwrias}</Text>}
                    {ergazomenos.kostos_dianyktereyshs>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος διανυκτέρευσης: </Text>{ergazomenos.kostos_dianyktereyshs}</Text>}
                    {ergazomenos.kostos_efka_kathimerinh>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος ΕΦΚΑ καθημερινή: </Text>{ergazomenos.kostos_efka_kathimerinh}</Text>}
                    {ergazomenos.kostos_efka_savvato>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος ΕΦΚΑ Σάββατο: </Text>{ergazomenos.kostos_efka_savvato}</Text>}
                    {ergazomenos.kostos_odoiporikwn_ana_wra>0 && <Text><Text style={{ fontWeight: 'bold' }}>Κόστος οδοιπορικών (ανά ώρα): </Text>{ergazomenos.kostos_odoiporikwn_ana_wra}</Text>}
                  </View>
                </DataTable.Cell>
                <DataTable.Cell style={myCss.tableIcon}>
                <IconButton
                    icon={({ color, size }) => (<MaterialIcons name="edit" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      const tempErgazomenosToBeEdited: Ergazomenos = {
                                        id: ergazomenos.id,
                                        onoma: ergazomenos.onoma,
                                        epwnymo: ergazomenos.epwnymo,
                                        thlefwno: ergazomenos.thlefwno,
                                        id_kathgorias: ergazomenos.id_kathgorias,
                                        trapeza: ergazomenos.trapeza,
                                        iban: ergazomenos.iban,
                                        kostos_hmeromisthiou_kathimerinh: ergazomenos.kostos_hmeromisthiou_kathimerinh,
                                        kostos_hmeromisthiou_savvato: ergazomenos.kostos_hmeromisthiou_savvato,
                                        kostos_hmeromisthiou_kyriakh: ergazomenos.kostos_hmeromisthiou_kyriakh,
                                        kostos_yperwrias: ergazomenos.kostos_yperwrias,
                                        kostos_dianyktereyshs: ergazomenos.kostos_dianyktereyshs,
                                        kostos_efka_kathimerinh: ergazomenos.kostos_efka_kathimerinh,
                                        kostos_efka_savvato: ergazomenos.kostos_efka_savvato,
                                        kostos_odoiporikwn_ana_wra: ergazomenos.kostos_odoiporikwn_ana_wra
                                      };
                                      setEditedErgazomenos(tempErgazomenosToBeEdited); 
                                      setSelectedKathgoriaErgazomenouEditErgazomenos(ergazomenos.id_kathgorias);
                                      setEditErgazomenosModalVisible(true);
                                   }} />
                  <IconButton
                    icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      setErgazomenosIDToBeDeleted(ergazomenos.id);
                                      setDeleteDialogVisible(true);
                                   }} />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
          <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="add" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} 
                                          onPress={() => {
                                                            const tempNewErgazomenos: Ergazomenos = {
                                                              id: -1,
                                                              onoma: "",
                                                              epwnymo: "",
                                                              thlefwno: "",
                                                              id_kathgorias: -1,
                                                              trapeza: "",
                                                              iban: "",
                                                              kostos_hmeromisthiou_kathimerinh: -1.0,
                                                              kostos_hmeromisthiou_savvato: -1.0,
                                                              kostos_hmeromisthiou_kyriakh: -1.0,
                                                              kostos_yperwrias: -1.0,
                                                              kostos_dianyktereyshs: -1.0,
                                                              kostos_efka_kathimerinh: -1.0,
                                                              kostos_efka_savvato: -1.0,
                                                              kostos_odoiporikwn_ana_wra: -1.0
                                                            };
                                                            setNewErgazomenos(tempNewErgazomenos); 
                                                            setSelectedKathgoriaErgazomenouAddErgazomenos(-1);
                                                            setSelectedKathgoriaErgazomenouEditErgazomenos(-1);
                                                            setNewErgazomenosModalVisible(true);
                                                         }}>{i18n.t('addNewErgazomeno')}</Button>
        </View>
      </ScrollView>}
      <AwesomeAlert
        show={alertForWebVisible}
        showProgress={false}
        title={i18n.t('caution')}
        message={alertForWebText}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="OK"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setAlertForWebVisible(false);
        }}
        onConfirmPressed={() => {
          setAlertForWebVisible(false);
        }}  
      />
    </SafeAreaView>
  );
};

export default ManageErgazomenousScreen;