import React from 'react';

const CustomTooltip = (props) => {
    const { value, color } = props;
    return (
        <div style={{ backgroundColor: color, padding: '5px', borderRadius: '5px' }}>
            <strong>{value}</strong>
        </div>
    );
};

export default CustomTooltip;