import React, { useState } from 'react';
import { BottomNavigation } from 'react-native-paper';
import i18n from '../../utils/i18n';
import ManageErgazomenousAnaMeraScreen from './ManageErgazomenousAnaMeraScreen';
import ManageErgazomenousScreen from './ManageErgazomenousScreen';
import ManageErgaScreen from './ManageErgaScreen';
import LoginManager from '../../managers/LoginManager';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import AdminMenuOptions from './AdminMenuOptions';
import { View, Text } from 'react-native';
import ConstManager from '../../managers/ConstManager';
import myCss from '../../css/myCss';

const AdminControlPanelFooter = () => {
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'ManageErgazomenousAnaMera', title: i18n.t('ergazomenoiAnaMera'), icon: ({ color, size }) => (<MaterialCommunityIcons name='calendar-account' color={color} size={size} />), screenTitle: i18n.t('ergazomenoiAnaMera') },
    { key: 'ManageErgazomenous', title: i18n.t('ergazomenoi'), icon: ({ color, size }) => (<MaterialCommunityIcons name='account-group' color={color} size={size} />), screenTitle: i18n.t('ergazomenoi') },
    { key: 'ManageErga', title: i18n.t('erga'), icon: ({ color, size }) => (<MaterialCommunityIcons name='format-list-bulleted-type' color={color} size={size} />), screenTitle: i18n.t('erga') }
  ]);

  const renderScene = BottomNavigation.SceneMap({
    ManageErgazomenousAnaMera: ManageErgazomenousAnaMeraScreen,
    ManageErgazomenous: ManageErgazomenousScreen,
    ManageErga: ManageErgaScreen
  });

  const customRenderScene = (props) => {
    const { route } = props;

    // Define the title for the screen
    const screenTitle = route.screenTitle;

    return (
      <View style={{flex: 1}}>
        <AdminMenuOptions
          screenTitle={screenTitle}
        />
        {renderScene(props)}
      </View>
    );
  };

  return (
    <BottomNavigation style={myCss.bottomNavigation}
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={customRenderScene}
    />
  );
};

export default AdminControlPanelFooter;
