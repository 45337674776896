import { View, SafeAreaView, ScrollView, TouchableOpacity, Dimensions } from 'react-native';
import myCss from '../../../css/myCss';
import Footer from '../../Footer';
import React, { useEffect, useRef, useState } from 'react';
import RestManager from '../../../managers/RestManager';
import i18n from '../../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataTable, IconButton, Colors, Title, Text, Button, Portal, Modal, TextInput, Dialog, Paragraph, Switch } from 'react-native-paper';
import LoginManager from '../../../managers/LoginManager';
import DropDown from 'react-native-paper-dropdown';
import ConstManager from '../../../managers/ConstManager';
import AwesomeAlert from 'react-native-awesome-alerts';
import UtilsManager from '../../../managers/UtilsManager';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigation } from '@react-navigation/native';

const screenDimensions = Dimensions.get('window');

const ManageKentraKostousScreen = () => {
  const navigation = useNavigation();

  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ManageKentraKostousScreen: I'm ready!");
    setIsLoading(true);
    (async () => {
      const tmpAllTypoiErgwn: Array<User> = await RestManager.getInstance().getAllTypoysErgwn();
      setTypoiErgwn(tmpAllTypoiErgwn);

      const tmpAllKentraKostous: Array<KentroKostous> = await RestManager.getInstance().getAllKentraKostous();
      setKentraKostous(tmpAllKentraKostous);
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);
 
  const [isLoading, setIsLoading] = useState(false);
  const [typoiErgwn, setTypoiErgwn] = useState<Array<TyposErgou>>([]);
  const [kentraKostous, setKentraKostous] = useState<Array<KentroKostous>>([]);

  function getPerigrafhTypwnErgouTouKentrouKostous(tmpKentroKostous: KentroKostous) : string {
    let res: string = "";
    for(let i=0; i<tmpKentroKostous.typoiErgwn_ids.length; i++) {
      const tmpPerigrafh: string = getPerigrafhTypouErgouApoID(tmpKentroKostous.typoiErgwn_ids[i]);
      if(res == "")
        res = tmpPerigrafh;
      else
        res = res + ", " + tmpPerigrafh;
    }
    return res;
  }

  function getPerigrafhTypouErgouApoID(tmpTyposErgouID: number) : string {
    for(let i=0; i<typoiErgwn.length; i++) {
      if(typoiErgwn[i].id == tmpTyposErgouID)
        return typoiErgwn[i].typos;
    }
    return "Άγνωστο!";
  }

  const [newKentroKostousModalVisible, setNewKentroKostousModalVisible] = useState(false);
  const hideNewKentroKostousModal = () => { 
    setNewKentroKostousModalVisible(false);
  }
  useEffect(() => {
    setSelectedTypoiErgouItems([]); //init
  }, [newKentroKostousModalVisible]);

  const [editKentroKostousModalVisible, setEditKentroKostousModalVisible] = useState(false);
  const hideEditKentroKostousModal = () => { 
    setEditKentroKostousModalVisible(false);
  }

  const [newKentroKostous, setNewKentroKostous] = useState<KentroKostous>({
    id: -1,
    kwdikos: "",
    perigrafh: "",
    tha_enhmerwnei_vivlio_kostologiou: false,
    typoiErgwn_ids: []
  });

  const [editedKentroKostous, setEditedKentroKostous] = useState<KentroKostous>({
    id: -1,
    kwdikos: "",
    perigrafh: "",
    tha_enhmerwnei_vivlio_kostologiou: false,
    typoiErgwn_ids: []
  });

  // Create a ref for the TextInput components
  const kwdikosNewInputRef = useRef(null);
  const perigrafhNewInputRef = useRef(null);

  const kwdikosEditInputRef = useRef(null);
  const perigrafhEditInputRef = useRef(null);

  const [alertForNewWebModalVisible, setAlertForNewWebModalVisible] = useState(false);
  const [alertForNewWebModalText, setAlertForNewWebModalText] = useState('');

  const [alertForEditWebModalVisible, setAlertForEditWebModalVisible] = useState(false);
  const [alertForEditWebModalText, setAlertForEditWebModalText] = useState('');

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  useEffect(() => {
    if(alertForWebText!="" && alertForWebVisible == false) {
      navigation.reset({
        index: 1,
        routes: [{ name: 'AdminControlPanel' }]
      });
    }
  }, [alertForWebVisible]);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [dropdownListItemsArxiko, setDropdownListItemsArxiko] = useState<Array<{ label: string, value: number }>>([]);
  const [dropdownListItems, setDropdownListItems] = useState<Array<{ label: string, value: number }>>([]);
  const [selectedTypoiErgouItems, setSelectedTypoiErgouItems] = useState<Array<{ label: string, value: number }>>([]);
  const [selectedTypoiErgouIDs, setSelectedTypoiErgouIDs] = useState<Array<number>>([]);
  useEffect(() => {
    //gia to dropdown ths arxikhs othonhs
    const newFilterDropdownListItems = [
      {
          label: 'Όλοι',
          value: ConstManager.getInstance().OLOI_OI_TYPOI_ERGOY
      },
      ...typoiErgwn.map((item) => ({
          label: item.typos,
          value: item.id,
      })),
    ];
    setDropdownListItemsArxiko(newFilterDropdownListItems);

    //gia ta dropdown add & edit
    const newDropdownListItems = typoiErgwn.map((item) => ({
        label: item.typos,
        value: item.id,
    }));
    setDropdownListItems(newDropdownListItems);
  }, [typoiErgwn]);
  useEffect(() => {
    // Initialize an array to hold the selected items
    const tempNewSelectedItems = [];
    if(selectedTypoiErgouIDs!=null && selectedTypoiErgouIDs!=undefined) {
      for(let i=0; i<selectedTypoiErgouIDs.length; i++) {
        for(let j=0; j<typoiErgwn.length; j++) {
          console.log("typoiErgwn[" + j + "] : ", JSON.stringify(typoiErgwn[j], null, 2));
          if (typoiErgwn[j].id === selectedTypoiErgouIDs[i]) {
            // Add the new item to the array
            tempNewSelectedItems.push({
              label: typoiErgwn[j].typos,
              value: selectedTypoiErgouIDs[i]
            });
          }
        }
      }
    }
    console.log("useEffect [selectedTypoiErgouIDs] -> tempNewSelectedItems: " + JSON.stringify(tempNewSelectedItems, null, 2));
    setSelectedTypoiErgouItems(tempNewSelectedItems);

    setNewKentroKostous({...newKentroKostous, typoiErgwn_ids: selectedTypoiErgouIDs });
    setEditedKentroKostous({...editedKentroKostous, typoiErgwn_ids: selectedTypoiErgouIDs });
  }, [selectedTypoiErgouIDs, typoiErgwn]);

  const [showTyposErgouDropdownList, setShowTyposErgouDropdownList] = useState(false);

  const [selectedTyposErgouIDFilter, setSelectedTyposErgouIDFilter] = useState(ConstManager.getInstance().OLOI_OI_TYPOI_ERGOY);

  async function addNewKentroKostousAsync() 
  {
    if(newKentroKostous.typoiErgwn_ids==undefined || newKentroKostous.typoiErgwn_ids.length==0) {
      setAlertForNewWebModalText("Παρακαλώ επιλέξτε τύπο έργου!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newKentroKostous.kwdikos == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κωδικό!");
      setAlertForNewWebModalVisible(true);
    }
    else if(newKentroKostous.perigrafh == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε περιγραφή!");
      setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().addKentroKostous(newKentroKostous);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().KENTROKOSTOUS_ADDED_SUCCESSFULLY) {
          msgToShow = "Το κέντρο κόστους προστέθηκε επιτυχώς!";
          //update array
          const tmpAllKentraKostous: Array<KentroKostous> = await RestManager.getInstance().getAllKentraKostous();
          setKentraKostous(tmpAllKentraKostous);
          //init new obj
          setNewKentroKostous({
            id: -1,
            kwdikos: "",
            perigrafh: "",
            tha_enhmerwnei_vivlio_kostologiou: false,
            typoiErgwn_ids: []
          });
        }
        setNewKentroKostousModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  async function editKentroKostousAsync() 
  {
    if(editedKentroKostous.typoiErgwn_ids==undefined || editedKentroKostous.typoiErgwn_ids.length==0) {
      setAlertForEditWebModalText("Παρακαλώ επιλέξτε τύπο έργου!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedKentroKostous.kwdikos == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε κωδικό!");
      setAlertForEditWebModalVisible(true);
    }
    else if(editedKentroKostous.perigrafh == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε περιγραφή!");
      setAlertForEditWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().editKentroKostous(editedKentroKostous);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().KENTROKOSTOUS_EDITED_SUCCESSFULLY) {
          msgToShow = "Τα στοιχεία του κέντρου κόστους ενημερώθηκαν επιτυχώς!";
          //update array
          const tmpAllKentraKostous: Array<KentroKostous> = await RestManager.getInstance().getAllKentraKostous();
          setKentraKostous(tmpAllKentraKostous);
          //init edit obj
          setEditedKentroKostous({
            id: -1,
            kwdikos: "",
            perigrafh: "",
            tha_enhmerwnei_vivlio_kostologiou: false,
            typoiErgwn_ids: []
          });
        }
        setEditKentroKostousModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const hideDeleteDialog = () => setDeleteDialogVisible(false);

  const [kentroKostousIDToBeDeleted, setKentroKostousIDToBeDeleted] = useState(-1);

  async function deleteKentroKostousAsync() {
    hideDeleteDialog();
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    webServiceResponseStr = await RestManager.getInstance().deleteKentroKostous(kentroKostousIDToBeDeleted);
    if(typeof webServiceResponseStr === 'string') {
      var msgToShow: string = "";
      if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
        msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
      else if(webServiceResponseStr == ConstManager.getInstance().KENTROKOSTOUS_DELETED_SUCCESSFULLY) {
        msgToShow = "Το κέντρο κόστους διεγράφη επιτυχώς!";
        //update array
        const tmpAllKentraKostous: Array<KentroKostous> = await RestManager.getInstance().getAllKentraKostous();
        setKentraKostous(tmpAllKentraKostous);
        //init new obj
        setNewKentroKostous({
          id: -1,
          kwdikos: "",
          perigrafh: "",
          tha_enhmerwnei_vivlio_kostologiou: false,
          typoiErgwn_ids: []
        });
      }
      setAlertForWebText(msgToShow);
      setAlertForWebVisible(true);
    }
    setIsLoading(false);
  }
  
  return (
    <SafeAreaView style={myCss.container}>
      <Portal>
        <Modal visible={newKentroKostousModalVisible} onDismiss={hideNewKentroKostousModal} contentContainerStyle={myCss.modalContainer}>
          <View>
            <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Νέο κέντρο κόστους</Title>
            <TouchableOpacity onPress={() => kwdikosNewInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={kwdikosNewInputRef}
                label="Κωδικός"
                value={newKentroKostous.kwdikos}
                onChangeText={tmpKwdikos => setNewKentroKostous({...newKentroKostous, kwdikos: tmpKwdikos })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => perigrafhNewInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={perigrafhNewInputRef}
                label="Περιγραφή"
                value={newKentroKostous.perigrafh}
                onChangeText={tmpPerigrafh => setNewKentroKostous({...newKentroKostous, perigrafh: tmpPerigrafh })}
              />
            </TouchableOpacity>
            <View style={{flexDirection:"row", marginTop: 20}}>
              <Text style={myCss.switchText}>{i18n.t('thaEnhmerwneiVivlioKostologiou')}</Text> 
              <Switch style={myCss.switch} value={newKentroKostous.tha_enhmerwnei_vivlio_kostologiou} onValueChange={() => {
                  setNewKentroKostous({...newKentroKostous, 
                    tha_enhmerwnei_vivlio_kostologiou: !newKentroKostous.tha_enhmerwnei_vivlio_kostologiou
                  });
                }} 
              />
            </View>
            <Multiselect
              options={dropdownListItems} // Options to display in the dropdown
              selectedValues={selectedTypoiErgouItems} // Preselected value to persist in dropdown
              onSelect={(selectedList, selectedItem) => {
                //console.log("onSelect -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onSelect -> selectedItem: " + JSON.stringify(selectedItem, null, 2));
                setSelectedTypoiErgouIDs([...selectedTypoiErgouIDs, selectedItem.value]); //"useEfect - selectedTypoiErgouIDs" will trigger setSelectedTypoiErgouItems
              }}
              onRemove={(selectedList, removedItem) => {
                //console.log("onRemove -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onRemove -> removedItem: " + JSON.stringify(removedItem, null, 2));
                const tmpUpdatedIDs = selectedTypoiErgouIDs.filter(item => item !== removedItem.value);
                setSelectedTypoiErgouIDs(tmpUpdatedIDs); //"useEfect - selectedTypoiErgouIDs" will trigger setSelectedTypoiErgouItems
              }}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              groupBy="label"
              placeholder="Τύποι έργων"
              style={{ 
                chips: { background: "green" }, 
                searchBox: { paddingTop: 10, paddingBottom: 10, border: "2px solid blue", borderRadius: 5 },
                multiselectContainer: { width: screenDimensions.width<=ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width*0.6 : ConstManager.getInstance().WEB_MAX_WIDTH*0.6, marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5 }
              }}
            />
            <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={addNewKentroKostousAsync}>{i18n.t('save')}</Button>
            <AwesomeAlert
              show={alertForNewWebModalVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForNewWebModalText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForNewWebModalVisible(false);
              }}  
            />
          </View>           
        </Modal>
      </Portal>

      <Portal>
        <Modal visible={editKentroKostousModalVisible} onDismiss={hideEditKentroKostousModal} contentContainerStyle={myCss.modalContainer}>
          <View>
            <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Υπάρχον κέντρο κόστους</Title>
            <TouchableOpacity onPress={() => kwdikosEditInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={kwdikosEditInputRef}
                label="Κωδικός"
                value={editedKentroKostous.kwdikos}
                onChangeText={tmpKwdikos => setEditedKentroKostous({...editedKentroKostous, kwdikos: tmpKwdikos })}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => perigrafhEditInputRef.current.focus()} style={{ marginTop: 5 }}>
              <TextInput
                ref={perigrafhEditInputRef}
                label="Περιγραφή"
                value={editedKentroKostous.perigrafh}
                onChangeText={tmpPerigrafh => setEditedKentroKostous({...editedKentroKostous, perigrafh: tmpPerigrafh })}
              />
            </TouchableOpacity>
            <View style={{flexDirection:"row", marginTop: 20}}>
              <Text style={myCss.switchText}>{i18n.t('thaEnhmerwneiVivlioKostologiou')}</Text> 
              <Switch style={myCss.switch} value={editedKentroKostous.tha_enhmerwnei_vivlio_kostologiou} onValueChange={() => {
                  setEditedKentroKostous({...editedKentroKostous, 
                    tha_enhmerwnei_vivlio_kostologiou: !editedKentroKostous.tha_enhmerwnei_vivlio_kostologiou
                  });
                }} 
              />
            </View>
            <Multiselect
              options={dropdownListItems} // Options to display in the dropdown
              selectedValues={selectedTypoiErgouItems} // Preselected value to persist in dropdown
              onSelect={(selectedList, selectedItem) => {
                //console.log("onSelect -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onSelect -> selectedItem: " + JSON.stringify(selectedItem, null, 2));
                setSelectedTypoiErgouIDs([...selectedTypoiErgouIDs, selectedItem.value]); //"useEfect - selectedTypoiErgouIDs" will trigger setSelectedTypoiErgouItems
              }}
              onRemove={(selectedList, removedItem) => {
                //console.log("onRemove -> selectedList: " + JSON.stringify(selectedList, null, 2));
                //console.log("onRemove -> removedItem: " + JSON.stringify(removedItem, null, 2));
                const tmpUpdatedIDs = selectedTypoiErgouIDs.filter(item => item !== removedItem.value);
                setSelectedTypoiErgouIDs(tmpUpdatedIDs); //"useEfect - selectedTypoiErgouIDs" will trigger setSelectedTypoiErgouItems
              }}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              groupBy="label"
              placeholder="Τύποι έργων"
              style={{ 
                chips: { background: "green" }, 
                searchBox: { paddingTop: 10, paddingBottom: 10, border: "2px solid blue", borderRadius: 5 },
                multiselectContainer: { width: screenDimensions.width<=ConstManager.getInstance().WEB_MAX_WIDTH ? screenDimensions.width*0.6 : ConstManager.getInstance().WEB_MAX_WIDTH*0.6, marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5 }
              }}
            />
            <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={editKentroKostousAsync}>{i18n.t('save')}</Button>
            <AwesomeAlert
              show={alertForEditWebModalVisible}
              showProgress={false}
              title={i18n.t('caution')}
              message={alertForEditWebModalText}
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showConfirmButton={true}
              confirmText="OK"
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setAlertForEditWebModalVisible(false);
              }}
              onConfirmPressed={() => {
                setAlertForEditWebModalVisible(false);
              }}  
            />
          </View>           
        </Modal>
      </Portal>

      <Portal>
        <Dialog visible={deleteDialogVisible} onDismiss={hideDeleteDialog} style={myCss.dialog}>
          <Dialog.Title>Διαγραφή κέντρου κόστους!</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{i18n.t('areYouSure')}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setDeleteDialogVisible(false)}>{i18n.t('cancel')}</Button>
            <Button onPress={() => deleteKentroKostousAsync()}>Ok</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      {newKentroKostousModalVisible==false 
        && editKentroKostousModalVisible==false 
        && alertForWebVisible==false 
        && alertForNewWebModalVisible==false
        && alertForEditWebModalVisible==false
        && deleteDialogVisible==false
        && <ScrollView>      
        <View style={myCss.container}>
          <Spinner
            //visibility of Overlay Loading Spinner
            visible={isLoading}
            //Text with the Spinner
            textContent={i18n.t('loading')}
            //Text style of the Spinner Text
            textStyle={myCss.spinnerTextStyle}
          />

          <Title style={myCss.title}>Κέντρα κόστους</Title>
          <View style={myCss.dropdownFullWidth}>
            <DropDown
                label="Τύπος έργου"
                mode={'outlined'}
                visible={showTyposErgouDropdownList}
                showDropDown={() => setShowTyposErgouDropdownList(true)}
                onDismiss={() => setShowTyposErgouDropdownList(false)}
                value={selectedTyposErgouIDFilter}
                setValue={setSelectedTyposErgouIDFilter}
                list={dropdownListItemsArxiko}
                inputProps={{ right: <TextInput.Icon icon={({ color, size }) => (<MaterialCommunityIcons name={showTyposErgouDropdownList==false ? "menu-down" : "menu-up"} color={color} size={size} />)} /> }}
            />
          </View>
          <DataTable>
            {kentraKostous
              .filter((kentroKostous) => 
                selectedTyposErgouIDFilter === ConstManager.getInstance().OLOI_OI_TYPOI_ERGOY || 
                UtilsManager.getInstance().kentroKostousBelongsToTypoErgou(kentroKostous, selectedTyposErgouIDFilter)
              )
              .map((kentroKostous: KentroKostous, i) => (          
              <DataTable.Row key={i} style={myCss.tableRow}>
                <DataTable.Title style={myCss.tableTitleKentraKostous}>{getPerigrafhTypwnErgouTouKentrouKostous(kentroKostous)}</DataTable.Title>
                <DataTable.Cell style={myCss.tableValueColumnKentraKostous}>{kentroKostous.kwdikos}</DataTable.Cell>
                <DataTable.Cell style={myCss.tableValueColumnKentraKostous}>{kentroKostous.perigrafh}</DataTable.Cell>
                <DataTable.Cell style={myCss.tableIcon}>
                <IconButton
                    icon={({ color, size }) => (<MaterialIcons name="edit" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      const tempKentroKostousToBeEdited: KentroKostous = {
                                        id: kentroKostous.id,
                                        kwdikos: kentroKostous.kwdikos,
                                        perigrafh: kentroKostous.perigrafh,
                                        tha_enhmerwnei_vivlio_kostologiou: kentroKostous.tha_enhmerwnei_vivlio_kostologiou,
                                        typoiErgwn_ids: kentroKostous.typoiErgwn_ids
                                      };
                                      setEditedKentroKostous(tempKentroKostousToBeEdited); 
                                      setSelectedTypoiErgouIDs(kentroKostous.typoiErgwn_ids);
                                      setEditKentroKostousModalVisible(true);
                                   }} />
                  <IconButton
                    icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      setKentroKostousIDToBeDeleted(kentroKostous.id);
                                      setDeleteDialogVisible(true);
                                   }} />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
          <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="add" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} 
                                          onPress={() => {
                                                            const tempNewKentroKostous: KentroKostous = {
                                                              id: -1,
                                                              kwdikos: "",
                                                              perigrafh: "",
                                                              tha_enhmerwnei_vivlio_kostologiou: false
                                                            };
                                                            setNewKentroKostous(tempNewKentroKostous); 
                                                            setSelectedTypoiErgouIDs([]);
                                                            setNewKentroKostousModalVisible(true);
                                                         }}>{i18n.t('addNewKentroKostous')}</Button>
        </View>
      </ScrollView>}
      <AwesomeAlert
        show={alertForWebVisible}
        showProgress={false}
        title={i18n.t('caution')}
        message={alertForWebText}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="OK"
        confirmButtonColor="#DD6B55"
        onCancelPressed={() => {
          setAlertForWebVisible(false);
        }}
        onConfirmPressed={() => {
          setAlertForWebVisible(false);
        }}  
      />
    </SafeAreaView>
  );
};

export default ManageKentraKostousScreen;