import GUIManager from "./GUIManager";
import { Platform } from "react-native";
import UtilsManager from "./UtilsManager";
import RestManager from "./RestManager";

class LoginManager {
    public username: string;
    public password: string;
    public md5EncodedPassword: string;

    public readonly INTRUDER_ROLE: number = 0;
    public readonly ADMIN_ROLE: number = 1;
    public readonly MHXANIKOS_ROLE: number = 2;
    public readonly ERGODHGOS_ROLE: number = 3;

    public role: number; 
    public ID: number;

    private static instance: LoginManager;

    private constructor() { 
        this.role = -1;
        this.ID = -1;

        this.username = 'test';
        this.password = 'test';
        this.md5EncodedPassword = 'test';
    }

    public static getInstance(): LoginManager {
        if (!LoginManager.instance) {
            LoginManager.instance = new LoginManager();
        }

        return LoginManager.instance;
    }
    public logout() {
        sessionStorage.setItem('storedEmail', "");
        sessionStorage.setItem('storedNonEncryptedPassword', "");
        
        GUIManager.getInstance().initialize();
        
        this.initialize();
    }

    public initialize()
    {
        this.role = -1;
        this.ID = -1;

        this.username = 'test';
        this.password = 'test';
        this.md5EncodedPassword = 'test';
    }  

    /*public async handleWebPageReload(): Promise<boolean> {
        if(this.username==undefined || this.username=="" || this.username=="test"
            || this.password==undefined || this.password=="" || this.password=="test"
            || this.md5EncodedPassword==undefined || this.md5EncodedPassword=="" || this.md5EncodedPassword=="test") { //do whatever is performed during login
            console.log("handleWebPageReload...prospathw na swsw undefined apo web page reload");

            this.username = sessionStorage.getItem('storedEmail')!;
            this.password = sessionStorage.getItem('storedNonEncryptedPassword')!;
            //this.md5EncodedPassword = sessionStorage.getItem('storedEncryptedPassword')!;
            
            var tmpRoleID : RoleID = {
                role: -1,
                ID: -1
            };
            tmpRoleID = await RestManager.getInstance().login() as RoleID;

            LoginManager.getInstance().role = tmpRoleID.role;
            LoginManager.getInstance().ID = tmpRoleID.ID;

            //ADMIN_ROLE
            if(tmpRoleID.ID!==-1 && tmpRoleID.role===LoginManager.getInstance().ADMIN_ROLE)
            {
                //console.log("*** ADMIN MODE ***");
                LoginManager.getInstance().role = LoginManager.getInstance().ADMIN_ROLE;
            }
            console.log("handleWebPageReload...ESWSE THN KATASTASH!");
            return true;
        }

        console.log("handleWebPageReload...de xreiasthke na kanei kati!");
        return false;
    }*/
}

export default LoginManager;