import { View, Text } from "react-native";
import { Divider } from "react-native-paper";
import myCss from "../css/myCss";
import React, { useEffect, useRef, useState } from 'react';

const Footer = () => {
     return (
         <View style={myCss.footer}>
            <Divider style={myCss.divider}/>
            <Text style={myCss.footerText}>{"© 2024 - Website Designed & Developed by "}
                <Text style={{ color: 'black', textDecorationLine: 'underline', fontWeight: 'normal' }} onPress={() => { window.location.href = "mailto:kaklanis@gmail.com" }}>Nick Kaklanis</Text>
            </Text>
         </View>
     );
};
  
export default Footer; 