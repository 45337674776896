import { View, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';
import myCss from '../../../css/myCss';
import Footer from '../../Footer';
import React, { useEffect, useRef, useState } from 'react';
import RestManager from '../../../managers/RestManager';
import i18n from '../../../utils/i18n';
import Spinner from 'react-native-loading-spinner-overlay';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataTable, IconButton, Colors, Title, Text, Button, Portal, Modal, TextInput, Dialog, Paragraph } from 'react-native-paper';
import LoginManager from '../../../managers/LoginManager';
import ConstManager from '../../../managers/ConstManager';
import AwesomeAlert from 'react-native-awesome-alerts';


const ManageKathgoriesErgazomenwnScreen = () => {
  const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
  useEffect(() => {
    console.log("ManageKathgoriesErgazomenwnScreen: I'm ready!");
    setIsLoading(true);
    (async () => {
      const tmpAllKathgoriesErgazomenwn: Array<KathgoriaErgazomenwn> = await RestManager.getInstance().getAllKathgoriesErgazomenwn();
      setKathgoriesErgazomenwn(tmpAllKathgoriesErgazomenwn);      
    })();
    setIsLoading(false);
  }, [alreadyRunOnceOnViewLoad]);

  const [isLoading, setIsLoading] = useState(false);
  const [kathgoriesErgazomenwn, setKathgoriesErgazomenwn] = useState<Array<KathgoriaErgazomenwn>>([]);

  const [newKathgoriaErgazomenwnModalVisible, setNewKathgoriaErgazomenwnModalVisible] = useState(false);
  const hideNewKathgoriaErgazomenwnModal = () => { 
    setNewKathgoriaErgazomenwnModalVisible(false);
  }

  const [editKathgoriaErgazomenwnModalVisible, setEditKathgoriaErgazomenwnModalVisible] = useState(false);
  const hideEditKathgoriaErgazomenwnModal = () => { 
    setEditKathgoriaErgazomenwnModalVisible(false);
  }

  const [newKathgoriaErgazomenwn, setNewKathgoriaErgazomenwn] = useState<KathgoriaErgazomenwn>({
    id: -1,
    kathgoria: ""
  });

  const [editedKathgoriaErgazomenwn, setEditedKathgoriaErgazomenwn] = useState<KathgoriaErgazomenwn>({
    id: -1,
    kathgoria: ""
  });

  // Create a ref for the TextInput components
  const kathgoriaNewInputRef = useRef(null);
  
  const kathgoriaEditInputRef = useRef(null);

  const [alertForNewWebModalVisible, setAlertForNewWebModalVisible] = useState(false);
  const [alertForNewWebModalText, setAlertForNewWebModalText] = useState('');

  const [alertForEditWebModalVisible, setAlertForEditWebModalVisible] = useState(false);
  const [alertForEditWebModalText, setAlertForEditWebModalText] = useState('');

  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  async function addNewKathgoriaErgazomenwnAsync() 
  {
    if(newKathgoriaErgazomenwn.kathgoria == "") {
      setAlertForNewWebModalText("Παρακαλώ συμπληρώστε κατηγορία!");
      setAlertForNewWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().addKathgoriaErgazomenwn(newKathgoriaErgazomenwn);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().KATHGORIAERGAZOMENWN_ADDED_SUCCESSFULLY) {
          msgToShow = "Η κατηγορία εργαζομένων προστέθηκε επιτυχώς!";
          //update array
          const tmpAllKathgoriesErgazomenwn: Array<KathgoriaErgazomenwn> = await RestManager.getInstance().getAllKathgoriesErgazomenwn();
          setKathgoriesErgazomenwn(tmpAllKathgoriesErgazomenwn);
          //init new obj
          setNewKathgoriaErgazomenwn({
            id: -1,
            kathgoria: ""
          });
        }
        setNewKathgoriaErgazomenwnModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  async function editKathgoriaErgazomenwnAsync() 
  {
    if(editedKathgoriaErgazomenwn.kathgoria == "") {
      setAlertForEditWebModalText("Παρακαλώ συμπληρώστε κατηγορία!");
      setAlertForEditWebModalVisible(true);
    }
    else {
      setIsLoading(true);
      
      var webServiceResponseStr : string | void;
      webServiceResponseStr = await RestManager.getInstance().editKathgoriaErgazomenwn(editedKathgoriaErgazomenwn);
      if(typeof webServiceResponseStr === 'string') {
        var msgToShow: string = "";
        if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
          msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
        else if(webServiceResponseStr == ConstManager.getInstance().KATHGORIAERGAZOMENWN_EDITED_SUCCESSFULLY) {
          msgToShow = "Η κατηγορία εργαζομένων ενημερώθηκε επιτυχώς!";
          //update array
          const tmpAllKathgoriesErgazomenwn: Array<KathgoriaErgazomenwn> = await RestManager.getInstance().getAllKathgoriesErgazomenwn();
          setKathgoriesErgazomenwn(tmpAllKathgoriesErgazomenwn);
          //init edit obj
          setEditedKathgoriaErgazomenwn({
            id: -1,
            kathgoria: ""
          });
        }
        setEditKathgoriaErgazomenwnModalVisible(false);
        setAlertForWebText(msgToShow);
        setAlertForWebVisible(true);
      }
      setIsLoading(false);
    }
  }

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const hideDeleteDialog = () => setDeleteDialogVisible(false);

  const [kathgoriaErgazomenwnIDToBeDeleted, setKathgoriaErgazomenwnIDToBeDeleted] = useState(-1);

  async function deleteKathgoriaErgazomenwnAsync() {
    hideDeleteDialog();
    setIsLoading(true);
    var webServiceResponseStr : string | void;
    webServiceResponseStr = await RestManager.getInstance().deleteKathgoriaErgazomenwn(kathgoriaErgazomenwnIDToBeDeleted);
    if(typeof webServiceResponseStr === 'string') {
      var msgToShow: string = "";
      if(webServiceResponseStr == ConstManager.getInstance().INTRUDER_MSG)
        msgToShow = "Δεν έχετε δικαίωμα για αυτή την ενέργεια!";
      else if(webServiceResponseStr == ConstManager.getInstance().KATHGORIAERGAZOMENWN_DELETED_SUCCESSFULLY) {
        msgToShow = "Η κατηγορία εργαζομένων διεγράφη επιτυχώς!";
        //update array
        const tmpAllKathgoriesErgazomenwn: Array<KathgoriaErgazomenwn> = await RestManager.getInstance().getAllKathgoriesErgazomenwn();
        setKathgoriesErgazomenwn(tmpAllKathgoriesErgazomenwn);
        //init new obj
        setNewKathgoriaErgazomenwn({
          id: -1,
          kathgoria: ""
        });
      }
      setAlertForWebText(msgToShow);
      setAlertForWebVisible(true);
    }
    setIsLoading(false);
  }
  
  return (
    <SafeAreaView>
      <ScrollView>

      <Portal>
          <Modal visible={newKathgoriaErgazomenwnModalVisible} onDismiss={hideNewKathgoriaErgazomenwnModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Νέα κατηγορία εργαζομένων</Title>
              <TouchableOpacity onPress={() => kathgoriaNewInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={kathgoriaNewInputRef}
                  label="Κατηγορία"
                  value={newKathgoriaErgazomenwn.kathgoria}
                  onChangeText={tmpKathgoria => setNewKathgoriaErgazomenwn({...newKathgoriaErgazomenwn, kathgoria: tmpKathgoria })}
                />
              </TouchableOpacity>
              <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={addNewKathgoriaErgazomenwnAsync}>{i18n.t('save')}</Button>
              <AwesomeAlert
                show={alertForNewWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForNewWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForNewWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Modal visible={editKathgoriaErgazomenwnModalVisible} onDismiss={hideEditKathgoriaErgazomenwnModal} contentContainerStyle={myCss.modalContainer}>
            <View>
              <Title style={{ alignSelf: "center", fontWeight: "bold" }}>Υπάρχουσα κατηγορία εργαζομένων</Title>
              <TouchableOpacity onPress={() => kathgoriaEditInputRef.current.focus()} style={{ marginTop: 5 }}>
                <TextInput
                  ref={kathgoriaEditInputRef}
                  label="Κατηγορία"
                  value={editedKathgoriaErgazomenwn.kathgoria}
                  onChangeText={tmpKathgoria => setEditedKathgoriaErgazomenwn({...editedKathgoriaErgazomenwn, kathgoria: tmpKathgoria })}
                />
              </TouchableOpacity>
              <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="save" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} onPress={editKathgoriaErgazomenwnAsync}>{i18n.t('save')}</Button>
              <AwesomeAlert
                show={alertForEditWebModalVisible}
                showProgress={false}
                title={i18n.t('caution')}
                message={alertForEditWebModalText}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor="#DD6B55"
                onCancelPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}
                onConfirmPressed={() => {
                  setAlertForEditWebModalVisible(false);
                }}  
              />
            </View>           
          </Modal>
        </Portal>

        <Portal>
          <Dialog visible={deleteDialogVisible} onDismiss={hideDeleteDialog} style={myCss.dialog}>
            <Dialog.Title>Διαγραφή κατηγορίας εργαζομένων!</Dialog.Title>
            <Dialog.Content>
              <Paragraph>{i18n.t('areYouSure')}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setDeleteDialogVisible(false)}>{i18n.t('cancel')}</Button>
              <Button onPress={() => deleteKathgoriaErgazomenwnAsync()}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        <View style={myCss.container}>
          <Spinner
            //visibility of Overlay Loading Spinner
            visible={isLoading}
            //Text with the Spinner
            textContent={i18n.t('loading')}
            //Text style of the Spinner Text
            textStyle={myCss.spinnerTextStyle}
          />

          <Title style={myCss.title}>Κατηγορίες Εργαζομένων</Title>
          <DataTable>
            {kathgoriesErgazomenwn.map((kathgoria: KathgoriaErgazomenwn, i) => (             
              <DataTable.Row key={i} style={myCss.tableRow}>
                <DataTable.Title style={myCss.tableTitle}>Κατηγορία {i+1}</DataTable.Title>
                <DataTable.Cell style={myCss.tableValueColumn}>{kathgoria.kathgoria}</DataTable.Cell>
                <DataTable.Cell style={myCss.tableIcon}>
                <IconButton
                    icon={({ color, size }) => (<MaterialIcons name="edit" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      const tempKathgoriaErgazomenwnToBeEdited: KathgoriaErgazomenwn = {
                                        id: kathgoria.id,
                                        kathgoria: kathgoria.kathgoria
                                      };
                                      setEditedKathgoriaErgazomenwn(tempKathgoriaErgazomenwnToBeEdited); 
                                      setEditKathgoriaErgazomenwnModalVisible(true);
                                   }} />
                  <IconButton
                    icon={({ color, size }) => (<MaterialCommunityIcons name="delete" color={color} size={size} />)}
                    color={Colors.blue900}
                    size={35}
                    onPress={() => { 
                                      setKathgoriaErgazomenwnIDToBeDeleted(kathgoria.id);
                                      setDeleteDialogVisible(true);
                                   }} />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
          <Button style={myCss.iconButton} icon={({ color, size }) => (<MaterialIcons name="add" color={color} size={size} />)} mode="contained" labelStyle={myCss.iconButtonLabel} contentStyle={myCss.iconButtonContent} 
                                          onPress={() => {
                                                            const tempNewKathgoriaErgazomenwn: KathgoriaErgazomenwn = {
                                                              id: -1,
                                                              kathgoria: ""
                                                            };
                                                            setNewKathgoriaErgazomenwn(tempNewKathgoriaErgazomenwn); 
                                                            setNewKathgoriaErgazomenwnModalVisible(true);
                                                         }}>{i18n.t('addNewCategory')}</Button>
          <AwesomeAlert
            show={alertForWebVisible}
            showProgress={false}
            title={i18n.t('caution')}
            message={alertForWebText}
            closeOnTouchOutside={false}
            closeOnHardwareBackPress={false}
            showConfirmButton={true}
            confirmText="OK"
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => {
              setAlertForWebVisible(false);
            }}
            onConfirmPressed={() => {
              setAlertForWebVisible(false);
            }}  
          />          
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ManageKathgoriesErgazomenwnScreen;